import React, { useEffect, useState } from "react";
import { API } from "../components/constants/api_url";
import APIServices from "../service/APIService";
import { useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { DateTime } from "luxon";
import { Column } from "primereact/column";
import { Editor } from "primereact/editor";
import Swal from "sweetalert2";
import { Calendar } from "primereact/calendar";
import useForceUpdate from "use-force-update";


const NewdFeed = () => {
    const selector = useSelector(state => state.user.userdetail)
    const [news, setNews] = useState([])
    const [newsobj, setNewsObj] = useState({ message: null,title:'', status: 1 ,expiryDate:null})
    const [submitted, setSubmitted] = useState(false)
    const [visible, setVisible] = useState(false)
const forceUpdate = useForceUpdate()
    useEffect(() => {


        const promise0 = APIServices.get(
            API.NewsCirculate_UP(selector.id));

        Promise.all([
            promise0
        ]).then((values) => {
            const shapedNews = values[0].data
            setNews(shapedNews)
        })

    }, [])
    const updateNewsObj = (obj, val) => {

console.log(val)
        setNewsObj((prev) => ({ ...prev, [obj]: val }))
    }
const editFeed =(rowData)=>{
    let items = JSON.parse(JSON.stringify(rowData))
    if(items.expiryDate){
        items.expiryDate = DateTime.fromISO(items.expiryDate).toLocal().toJSDate()
    }
    setNewsObj(items); 
    setSubmitted(false); 
    setVisible(true);
}
    const titleTemplate = (rowData) => {
        return (
            <span className="clr-navy fw-5 fs-16 cur-pointer text-three-dot text-underline" onClick={() => { editFeed(rowData) }}>{rowData.title} </span>
        )
    }
    const isSameOrFutureLocalDate = (utcDateString) => {


        if(utcDateString === null){
            return true
        }
        // Parse the given UTC date string
        let givenDate = DateTime.fromISO(utcDateString, { zone: 'utc' }).toLocal();
if(typeof utcDateString === 'object'){
    console.log( DateTime.fromObject(utcDateString).toJSDate())
     givenDate =DateTime.fromObject(utcDateString).toLocal()
}
        // Get the current local date
        const currentLocalDate = DateTime.local();
    
        // Compare the dates ignoring the time part
        return givenDate.startOf('day') >= currentLocalDate.startOf('day');
       };
    const statusTemplate = (rowData) => {
        let date = isSameOrFutureLocalDate(rowData.expiryDate)


        if (rowData.status === 2) {
            return <span className={!date ? "status-tag-orange":  "status-tag-green"}> { !date ? 'Expired' :'Active'}</span>
        } else {
            return <span className="status-tag-orange">Inactive  </span>
        }
    }
    const pinTemplate = (rowData) => {
   

        if(rowData.pin){
return <svg  onClick={()=>{updatePinStatus(rowData)}} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#666666"><path d="M680-840v80h-40v327l-80-80v-247H400v87l-87-87-33-33v-47h400ZM480-40l-40-40v-240H240v-80l80-80v-46L56-792l56-56 736 736-58 56-264-264h-6v240l-40 40ZM354-400h92l-44-44-2-2-46 46Zm126-193Zm-78 149Z"/></svg>
        }else{
        return    <svg onClick={()=>{updatePinStatus(rowData)}} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#666666"><path d="m640-480 80 80v80H520v240l-40 40-40-40v-240H240v-80l80-80v-280h-40v-80h400v80h-40v280Zm-286 80h252l-46-46v-314H400v314l-46 46Zm126 0Z"/></svg>
        }


    }
const updatePinStatus =(rowData)=>{
    let newObj = {pin: rowData.pin === null ? true : !rowData.pin}
    APIServices.patch(API.NewsCirculate_Edit(rowData.id), newObj).then((res) => {
        let local = news
        let index = local.findIndex(i => i.id === rowData.id)
        if (index !== -1) {
            local[index].pin = rowData.pin === null ? true : !rowData.pin
        }
        console.log(local)
        setNews(local)
        forceUpdate()
        setVisible(false)
    })
}

    const addNews = () => {
        setSubmitted(true)
        const dt = DateTime.utc()
        if (newsobj.title.trim().length  && checkEmptyPTags(newsobj.message)) {
            if (newsobj.id) {
                let newObj = {title:newsobj.title,expiryDate:newsobj.expiryDate, message: newsobj.message, modified_on: dt, status: newsobj.status }
                APIServices.patch(API.NewsCirculate_Edit(newsobj.id), newObj).then((res) => {
                    let local = news
                    let index = local.findIndex(i => i.id === newsobj.id)
                    if (index !== -1) {
                        local[index] = { ...newsobj, ...newObj }
                    }
                    console.log(local)
                    setNews(local)
                    setVisible(false)
                })
            } else {
                let newObj = {pin:false, title:newsobj.title,expiryDate:newsobj.expiryDate, message: newsobj.message, created_on: dt, modified_on: dt, status: newsobj.status }

                APIServices.post(API.NewsCirculate_UP(selector.id), newObj).then((res) => {
                    let local = news
                    news.push(res.data)
                    setNews(local)
                    setVisible(false)
                })
            }
        }
    }
    const dialogFooter = () => {
        return (
            <>
                <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => { addNews() }} />
            </>
        )
    }
    const getDate = (date, format) => {

        if(date === null){
            return 'No Expiry Date'
        }
        if (typeof date === 'string') {
            return DateTime.fromISO(date, { zone: 'utc' }).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
        }else if(DateTime.isDateTime(date)){
            return date.toFormat(format ? format : 'dd-MM-yyyy')
        } else {
            return DateTime.fromJSDate(date).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
        }

    };
    const deleteMessage = async (rowData) => {
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to delete message ?
          </div>`,

            confirmButtonColor: 'red',
            showCancelButton: true,
            confirmButtonText:
                'Yes',

        })
        if (accept) {
            APIServices.delete(API.NewsCirculate_Edit(rowData.id)).then((res) => {
                let local = news
                let index = local.findIndex(i => i.id === rowData.id)
                if (index !== -1) {
                    local.splice(index, 1)
                }
                setNews(local)
            })
        }
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">

                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteMessage(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    function checkEmptyPTags(str) {
        if(!str){
            return false
        }
        const regex = /<p>\s*<\/p>/;
        return !regex.test(str);
    }
    const pinSort = (e)=>{
        if (e.order === 1) {
     return   e.data.sort((a, b) => {
        if (a.pin === true && !b.pin ) return -1; 
        if (b.pin === true && !a.pin !== true) return 1;  
        if (!a.pin  && !b.pin ) return 1;
        if (!b.pin  && !a.pin ) return -1;
        return 0; 
    })
        }
        else{
       return     e.data.sort((a, b) => {
        if (a.pin === b.pin) return 0;
        if ((a.pin === false || a.pin === null) && b.pin === true) return -1;
        if ((b.pin === false || b.pin === null) && a.pin === true) return 1;
        return 0;
    })

        }
    }
    return (
        <div className="bg-smoke font-lato">
            <div className="col-12" >
                <div className="flex col-12 justify-content-end">
                    <Button label="Add New" onClick={() => { setNewsObj({title:'', message: null, status: 1,expiryDate:null }); setSubmitted(false); setVisible(true) }} />
                </div>
                <DataTable className={'newsfeed'} paginator rows={10} scrollable value={news} >
                    <Column field="title" header="Title" body={titleTemplate} />
                    <Column field="expiryDate" header="Expiry On" body={(rowData) => { return (<>{getDate(rowData.expiryDate)}</>) }} />

                    <Column field="modified_on" header="Updated On" body={(rowData) => { return (<>{getDate(rowData.modified_on)}</>) }} />
                    
                    <Column  header="Pin" field="pin" sortable  sortFunction={pinSort} body={pinTemplate} />

                    <Column field="status" header="Status" body={statusTemplate} />
                    
                    <Column body={actionBodyTemplate} />
                </DataTable>
                <Dialog style={{ width: '75%' }} header={newsobj.id ? 'Edit' : 'Add New'} visible={visible} footer={dialogFooter} onHide={() => setVisible(false)}>

                    <div >
                        <div className="col-12">
                            <label className="fw-5 fs-16">Title</label>
                            <InputText className='mt-2 mb-2' value={newsobj.title} style={{ width: '100%' }} onChange={(e) => { updateNewsObj('title', e.target.value) }} />
                        </div>
                        {submitted && newsobj.title.trim().length === 0 &&
                            <small style={{ color: 'red' }}>
                                Title required
                            </small>

                        }
                    </div>
                    <div >
                        <div className="col-12">
                            <label className="fw-5 fs-16">Message</label>
                            <Editor className='text-area mt-2 mb-2' value={newsobj.message} style={{ minWidth: '100%', minHeight: 200, overflow: 'auto' }} onTextChange={(e) => updateNewsObj('message', e.htmlValue)}  />
                        </div>
                        {submitted && !checkEmptyPTags(newsobj.message) &&
                            <small style={{ color: 'red' }}>
                                Message required
                            </small>

                        }
                    </div>
                    <div >
                        <div className="col-12">
                            <label className="fw-5 fs-16 flex">Expiry On</label>
                            <Calendar value={newsobj.expiryDate} className=" mt-3 mb-3" showIcon   onChange={(e) => updateNewsObj('expiryDate', e.value)} dateFormat="dd-M-yy" view="date" minDate={new Date()} />
                            
                        </div>

                    </div>
                    <div >
                        <div className="col-12">
                            <label className="fw-5 fs-16">Status</label>
                            <div className="flex flex-wrap gap-3 mt-3 mb-3">
                                <div className="flex align-items-center m-1">
                                    <RadioButton inputId="ingredient1" name="pizza" value={2} onChange={(e) => updateNewsObj('status', e.value)} checked={newsobj.status === 2} />
                                    <label htmlFor="ingredient1" className="ml-2">Active</label>
                                </div>
                                <div className="flex align-items-center m-1">
                                    <RadioButton inputId="ingredient2" name="pizza" value={1} onChange={(e) => updateNewsObj('status', e.value)} checked={newsobj.status === 1} />
                                    <label htmlFor="ingredient2" className="ml-2">InActive</label>
                                </div>

                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>

        </div>
    )
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(NewdFeed, comparisonFn);