import React, { useState, useEffect } from "react";
import "../reports/toc_style.css";
import { useHeadsObserver } from "./hooks";
import * as XLSX from "xlsx";
import useForceUpdate from "use-force-update";
import $ from "jquery";
import Axios from "axios";
import { API } from "../../components/constants/api_url";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { saveAs } from "file-saver";
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Dropdown } from 'primereact/dropdown'
import APIServices from "../../service/APIService";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
// pdfMake.fonts = {
//     Roboto: {
//         normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
//         bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
//         italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
//         bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
//       }
// }
const { DateTime } = require("luxon");

window.jQuery = $;
window.$ = $;
const dcf_id = [11, 10, 15, 72, 16, 36];
const SGXReport = () => {
    const [headings, setHeadings] = useState([]);
    const { activeId } = useHeadsObserver();
    const [tableData, setTableData] = useState([]);
    const [workbook, setWorkbook] = useState(null);
    const [firstSheet, setFirstSheet] = useState(null);
    const [year, setYear] = useState(2022);
    const [rfData, setRFData] = useState({});
    const selector = useSelector((state) => state.user.userdetail);
    const emissionFactor = useSelector((state) => state.emissionfactor.emissionFactor);
    const locationList = useSelector((state) => state.sitelist.locationList);
    const siteList = useSelector((state) => state.sitelist.siteList);
    const rflibrary = useSelector((state) => state.library.rf)

    const [dcfass, setDCFAss] = useState([]);
    const [dcflist, setDcfList] = useState([]);
    const [response, setResponse] = useState([]);
    const [report, setReport] = useState([]);
    const [reportEF, setReportEF] = useState([]);
    const [dpreport, setDpReport] = useState([]);

    let months_ = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const forceUpdate = useForceUpdate();
    function formatSubscript__(inputString, findArray, replaceArray) {
        let result = [];
        let currentIndex = 0;

        for (let i = 0; i < findArray.length; i++) {
            const findText = findArray[i];
            const replaceValue = replaceArray[i];
            const index = inputString.toLowerCase().indexOf(findText, currentIndex);

            if (index === -1) {
                // If the findText is not found, add the remaining text and break
                result.push(inputString.substring(currentIndex));
                break;
            }

            // Add the text before the found substring
            result.push(inputString.substring(currentIndex, index));

            // Add the subscripted replaceValue as an object
            result.push(...getResult(findText, replaceValue));

            // Update the currentIndex to continue searching
            currentIndex = index + findText.length;
        }

        // Add any remaining text after the last replacement
        if (currentIndex < inputString.length) {
            result.push(inputString.substring(currentIndex));
        }

        // Filter out empty strings
        result = result.filter((item) => item !== "");

        return result;
    }
    const handleNaNAndInfinity = (value) => {
        if (typeof value === 'number' && isFinite(value)) {
            return value.toFixed(0); // Return the original value if it's a valid number
        } else {
            return 0; // Return 0 for NaN or Infinity
        }
    }
    const getDataByDPArray = (dparr, yr) => {
        let dpreport_ = JSON.parse(JSON.stringify(dpreport))
        let d = 0
        dparr.forEach((dpid) => {
            dpreport_.filter((i) => { return i.dpId === dpid }).forEach((k) => {


                if (checkYear(k.rp, yr) !== 0) {
                    d = d + k.value
                }

            })
        })

        return isNaN(d) ? 0 : d.toFixed(0)
    }
    function formatSubscript(inputString, findArray, replaceArray) {
        return inputString;
    }
    function getResult(str, str2) {
        let arr = str.split(str2.toString());
        arr.splice(1, 0, { text: str2, fontSize: 7, baseline: -5 });
        return arr;
    }
    function ulOrOlToPdfMake(element) {
        const result = [];
        const listItems = Array.from(element.querySelectorAll("li"));

        listItems.forEach((li) => {
            const text = li.textContent.trim();
            if (text !== "") {
                result.push({ text });
            }
        });

        return result;
    }
    async function exportTable2Excel(type) {
        let initialData = [
            {
                alignment: "center", // Center the text horizontally
                margin: [0, 250], // Adjust the top margin to vertically center the text
                text: [
                    { text: "SGX REPORT" + "\n", fontSize: 40, color: "#315874", bold: true },
                    { text: "FY " + year + " - " + (year + 1).toString().substr(2, 3) + "\n", fontSize: 20, color: "#315874" },
                    { text: DateTime.local().toFormat("MMMM dd, yyyy"), fontSize: 20, color: "blue" }, // Customize the font size and color
                ],
                pageBreak: "after",
            },
            {
                toc: {
                    id: "sectionHeader",
                    title: { text: "Table of Content", style: "tdHead" },
                },
                pageBreak: "after",
            },
        ],
            data = [];
        const div = document.getElementById("main");
        for (let i = 0; i < div.children.length; i++) {
            if (div.childNodes[i].tagName.toLowerCase() === "sectionheader") {
                data.push({
                    table: {
                        widths: ["*"],
                        body: [[{ tocItem: "sectionHeader", text: formatSubscript(div.childNodes[i].textContent, ["tco2e", "n2o", "ch4", "co2"], ["2", "2", "4", "2"]), style: "secHead", border: [false, false, false, false] }]],
                    },
                });
                data.push({
                    text: "", // Empty text

                    margin: [10, 10], // Adjust the margin for horizontal space
                });
            } else if (div.childNodes[i].tagName.toLowerCase() === "sectionheader1") {
                data.push({ text: formatSubscript(div.childNodes[i].textContent, ["tco2e", "n2o", "ch4", "co2"], ["2", "2", "4", "2"]), style: "text-under" });
                data.push({
                    text: "", // Empty text

                    margin: [5, 5], // Adjust the margin for horizontal space
                });
            } else {
                if (div.childNodes[i].children.length !== 0) {
                    for (let child = 0; child < div.childNodes[i].children.length; child++) {
                        let tag = div.childNodes[i].childNodes[child].tagName;

                        if (tag) {
                            if (tag === "OL") {
                                data.push({ ol: ulOrOlToPdfMake(div.childNodes[i].childNodes[child]) });
                                data.push({
                                    text: "", // Empty text

                                    margin: [0, 5], // Adjust the margin for horizontal space
                                });
                            } else if (tag === "UL") {
                                data.push({ ul: ulOrOlToPdfMake(div.childNodes[i].childNodes[child]) });
                                data.push({
                                    text: "", // Empty text

                                    margin: [0, 5], // Adjust the margin for horizontal space
                                });
                            } else if (tag === "TABLE") {
                                let content = generatePdfMakeContentFromTable(div.childNodes[i].childNodes[child]);

                                data.push({
                                    table: {
                                        headerRows: 1,
                                        widths: JSON.parse(JSON.stringify(content))[0].map((a, b) => {
                                            return b == 0 ? "*" : "auto";
                                        }),
                                        body: content,
                                        style: "tableStyle",
                                    },
                                });
                                data.push({
                                    text: "", // Empty text

                                    margin: [0, 10], // Adjust the margin for horizontal space
                                });
                            } else if (tag === "IMG") {
                                data.push({ image: div.childNodes[i].childNodes[child].src });

                                data.push({
                                    text: "", // Empty text

                                    margin: [0, 5], // Adjust the margin for horizontal space
                                });
                            } else if (tag === "BR") {
                                let txt = `Definition: Operational Boundaries requires choosing the scope of emissions that will be reported. There are three scopes of emissions that can be reported:
                       \n Scope 1: Direct GHG Emissions from company owned or controlled sources
                       \n Scope 2: Indirect GHG Emissions from purchased electricity or steam.
                       \n According the GHG Protocol Corporate Reporting Standard, Scope 1 and Scope 2 emissions must be reported. Scope 3 emissions are voluntary`;
                                data.push({ text: txt });
                                data.push({
                                    text: "", // Empty text

                                    margin: [0, 5], // Adjust the margin for horizontal space
                                });
                            } else {
                                data.push({ text: formatSubscript(div.childNodes[i].childNodes[child].textContent, ["tco2e", "n2o", "ch4", "co2"], ["2", "2", "4", "2"]) });
                                data.push({
                                    text: "", // Empty text

                                    margin: [0, 5], // Adjust the margin for horizontal space
                                });
                            }
                        }
                    }
                } else {
                    data.push({ text: formatSubscript(div.childNodes[i].textContent, ["tco2e", "n2o", "ch4", "co2"], ["2", "2", "4", "2"]) });
                    data.push({
                        text: "", // Empty text

                        margin: [5, 5], // Adjust the margin for horizontal space
                    });
                }
            }
        }

        data.forEach((k) => {
            if (k.table) {
                if (!haveSameSubarrayLengths(k.table.body)) {
                    console.log(k.table.body);
                }
            }
        });

        let images = {
            clientlogo: document.getElementById("clientlogo").src,
        };

        const header = (currentPage, pageCount, pageSize) => {

            return {
                columns: [
                    {
                        text: `SGX Report FY` + year.toString().substr(2, 3),
                        style: "headerText",
                        margin: [30, 20],
                        fit: [40, 40],
                        alignment: "left",
                    },
                    {
                        image: "clientlogo",
                        fit: [40, 40],
                        margin: [0, 5, 15, 0],
                        alignment: "right",
                    },
                ],
                // Add margins to the header
            };

        };
        const documentDefinition = {
            info: {
                title: "SGX Report - " + DateTime.local().toFormat("MMMM dd, yyyy"),
                author: "Navigos",
                subject: "SGX Report",
                keywords: "Dont share unless people within same organization",
                producer: "EiSqr",
            },
            // userPassword: 'Report@',
            // ownerPassword: '123456',
            permissions: {
                printing: "highResolution", //'lowResolution'
                modifying: false,
                copying: false,
                annotating: true,
                fillingForms: true,
                contentAccessibility: true,
                documentAssembly: true,
            },
            pageSize: "A4",
            pageMargins: [30, 70, 30, 30], // [left, top, right, bottom] margins
            header,
            footer: function (currentPage, pageCount) {
                return {
                    text: "Page " + currentPage + " of " + pageCount,
                    alignment: "center",
                    fontSize: 8,
                };
            },
            content: [...initialData, ...data],
            // defaultStyle: {
            //     font: 'Roboto'
            //   },

            images,
            styles: {
                tdHead: {
                    bold: true,
                    alignment: "center",
                    valign: "middle",
                    fillColor: "#315874",
                    color: "white",
                },

                secHead: {
                    bold: true,
                    fillColor: "#315874",
                    alignment: "center",
                    padding: [10, 10],
                    color: "white",
                },
                headerText: {
                    fontSize: 14,
                    bold: true,
                    color: "para", // Text color
                },
                "text-under": {
                    decoration: "underline",
                    color: "#315874",
                    bold: true,
                },
                boldBlue: {
                    color: "#315874",
                    bold: true,
                },
            },
        };
        console.log([...initialData, ...data]);


        if (type === 0) {
            const pdf = pdfMake.createPdf(documentDefinition);

            pdf.download("SGXReport.pdf");

        } else {
            pdfMake.createPdf(documentDefinition).open({}, window.open('', '_blank'));

        }
    }
    function haveSameSubarrayLengths(data) {
        if (data.length < 2) {
            // If there are fewer than 2 subarrays, they are considered to have the same length.
            return true;
        }

        const firstSubarrayLength = data[0].length;

        for (let i = 1; i < data.length; i++) {
            if (data[i].length !== firstSubarrayLength) {
                return false;
            }
        }

        return true;
    }
    function generatePdfMakeContentFromTable(table) {
        if (!table) {
            console.error(`Table  not found.`);
            return [];
        }

        let contentArray = [],
            maxCol = 0;
        for (let i = 0; i < table.rows.length; i++) {
            if (maxCol <= table.rows[i].cells.length) {
                maxCol = table.rows[i].cells.length;
            }
        }

        for (let i = 0; i < table.rows.length; i++) {
            const rowArray = [];

            for (let j = 0; j < table.rows[i].cells.length; j++) {
                const cell = table.rows[i].cells[j];
                const colSpan = cell.getAttribute("colspan");
                const rowSpan = cell.getAttribute("rowspan");
                const cellText = cell.textContent.trim();

                const cellObject = { text: cellText };
                cellObject.colSpan = parseInt(colSpan);
                cellObject.rowSpan = parseInt(rowSpan);
                cellObject.style = cell.getAttribute("class");
                rowArray.push(cellObject);
                if (parseInt(colSpan) > 1) {
                    for (let j = 0; j < parseInt(colSpan) - 1; j++) {
                        rowArray.push({});
                    }
                }
            }

            contentArray.push(rowArray);
        }
        contentArray.forEach((i, index) => {
            if (i.length !== maxCol) {
                if (contentArray[index - 1]) {
                    contentArray[index - 1].forEach((k, ind) => {
                        if (k.rowSpan) {
                            if (k.rowSpan > 1) {
                                if (k.colSpan === 1) {
                                    i.splice(ind, 0, { text: "", colSpan: k.colSpan, rowSpan: k.rowSpan - 1 });
                                } else {
                                    let newind = ind;
                                    for (let j = 0; j < parseInt(k.colSpan); j++) {
                                        i.splice(newind, 0, {});
                                        newind++;
                                    }
                                }
                            }
                        } else {
                            for (let j = 0; j < maxCol - Object.keys(i).length; j++) {
                                // i.push({id:1});
                            }
                        }
                    });
                }
            }
        });
        contentArray.forEach((i, index) => {
            if (i.length !== maxCol) {
                let len = Object.keys(i).length;
                for (let j = 0; j < maxCol - len; j++) {
                    i.push({});
                }
            }
        });
        return contentArray;
    }

    const isMergedCell = (merge, rowIndex, colIndex) => {
        return merge.some((range) => rowIndex >= range.s.r && rowIndex <= range.e.r && colIndex >= range.s.c && colIndex <= range.e.c);
    };
    const getSum = (subset) => {
        let i = 0;
        subset.forEach((item) => {
            i = i + item[1];
        });
        return i;
    };
    const checkReportingPeriod = (rp, filter) => {
        let count = 0,
            rps = [];
        filter.forEach((item) => {
            if (rp.includes(item)) {
                count = count + 1;
                rps.push(item);
            }
        });
        return { result: count === rp.length, count: count, rps };
    };
    function getObjectsWithEmptyString(arr) {
        return arr
            .map((obj) => {
                const updatedObj = { ...obj };
                Object.keys(updatedObj).forEach((key) => {
                    if (typeof updatedObj[key] === "string" && updatedObj[key].trim() === "") {
                        delete updatedObj[key];
                    }
                });
                return updatedObj;
            })
            .filter((obj) => Object.values(obj).some((value) => value !== ""));
    }
    const getCellColSpan = (rowIndex, colIndex) => {
        const merge = workbook.Sheets[firstSheet]["!merges"] || [];
        for (const range of merge) {
            if (rowIndex >= range.s.r && rowIndex <= range.e.r && colIndex >= range.s.c && colIndex <= range.e.c) {
                return range.e.c - range.s.c + 1;
            }
        }
        return 1;
    };

    const getCellRowSpan = (rowIndex, colIndex) => {
        const merge = workbook.Sheets[firstSheet]["!merges"] || [];
        for (const range of merge) {
            if (rowIndex >= range.s.r && rowIndex <= range.e.r && colIndex >= range.s.c && colIndex <= range.e.c) {
                return range.e.r - range.s.r + 1;
            }
        }
        return 1;
    };
    function findValueByKey(object, key) {
        if (key in object) {
            return object[key];
        } else {
            return null; // or any other default value you want to return if the key is not found
        }
    }
    const getLastResponseByRFID = (key, uid) => {
        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, key);
        console.log(key, result)
        if (result) {
            if (result[0].type === 0) {
                if (typeof uid === "string") {
                    let index = result[0].response.findIndex((k) => {
                        return k.name === uid;
                    });
                    if (index !== -1) {
                        if (result[0].response[index].type === 2) {

                            let value_2 = result[0].response[index].value.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, ' ').replace(/&nbsp;/g, ' ')
                            return value_2 === null ? '' : result[0].response[index].value.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, ' ').replace(/&nbsp;/g, ' ')

                        } else if (result[0].response[index].type === 6 || result[0].response[index].type === 4) {
                            let rflib = rflibrary.findIndex((m) => { return m.id === key })
                            console.log(rflib, key, uid)
                            if (rflib !== -1) {
                                let field = rflibrary[rflib].data1.findIndex((s) => { return s.name === uid })
                                if (field !== -1) {
                                    if (typeof result[0].response[index].value[0] === 'number') {
                                        return rflibrary[rflib].data1[field].values[result[0].response[index].value[0]].label
                                    } else if (typeof result[0].response[index].value[0] === 'string') {

                                        let rgloc = rflibrary[rflib].data1[field].values.findIndex((l) => { return l.value === result[0].response[index].value[0] })
                                        if (rgloc !== -1) {
                                            return rflibrary[rflib].data1[field].values[rgloc].label
                                        }
                                    }else{
                                        return null
                                    }


                                } else {
                                    return 'Field Not Found'
                                }

                            } else {
                                return 'RF Not Found'
                            }
                        }else if (result[0].response[index].type === 3) {
                            let rflib = rflibrary.findIndex((m) => { return m.id === key })
                            console.log(rflib, key, uid)
                            if (rflib !== -1) {
                                let field = rflibrary[rflib].data1.findIndex((s) => { return s.name === uid })
                                if (field !== -1) {
                                    if (typeof result[0].response[index].value[0] === 'number') {
                                        return null
                                    } else if (typeof result[0].response[index].value[0] === 'string') {

                                        let rgloc = rflibrary[rflib].data1[field].values.findIndex((l) => { return l.value === result[0].response[index].value })
                                        if (rgloc !== -1) {
                                            return rflibrary[rflib].data1[field].values[rgloc].label
                                        }
                                    }else{
                                        return null
                                    }


                                } else {
                                    return 'Field Not Found'
                                }

                            } else {
                                return 'RF Not Found'
                            }
                        }
                         else if (result[0].response[index].type === 9) {
                            return DateTime.fromISO(result[0].response[index].value, { zone: 'utc' }).toFormat('dd-MM-yyyy')
                        } else {
                            return result[0].response[index].value;
                        }

                    } else {
                        return 'NA';
                    }
                } else {
                    let str = "";
                    uid.forEach((id) => {
                        let index = result[0].response.findIndex((k) => {
                            return k.name === id;
                        });
                        if (index !== -1) {

                            str = str + " " + result[0].response[index].value;
                        }
                    });
                    if (str.trim().length !== 0) {
                        return str;
                    } else {
                        return 'NA';
                    }
                }
            } else {
                return result[0].data2.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, ' ').replace(/&nbsp;/g, ' ')
            }
        }
        return "";
    };
    const getLastResponseByRFID___ = (key, uid) => {
        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, key);

        if (result) {
            if (result[0].type === 0) {
                if (typeof uid === "string") {
                    let index = result[0].response.findIndex((k) => {
                        return k.name === uid;
                    });
                    if (index !== -1) {
                        return result[0].response[index].value;
                    } else {
                        return null;
                    }
                } else {
                    let str = "";
                    uid.forEach((id) => {
                        let index = result[0].response.findIndex((k) => {
                            return k.name === id;
                        });
                        if (index !== -1) {
                            str = str + " " + result[0].response[index].value;
                        }
                    });
                    if (str.trim().length !== 0) {
                        return str;
                    } else {
                        return null;
                    }
                }
            } else {
                return result[0].data2;
            }
        }
        return "NA";
    };
    const updateDataByYear = (val) => {
        setYear(val);
        forceUpdate();
    };

    useEffect(() => {
        const elements = Array.from(document.querySelectorAll("sectionheader,sectionheader1,sectionheader2")).map((elem) => ({
            id: elem.id,
            text: elem.childNodes[0].textContent.trim(),
            level: Number(elem.nodeName.charAt(13)),
        }));

        let as = [],
            indx = 0;
        elements.forEach((item, ind) => {
            if (item.level === 0) {
                as[indx] = item;
                indx = indx + 1;
            } else if (elements[ind - 1].level === 0) {
                as[indx] = { item: [item], level: 1 };
                if (elements[ind + 1] !== undefined && elements[ind + 1].level === 0) {
                    indx = indx + 1;
                }
            } else {
                as[indx].item.push(item);
                if (elements[ind + 1] !== undefined && elements[ind + 1].level === 0) {
                    indx = indx + 1;
                }
            }
        });

        setHeadings(as);
    }, []);
    const groupArrayObject = (array, obj) => {
        return array.reduce(
            (group, arr) => {
                let key = arr[obj];

                group[key] = group[key] ?? [];

                group[key].push(arr);

                return group;
            },

            {}
        );
    };
    useEffect(() => {
        let gtaString = {
            include: ["newTargetsTwos", "newIndicatorTwos", "newInitiatives"],
        };
        let dcf_list = [],
            dcf_submitted = [],
            locloc = [];
        let category_string = {
            include: [{ relation: "newTopics", scope: { include: [{ relation: "newMetrics", scope: { include: [{ relation: "newDataPoints" }] } }] } }],
        };

        const promise1 = APIServices.get(API.DCF);
        const promise2 = APIServices.get(API.DCF_Submit_UP(selector.id));
        const promise3 = APIServices.get(API.DP_report_UP(selector.id));
        const promise4 = APIServices.get(API.RF_Submit_UP(selector.id));
        const promise5 = APIServices.get(API.AssignDCFUser_UP(selector.id));
        Promise.all([promise1, promise2, promise3, promise4, promise5]).then(function (values) {
            setDCFAss(
                values[4].data
                    .filter((k) => {
                        return dcf_id.includes(k.dcfId) && k.type === 0;
                    })
                    .map((k) => {
                        return { dcfId: k.dcfId, site: k.site[0] };
                    })
            );
            dcf_list = values[0].data;
            setDcfList(values[0].data);
            dcf_submitted = values[1].data;
            setDpReport(values[2].data);
            let val = [],
                filterarr = groupArrayObject(values[2].data, "submitId");
            Object.keys(filterarr).forEach((item) => {
                val.push({ id: filterarr[item][0].submitId, rp: filterarr[item][0].rp, rp_: getRP_(filterarr[item][0].rp), year: filterarr[item][0].year, dcf: filterarr[item][0].dcfId, site: filterarr[item][0].site, response: Object.values(groupArrayObject(filterarr[item], "form_id")) });
            });

            setResponse(val);
            let rfid_group = groupArrayObject(values[3].data, "rfid");
            Object.keys(rfid_group).forEach((key) => {
                rfid_group[key].sort((a, b) => {
                    return b.id - a.id;
                });
            });
            setRFData(rfid_group);
            forceUpdate();
        });
    }, []);
    useEffect(() => {
        if (response.length !== 0) {
            let report_ = renderData(
                { location: { a: { name: "All", id: 0 }, b: { name: "All", id: 0 }, c: { name: "All", id: 0 } }, year: { name: "All", id: 0 }, month: null, indicator: { id: 0 }, timeline: [], from: getDateObjectByMonth_Year(4, year), to: getDateObjectByMonth_Year(3, year + 1) },
                1
            );
            let reportEF_ = renderDataEF(
                { location: { a: { name: "All", id: 0 }, b: { name: "All", id: 0 }, c: { name: "All", id: 0 } }, year: { name: "All", id: 0 }, month: null, indicator: { id: 0 }, timeline: [], from: getDateObjectByMonth_Year(4, year), to: getDateObjectByMonth_Year(3, year + 1) },
                1
            );

            // report_[`${year - 1}`] = renderData({ location: { a: { name: 'All', id: 0 }, b: { name: 'All', id: 0 }, c: { name: 'All', id: 0 } }, year: { name: 'All', id: 0 }, month: null, indicator: { id: 0 }, timeline: [], from: getDateObjectByMonth_Year(4, year - 1), to: getDateObjectByMonth_Year(3, year) }, 1)
            setReport(report_);
            setReportEF(reportEF_);
            forceUpdate();
        }
    }, [response, year]);

    const getScopeData = (year, area) => {
        if (report[year]) {
            return report[year]
                .filter((k) => {
                    return k.scope === area;
                })
                .map((j) => {
                    return j.ghg;
                })
                .reduce((a, b) => {
                    return a + b;
                }, 0)
                .toFixed(2);
        }
        return 0;
    };
    const checkYear = (rp, yr) => {
        let betweenMonths = [];

        let endDate = moment.utc(getDateObjectByMonth_Year(2, yr + 1)).local();
        let startDate = moment.utc(getDateObjectByMonth_Year(2, yr)).local();
        while (startDate.startOf("month") <= endDate.startOf("month")) {
            betweenMonths.push(startDate.format("MM-YYYY"));
            startDate.add(1, "month");
        }
        return betweenMonths.filter((i) => {
            return rp.includes(i);
        }).length === rp.length
            ? 1
            : betweenMonths.filter((i) => {
                return rp.includes(i);
            }).length;
    };
    const getDataByDP = (dpid, yr) => {
        let dpreport_ = JSON.parse(JSON.stringify(dpreport));
        let d = 0;

        dpreport_
            .filter((i) => {
                return i.dpId === dpid;
            })
            .forEach((k) => {
                if (checkYear(k.rp, yr) !== 0) {
                    d = d + k.value;
                }
            });

        return d;
    };
    const checkScope = (arr) => {
        let index = dcfass.findIndex((l) => {
            return arr.includes(l.dcfId);
        });
        return index !== -1 ? true : false;
    };
    function concatenateArrayWithAnd(array) {
        if (array.length === 1) {
            return array[0];
        } else if (array.length > 1) {
            const lastElement = array.pop(); // Remove the last element
            return `${array.join(", ")} and ${lastElement}`;
        } else {
            return "NA"; // Return an empty string if the array is empty
        }
    }
    const getMCFuelUsed = () => {
        let result = [];

        report
            .filter((l) => {
                return l.dcfId === 15;
            })
            .forEach((i, j) => {
                !result.includes(i.mode) && result.push(i.mode);
            });

        return concatenateArrayWithAnd(result);
    };
    const getSCFuelUsed = () => {
        let result = [];

        report
            .filter((l) => {
                return l.dcfId === 11;
            })
            .forEach((i, j) => {
                !result.includes(i.fuel_type) && result.push(i.fuel_type);
            });
        return concatenateArrayWithAnd(result);
    };
    const getFEGasUsed = () => {
        let result = [];

        report
            .filter((l) => {
                return l.dcfId === 10;
            })
            .forEach((i, j) => {
                result.push(i.gastype);
            });
        return concatenateArrayWithAnd(result);
    };
    function removeDuplicatesByProperties(arr, keys) {
        const seen = new Set();
        return arr.filter((item) => {
            const key = JSON.stringify(keys.map((key) => item[key]));
            if (!seen.has(key)) {
                seen.add(key);
                return true;
            }
            return false;
        });
    }
    const renderFEGas = () => {
        let result = [];

        reportEF
            .filter((l) => {
                return l.dcfId === 10;
            })
            .forEach((i, j) => {
                result.push({ type: i.gastype, ghg: i.co2e_.toFixed(2) + " kg CO2e/kg" });
            });

        return result.length === 0 ? [{ type: "Not Found", ghg: 0 }] : result;
    };
    const renderSCFuel = () => {
        let result = [];

        reportEF
            .filter((l) => {
                return l.dcfId === 11;
            })
            .forEach((i, j) => {
                result.push({ type: i.fuel_type + "-" + i.unit, co2_: i.co2_.toFixed(2) + " kg CO2e/kg", n2o_: i.n2o_.toFixed(2) + " kg CO2e/kg", ch4_: i.ch4_.toFixed(2) + " kg CO2e/kg" });
            });
        return result.length === 0 ? [{ type: "Not Found", co2_: 0, ch4_: 0, n2o_: 0 }] : removeDuplicatesByProperties(result, ["co2_", "n2o_", "ch4", "unit", "fuel_type"]);
    };

    const renderMCFuel = () => {
        let result = [];

        reportEF
            .filter((l) => {
                return l.dcfId === 15;
            })
            .forEach((i, j) => {
                console.log("i", i);
                result.push({ type: i.mode + " - " + i.fuel_cat, ghg: i.co2e_.toFixed(2) + " kg CO2e /litre" });
            });

        return result.length === 0 ? [{ type: "Not Found", ghg: 0 }] : result;
    };
    const getScopeDataByDCF = (id) => {
        console.log(
            JSON.parse(JSON.stringify(report)).filter((i) => {
                return id.includes(i.dcfId);
            }),
            "report",
            id
        );
        let report_ = JSON.parse(JSON.stringify(report))
            .filter((i) => {
                return id.includes(i.dcfId);
            })
            .map((i) => {
                return i.ghg;
            })
            .reduce((a, b) => {
                return a + b;
            }, 0);
        return report_.toFixed(2);
    };
    const renderEmissionScope1 = (text) => {
        return (
            <>
                {checkScope([11, 10, 15]) && (
                    <>
                        {checkScope([11]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Stationary Combustion Emission
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([11])}
                                </td>
                                <td colSpan="1" className="text-center"></td>
                            </tr>
                        )}
                        {checkScope([15]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Mobile Combustion Emission
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([15])}
                                </td>
                                <td colSpan="1" className="text-center"></td>
                            </tr>
                        )}
                        {checkScope([10]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Fugitive Emissions
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([10])}
                                </td>
                                <td colSpan="1" className="text-center"></td>
                            </tr>
                        )}
                        <tr>
                            <td colspan="1" rowspan="1">
                                Subtotal Scope 1 Emissions
                            </td>
                            <td colspan="1" rowspan="1"></td>
                            <td colSpan="1" className="text-center">
                                {getScopeDataByDCF([10, 11, 15])}
                            </td>
                        </tr>
                    </>
                )}
            </>
        );
    };
    const renderEmissionScope3 = (text) => {
        return (
            <>
                {checkScope([16, 36]) && (
                    <>
                        {checkScope([16]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Category 1 - Purchased goods and services
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([16])}
                                </td>
                                <td colSpan="1" className="text-center"></td>
                            </tr>
                        )}
                        {checkScope([36]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Category 6 - Business travel
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([36])}
                                </td>
                                <td colSpan="1" className="text-center"></td>
                            </tr>
                        )}
                        <tr>
                            <td colspan="1" rowspan="1">
                                Subtotal Scope 3 Emissions
                            </td>
                            <td colspan="1" rowspan="1"></td>
                            <td colSpan="1" className="text-center">
                                {getScopeDataByDCF([36, 16])}
                            </td>
                        </tr>
                    </>
                )}
            </>
        );
    };
    const renderEmissionScope1_ = (text) => {
        return (
            <>
                {checkScope([11, 10, 15]) && (
                    <>
                        {checkScope([11]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Stationary Combustion Emission
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([11])}
                                </td>
                            </tr>
                        )}
                        {checkScope([15]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Mobile Combustion Emission
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([15])}
                                </td>
                            </tr>
                        )}
                        {checkScope([10]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Fugitive Emissions
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([10])}
                                </td>
                            </tr>
                        )}
                    </>
                )}
            </>
        );
    };
    const renderEmissionScope3_ = (text) => {
        return (
            <>
                {checkScope([16, 36]) && (
                    <>
                        {checkScope([16]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Category 1 - Purchased goods and services
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([16])}
                                </td>
                            </tr>
                        )}
                        {checkScope([36]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Category 6 - Business travel
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([36])}
                                </td>
                            </tr>
                        )}
                    </>
                )}
            </>
        );
    };
    const renderEmissionScope1_1 = (text) => {
        return (
            <>
                {checkScope([11, 10, 15]) && (
                    <>
                        {checkScope([11]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Stationary Combustion Emission
                                </td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                            </tr>
                        )}
                        {checkScope([15]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Mobile Combustion Emission
                                </td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                            </tr>
                        )}
                        {checkScope([10]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Fugitive Emissions
                                </td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                            </tr>
                        )}
                    </>
                )}
            </>
        );
    };
    const renderEmissionScope3_1 = (text) => {
        return (
            <>
                {checkScope([16, 36]) && (
                    <>
                        {checkScope([16]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Category 1 - Purchased goods and services
                                </td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                            </tr>
                        )}
                        {checkScope([36]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Category 6 - Business travel
                                </td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                            </tr>
                        )}
                    </>
                )}
            </>
        );
    };
    const renderEmissionByScopeTable = (text) => {
        return (
            <div class="gridlines-container">
                <table class="gridlines">
                    <tbody>
                        <tr>
                            <td colspan="1" rowspan="1" className="tdHead">
                                Scope of Emission
                            </td>
                            <td colspan="1" rowspan="1" className="tdHead">
                                tCO2e
                            </td>
                        </tr>
                        {checkScope([11, 10, 15]) && (
                            <>
                                <tr>
                                    <td colspan="1" rowspan="1">
                                        Scope 1- Direct Emissions
                                    </td>
                                    <td colspan="1" rowspan="1">
                                        {getScopeDataByDCF([11, 10, 15])}
                                    </td>
                                </tr>
                            </>
                        )}
                        {checkScope([72]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Scope 2- Indirect Emission
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([72])}
                                </td>
                            </tr>
                        )}
                        {checkScope([16, 36]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Scope 3- Indirect emissions
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([16, 36])}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    };
    const renderEmissionByCategoryTable = () => {
        return (
            <div class="gridlines-container">
                <table class="gridlines">
                    <tbody>
                        <tr>
                            <td colspan="1" rowspan="1" className="tdHead">
                                Emission Profile
                            </td>
                            <td colspan="1" rowspan="1" className="tdHead">
                                tCO2e
                            </td>
                        </tr>
                        {checkScope([11, 10, 15]) && (
                            <>
                                {checkScope([11]) && (
                                    <tr>
                                        <td colspan="1" rowspan="1">
                                            Stationary Combustion (Fuel Used)
                                        </td>
                                        <td colspan="1" rowspan="1">
                                            {getScopeDataByDCF([11])}
                                        </td>
                                    </tr>
                                )}
                                {checkScope([15]) && (
                                    <tr>
                                        <td colspan="1" rowspan="1">
                                            Mobile Combustion (Owned Vehicles)
                                        </td>
                                        <td colspan="1" rowspan="1">
                                            {getScopeDataByDCF([15])}
                                        </td>
                                    </tr>
                                )}
                                {checkScope([10]) && (
                                    <tr>
                                        <td colspan="1" rowspan="1">
                                            Fugitive Emissions (Refrigerants)
                                        </td>
                                        <td colspan="1" rowspan="1">
                                            {getScopeDataByDCF([10])}
                                        </td>
                                    </tr>
                                )}
                            </>
                        )}
                        {checkScope([72]) && (
                            <>
                                <tr>
                                    <td colspan="1" rowspan="1">
                                        Emissions from purchased energy (Grid Electricity)
                                    </td>
                                    <td colspan="1" rowspan="1">
                                        {getScopeDataByDCF([72])}
                                    </td>
                                </tr>
                            </>
                        )}
                        {checkScope([16, 36]) && (
                            <>
                                {checkScope([16]) && (
                                    <tr>
                                        <td colspan="1" rowspan="1">
                                            Purchased Goods and Services
                                        </td>
                                        <td colspan="1" rowspan="1">
                                            {getScopeDataByDCF([16])}
                                        </td>
                                    </tr>
                                )}
                                {checkScope([36]) && (
                                    <tr>
                                        <td colspan="1" rowspan="1">
                                            Business Travel
                                        </td>
                                        <td colspan="1" rowspan="1">
                                            {getScopeDataByDCF([36])}
                                        </td>
                                    </tr>
                                )}
                                {/* <tr>
                            <td colspan="1" rowspan="1" >Employee Commute
                            </td>
                            <td colspan="1" rowspan="1" >
                            </td>
                        </tr> */}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        );
    };
    const getDataByDCFDPID = (dcfid, dpid, yr) => {
        let response_ = JSON.parse(JSON.stringify(response));
        let d = 0;

        response_.forEach((k) => {
            if (k.dcf === dcfid) {
                if (checkYear(k.rp, yr) !== 0) {
                    let result = k.response.filter((k) => {
                        return k.name === dpid;
                    });
                    if (result.length !== 0) {
                        d = d + parseInt(result[0].value.match(/\d+/)[0]);
                    }
                }
            }
        });

        return d;
    };

    function getDateObjectByMonth_Year(month, year) {
        if (isNaN(month) || isNaN(year)) {
            throw new Error("Invalid month or year");
        }

        const normalizedMonth = Math.max(1, Math.min(12, month));

        const date = DateTime.fromObject({ year, month: normalizedMonth, day: 1 });

        return date.toJSDate();
    }
    const getRP_ = (rp) => {
        if (rp.length === 1) {
            return months_[parseInt(rp[0].split("-")[0]) - 1] + "-" + rp[0].split("-")[1].slice(-2);
        } else {
            return months_[parseInt(rp[0].split("-")[0]) - 1] + "-" + rp[0].split("-")[1].slice(-2) + "to" + months_[parseInt(rp[rp.length - 1].split("-")[0]) - 1] + "-" + rp[rp.length - 1].split("-")[1].slice(-2);
        }
    };
    const getClassName = (level) => {
        switch (level) {
            case 1:
                return "head1";
            case 2:
                return "head2";
            case 3:
                return "head3";
            default:
                return null;
        }
    };

    const checkSite = (id, filter) => {
        let idlist = [];

        siteList.forEach((country) => {
            if (filter.a.id === 0 || filter.a.id === country.id) {
                country.locationTwos.forEach((city) => {
                    if (filter.b.id === 0 || filter.b.id === city.id) {
                        city.locationThrees.forEach((loc) => {
                            if (filter.c.id == 0 || filter.c.id === loc.id) {
                                idlist.push(loc.id);
                            }
                        });
                    }
                });
            }
        });

        return idlist.includes(id);
    };
    const groupArrayObject_3_Keys = (array, obj1, obj2, obj3) => {
        return array.reduce((result, arr) => {
            let key1 = arr[obj1];
            let key2 = arr[obj2];
            let key3 = arr[obj3];

            const key = `${key1}-${key2}-${key3}`;

            if (!result[key]) {
                result[key] = [];
            }

            result[key].push(arr);

            return result;
        }, {});
    };
    const renderData = (search) => {
        let betweenMonths = [],
            betweenYears = [];
        let monthly_fg = [];

        let endDate = moment.utc(search.to).local();
        let startDate = moment.utc(search.from).local();
        let year = moment(moment.utc()).format("YYYY");

        if (search.to !== null && search.from !== null) {
            while (startDate.startOf("month") <= endDate.startOf("month")) {
                betweenMonths.push(startDate.format("MM-YYYY"));
                !betweenYears.includes(startDate.format("YYYY")) && betweenYears.push(startDate.format("YYYY"));

                startDate.add(1, "month");
            }

            let res = JSON.parse(JSON.stringify(response));

            res.forEach((report, rind) => {
                let sc_total = 0,
                    fg_total = 0;

                if (checkSite(report.site, search.location)) {
                    report.sitename = locationList.filter((loc) => {
                        return loc.id === report.site;
                    })[0].name;
                    if (report.dcf === 16 && (search.indicator.id === 0 || search.indicator.id === 122)) {
                        report.response.forEach((fg, ind) => {
                            let date = fg.filter((i) => {
                                return i.dpId === "DPA0285";
                            })[0].value;
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            if (checkReportingPeriod([moment(date).format("MM-YYYY")], betweenMonths).result) {
                                let item_class = fg.filter((i) => {
                                    return i.dpId === "DPA0287";
                                })[0].value.name;
                                let item_qty = fg.filter((i) => {
                                    return i.dpId === "DPA0288";
                                })[0].value;
                                let price_per_item = fg.filter((i) => {
                                    return i.dpId === "DPA0289";
                                })[0].value;

                                let total =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.item_classification === item_class;
                                    })[0]["co2e_in_kg"] /
                                        1000) *
                                    item_qty *
                                    price_per_item;

                                fg_total = total + fg_total;

                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.item_class === item_class;
                                });

                                if (updateind === -1) {
                                    monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, item_class: item_class });
                                } else {
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                }
                            }
                        });
                    }
                    if (report.dcf === 36 && (search.indicator.id === 0 || search.indicator.id === 123)) {
                        report.response.forEach((fg, ind) => {
                            let date = fg.filter((i) => {
                                return i.dpId === "DPA0290";
                            })[0].value;
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            if (checkReportingPeriod([moment(date).format("MM-YYYY")], betweenMonths).result) {
                                let total = 0;

                                let travel_mode = fg.filter((i) => {
                                    return i.dpId === "DPA0291";
                                })[0].value.name;
                                let passenger = fg.filter((i) => {
                                    return i.dpId === "DPA0292";
                                })[0].value;

                                if (travel_mode.toLowerCase() === "air") {
                                    total =
                                        fg.filter((i) => {
                                            return i.dpId === "DP_co2e_mt";
                                        })[0].value * passenger;

                                    let updateind = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode;
                                    });

                                    if (updateind === -1) {
                                        monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode });
                                    } else {
                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                    }

                                    fg_total = total + fg_total;
                                } else if (travel_mode.toLowerCase() === "road") {
                                    let veh_cat = fg.filter((i) => {
                                        return i.dpId === "DPA0295";
                                    })[0].value.name;
                                    let veh_type = fg.filter((i) => {
                                        return i.dpId === "DPA0337";
                                    })[0].value;
                                    let fuel = fg.filter((i) => {
                                        return i.dpId === "DPA0338";
                                    })[0].value;
                                    let km = fg.filter((i) => {
                                        return i.dpId === "DP_KM";
                                    })[0].value;
                                    if (veh_cat.includes("Cars") || veh_cat.includes("Motor")) {
                                        if (veh_cat.includes("Cars")) {
                                            total =
                                                (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => {
                                                    return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.fuel_type === fuel.name && k.unit === "km";
                                                })[0]["co2e_in_kg"] /
                                                    1000) *
                                                km *
                                                passenger;
                                        } else {
                                            total =
                                                (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => {
                                                    return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.unit === "km";
                                                })[0]["co2e_in_kg"] /
                                                    1000) *
                                                km *
                                                passenger;
                                        }
                                    } else {
                                        total =
                                            (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => {
                                                return k.vehicle_category === veh_cat && k.unit === "passenger.km";
                                            })[0]["co2e_in_kg"] /
                                                1000) *
                                            km *
                                            passenger;
                                    }

                                    let updateind = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode;
                                    });

                                    if (updateind === -1) {
                                        monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode });
                                    } else {
                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                    }

                                    fg_total = total + fg_total;
                                } else if (travel_mode.toLowerCase() === "rail") {
                                    total =
                                        fg.filter((i) => {
                                            return i.dpId === "DP_KM";
                                        })[0].value *
                                        passenger *
                                        0.00116;

                                    let updateind = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode;
                                    });

                                    if (updateind === -1) {
                                        monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode });
                                    } else {
                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                    }

                                    fg_total = total + fg_total;
                                }
                            }
                        });
                    }

                    if (report.dcf === 11 && (search.indicator.id === 0 || search.indicator.id === 93)) {
                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths);

                        let total = 0,
                            co2 = 0,
                            ch4 = 0,
                            n2o = 0,
                            sc_data = [];
                        report.response.forEach((fg, ind) => {
                            let fuel_cat = fg.filter((i) => {
                                return i.dpId === "DPA0130";
                            })[0].value.name;
                            let fuel_type = fg.filter((i) => {
                                return i.dpId === "DPA0131";
                            })[0].value.name;
                            let unit = fg.filter((i) => {
                                return i.dpId === "DPA0132";
                            })[0].value.name;
                            let consumed = fg.filter((i) => {
                                return i.dpId === "DPA0336";
                            })[0].value;
                            let fuel_cat_ind = sc_data.findIndex((k) => {
                                return k.fuel_type === fuel_type;
                            });

                            if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                                let carbon = fg.filter((i) => {
                                    return i.dpId === "DPA0134";
                                })[0].value;
                                let cv = fg.filter((i) => {
                                    return i.dpId === "DPA0133";
                                })[0].value;
                                if (carbon > 0 && cv > 0) {
                                    let gj = (carbon * 3.664 * 1000) / cv;
                                    total = (gj * 0.000004184 * consumed) / 1000;
                                    fg["value"] = ["Emission Factor-", 3.664, " ,EnergyProduced-", 0.000004184];
                                    co2 = 0;
                                    ch4 = 0;
                                    n2o = 0;
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_type: fuel_type, ghg: total, co2, n2o, ch4 });
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total;
                                        sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                                        sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                                        sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                                    }
                                } else {
                                    total =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["co2e_in_kg"] /
                                            1000) *
                                        consumed;
                                    co2 =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["co2_in_kg"] /
                                            1000) *
                                        consumed;
                                    ch4 =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["ch4_in_kg"] /
                                            1000) *
                                        consumed;
                                    n2o =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["n2o_in_kg"] /
                                            1000) *
                                        consumed;
                                    fg["value"] = ["co2-", co2 / consumed, " ,ch4-", ch4 / consumed, " ,n2o-", n2o / consumed];
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_type: fuel_type, ghg: total, co2, n2o, ch4 });
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total;
                                        sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                                        sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                                        sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                                    }
                                }
                            } else {
                                total =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["co2e_in_kg"] /
                                        1000) *
                                    consumed;
                                co2 =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["co2_in_kg"] /
                                        1000) *
                                    consumed;
                                ch4 =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["ch4_in_kg"] /
                                        1000) *
                                    consumed;
                                n2o =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["n2o_in_kg"] /
                                        1000) *
                                    consumed;
                                fg["value"] = ["co2-", co2 / consumed, " ,ch4-", ch4 / consumed, " ,n2o-", n2o / consumed];
                                if (fuel_cat_ind === -1) {
                                    sc_data.push({ fuel_type: fuel_type, ghg: total, co2, n2o, ch4 });
                                } else {
                                    sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total;
                                    sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                                    sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                                    sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                                }
                            }
                        });
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, "MM-YYYY").toDate();
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            sc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.fuel_type === item.fuel_type;
                                });

                                if (updateind === -1) {
                                    monthly_fg.push({
                                        scope: 1,
                                        year: moment(date).format("YYYY"),
                                        month: moment(date).format("MMM-YYYY"),
                                        ghg: item.ghg / addedMonth.count,
                                        dcf: report.dcf,
                                        site: report.site,
                                        fuel_type: item.fuel_type,
                                        co2: (item.co2 * 1000) / addedMonth.count,
                                        n2o: (item.n20 * 1000) / addedMonth.count,
                                        ch4: (item.ch4 * 1000) / addedMonth.count,
                                    });
                                } else {
                                    monthly_fg[updateind].co2 = ((item.co2 * 1000) / addedMonth.count) * 1000 + monthly_fg[updateind].co2;
                                    monthly_fg[updateind].n2o = ((item.n2o * 1000) / addedMonth.count) * 1000 + monthly_fg[updateind].n2o;
                                    monthly_fg[updateind].ch4 = ((item.ch4 * 1000) / addedMonth.count) * 1000 + monthly_fg[updateind].ch4;
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + item.ghg / addedMonth.count;
                                }
                            });
                        });
                    }
                    if (report.dcf === 10 && (search.indicator.id === 0 || search.indicator.id === 116)) {
                        let add = [];

                        report.response.forEach((fg, ind) => {
                            let date = fg.filter((i) => {
                                return i.dpId === "DPA0137";
                            })[0].value;
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            if (checkReportingPeriod([moment(date).format("MM-YYYY")], betweenMonths).result) {
                                let gastype = fg.filter((i) => {
                                    return i.dpId === "DPA0136";
                                })[0].value.name;
                                let gasrefilled = fg.filter((i) => {
                                    return i.dpId === "DPA0138";
                                })[0].value;
                                let total =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => {
                                        return k.gas_type === gastype;
                                    })[0]["co2e_in_kg"] /
                                        1000) *
                                    gasrefilled;

                                fg_total = total + fg_total;

                                fg["gas"] = gastype;
                                fg["gasfilled"] = gasrefilled;
                                fg["value"] = ["EmissionFactor-", total / gasrefilled];
                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.gastype === gastype;
                                });

                                if (updateind === -1) {
                                    monthly_fg.push({ scope: 1, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: 0, dcf: report.dcf, site: report.site, gastype: gastype, ghg: total });
                                } else {
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                }
                            }
                        });
                    }
                    if (report.dcf === 72 && (search.indicator.id === 0 || search.indicator.id === 121)) {
                        report.rp.forEach((i) => {
                            if (checkReportingPeriod([i], betweenMonths).result) {
                                let yearind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY");
                                });
                                let monthind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY") && i.month === moment(i, "MM-YYYY").format("MMM-YYYY");
                                });
                                let dcfind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY") && i.month === moment(i, "MM-YYYY").format("MMM-YYYY") && i.dcf === report.dcf;
                                });
                                let siteind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY") && i.month === moment(i, "MM-YYYY").format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                                });

                                let ef = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[1].stdTopics[0].data1[0].importedData[0]["kwh_in_tco2e"];

                                let renewable =
                                    report.response[0].filter((i) => {
                                        return i.dpId === "DPA0156";
                                    })[0].value / report.rp.length;
                                let nonrenewable =
                                    report.response[0].filter((i) => {
                                        return i.dpId === "DPA0157";
                                    })[0].value / report.rp.length;

                                if (yearind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable });
                                } else if (monthind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable });
                                } else if (dcfind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable });
                                } else if (siteind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable });
                                }
                            }
                        });
                    }
                    if (report.dcf === 15 && (search.indicator.id === 0 || search.indicator.id === 118)) {
                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths);

                        let ef_by_fuel = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[4].data1[0].importedData;
                        let ef_by_distance = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[1].data1[0].importedData;

                        let total = 0,
                            co2 = 0,
                            ch4 = 0,
                            n2o = 0,
                            mc_data = [];

                        report.response.forEach((fg, ind) => {
                            let mode = fg.filter((i) => {
                                return i.dpId === "DP_MODE";
                            })[0].value
                                ? "by distance"
                                : "by fuel";
                            let fuel_cat = fg.filter((i) => {
                                return i.dpId === "DPA0140";
                            })[0].value;
                            let fuel_type = fg.filter((i) => {
                                return i.dpId === "DPA0139";
                            })[0].value;
                            let fuel = fg.filter((i) => {
                                return i.dpId === "DPA0141";
                            })[0].value.name;
                            let unit = fg.filter((i) => {
                                return i.dpId === "DPA0339";
                            })[0].value;
                            let km = fg.filter((i) => {
                                return i.dpId === "DPA0144";
                            })[0].value;
                            let fuel_filled = fg.filter((i) => {
                                return i.dpId === "DPA0143";
                            })[0].value;
                            let fuel_cat_ind = mc_data.findIndex((k) => {
                                return k.fuel === fuel;
                            });

                            if (mode === "by distance") {
                                total = ef_by_distance.filter((k) => {
                                    return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name;
                                })[0]["co2e_in_kg"];

                                if (fuel_cat_ind === -1) {
                                    mc_data.push({ mode: fuel, ghg: total * km, fuel_cat: fuel_cat.name });
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total;
                                }
                            } else {
                                total = ef_by_fuel.filter((k) => {
                                    return k.fuel.trim() === fuel.trim();
                                })[0]["co2e_in_kg"];

                                if (fuel_cat_ind === -1) {
                                    mc_data.push({ mode: fuel, ghg: total * fuel_filled });
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total;
                                }
                            }
                        });
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, "MM-YYYY").toDate();

                            mc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode;
                                });

                                if (updateind === -1) {
                                    let updateind2 = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode;
                                    });
                                    if (updateind2 !== -1) {
                                        monthly_fg[updateind2].ghg = item.ghg / addedMonth.count + monthly_fg[updateind2].ghg;
                                    } else {
                                        monthly_fg.push({ scope: 1, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: 0, dcf: report.dcf, site: report.site, ghg: item.ghg / addedMonth.count, mode: item.mode, fuel_cat: item.fuel_cat });
                                    }
                                } else {
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + item.ghg / addedMonth.count;
                                }
                            });
                        });
                    }
                }
            });

            let scope12_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 1 || i.scope === 2;
                }),
                "month"
            );
            let scope3_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 3;
                }),
                "month"
            );
            let scope1_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 1;
                }),
                "month"
            );
            let scope2_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 2;
                }),
                "month"
            );
            let pie = [];
            monthly_fg
                .filter((i) => {
                    return i.scope === 1;
                })
                .forEach((item) => {
                    let index = pie.findIndex((j) => {
                        return j.name === "Scope " + item.scope;
                    });
                    if (index === -1) {
                        pie.push({ name: "Scope " + item.scope, y: item.ghg });
                    } else {
                        pie[index].y = pie[index].y + item.ghg;
                    }
                });
            monthly_fg
                .filter((i) => {
                    return i.scope === 2;
                })
                .forEach((item) => {
                    let index = pie.findIndex((j) => {
                        return j.name === "Scope " + item.scope;
                    });
                    if (index === -1) {
                        pie.push({ name: "Scope " + item.scope, y: item.ghg });
                    } else {
                        pie[index].y = pie[index].y + item.ghg;
                    }
                });
            monthly_fg
                .filter((i) => {
                    return i.scope === 3;
                })
                .forEach((item) => {
                    let index = pie.findIndex((j) => {
                        return j.name === "Scope " + item.scope;
                    });
                    if (index === -1) {
                        pie.push({ name: "Scope " + item.scope, y: item.ghg });
                    } else {
                        pie[index].y = pie[index].y + item.ghg;
                    }
                });

            let index1 = pie.findIndex((j) => {
                return j.name === "Scope 1";
            });
            let index2 = pie.findIndex((j) => {
                return j.name === "Scope 2";
            });
            let index3 = pie.findIndex((j) => {
                return j.name === "Scope 3";
            });

            if (index1 === -1) {
                pie.push({ name: "Scope 1", y: 0 });
            }
            if (index2 === -1) {
                pie.push({ name: "Scope 2", y: 0 });
            }
            if (index3 === -1) {
                pie.push({ name: "Scope 3", y: 0 });
            }

            if (scope1_array.length !== 0) {
                let scope1_tier2 = [],
                    final = [];
                Object.keys(scope1_array).forEach((key) => {
                    scope1_array[key].forEach((item) => {
                        item.dcfId = item.dcf;
                        item.dcf =
                            dcflist.filter((l) => {
                                return l.id === item.dcf;
                            }).length === 0
                                ? item.dcf
                                : dcflist.filter((l) => {
                                    return l.id === item.dcf;
                                })[0].title;

                        if (
                            scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope1_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: "red",
                                y: item.ghg,

                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope1_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope1_tier2[ind].y = scope1_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope1_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope1_tier2[ind].subset[subind][1] = scope1_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    });
                });

                betweenYears.forEach((j) => {
                    let dataset = JSON.parse(JSON.stringify(scope1_tier2)).map((i) => {
                        i.subset.sort((a, b) => {
                            return moment(a[0], "MMM-YYYY").toDate() - moment(b[0], "MMM-YYYY").toDate();
                        });
                        i.subset = i.subset.filter((k) => {
                            return k[0].split("-")[1] === j;
                        });
                        i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
                        i.incomplete = false;
                        return i;
                    });

                    final.push(dataset);
                });
            }
            if (scope2_array.length !== 0) {
                let scope2_tier2 = [],
                    final = [];
                Object.keys(scope2_array).forEach((key) => {
                    scope2_array[key].forEach((item) => {
                        item.dcfId = item.dcf;
                        item.dcf =
                            dcflist.filter((l) => {
                                return l.id === item.dcf;
                            }).length === 0
                                ? item.dcf
                                : dcflist.filter((l) => {
                                    return l.id === item.dcf;
                                })[0].title;
                        if (
                            scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope2_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: "red",
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope2_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope2_tier2[ind].y = scope2_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope2_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope2_tier2[ind].subset[subind][1] = scope2_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    });
                });
                betweenYears.forEach((j) => {
                    let dataset = JSON.parse(JSON.stringify(scope2_tier2)).map((i) => {
                        i.subset.sort((a, b) => {
                            return moment(a[0], "MMM-YYYY").toDate() - moment(b[0], "MMM-YYYY").toDate();
                        });
                        i.subset = i.subset.filter((k) => {
                            return k[0].split("-")[1] === j;
                        });
                        i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
                        i.incomplete = false;
                        return i;
                    });

                    final.push(dataset);
                });
            }
            if (scope3_array.length !== 0) {
                let scope3_tier2 = [],
                    final = [];
                Object.keys(scope3_array).forEach((key) => {
                    scope3_array[key].forEach((item) => {
                        item.dcfId = item.dcf;
                        item.dcf =
                            dcflist.filter((l) => {
                                return l.id === item.dcf;
                            }).length === 0
                                ? item.dcf
                                : dcflist.filter((l) => {
                                    return l.id === item.dcf;
                                })[0].title;
                        if (
                            scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope3_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: "red",
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope3_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope3_tier2[ind].y = scope3_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope3_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope3_tier2[ind].subset[subind][1] = scope3_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    });
                });
                betweenYears.forEach((j) => {
                    let dataset = JSON.parse(JSON.stringify(scope3_tier2)).map((i) => {
                        i.subset.sort((a, b) => {
                            return moment(a[0], "MMM-YYYY").toDate() - moment(b[0], "MMM-YYYY").toDate();
                        });
                        i.subset = i.subset.filter((k) => {
                            return k[0].split("-")[1] === j;
                        });
                        i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
                        i.incomplete = false;
                        return i;
                    });

                    final.push(dataset);
                });
            }
        }

        return monthly_fg;
    };
    const renderDataEF = (search) => {
        let betweenMonths = [],
            betweenYears = [];
        let monthly_fg = [];

        let endDate = moment.utc(search.to).local();
        let startDate = moment.utc(search.from).local();
        let year = moment(moment.utc()).format("YYYY");

        if (search.to !== null && search.from !== null) {
            while (startDate.startOf("month") <= endDate.startOf("month")) {
                betweenMonths.push(startDate.format("MM-YYYY"));
                !betweenYears.includes(startDate.format("YYYY")) && betweenYears.push(startDate.format("YYYY"));

                startDate.add(1, "month");
            }

            let res = JSON.parse(JSON.stringify(response));

            res.forEach((report, rind) => {
                let sc_total = 0,
                    fg_total = 0;

                if (checkSite(report.site, search.location)) {
                    report.sitename = locationList.filter((loc) => {
                        return loc.id === report.site;
                    })[0].name;
                    if (report.dcf === 16 && (search.indicator.id === 0 || search.indicator.id === 122)) {
                        report.response.forEach((fg, ind) => {
                            let date = fg.filter((i) => {
                                return i.dpId === "DPA0285";
                            })[0].value;
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            if (checkReportingPeriod([moment(date).format("MM-YYYY")], betweenMonths).result) {
                                let item_class = fg.filter((i) => {
                                    return i.dpId === "DPA0287";
                                })[0].value.name;
                                let item_qty = fg.filter((i) => {
                                    return i.dpId === "DPA0288";
                                })[0].value;
                                let price_per_item = fg.filter((i) => {
                                    return i.dpId === "DPA0289";
                                })[0].value;

                                let total =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.item_classification === item_class;
                                    })[0]["co2e_in_kg"] /
                                        1000) *
                                    item_qty *
                                    price_per_item;

                                fg_total = total + fg_total;

                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.item_class === item_class;
                                });

                                if (updateind === -1) {
                                    monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, item_class: item_class });
                                } else {
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                }
                            }
                        });
                    }
                    if (report.dcf === 36 && (search.indicator.id === 0 || search.indicator.id === 123)) {
                        report.response.forEach((fg, ind) => {
                            let date = fg.filter((i) => {
                                return i.dpId === "DPA0290";
                            })[0].value;
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            if (checkReportingPeriod([moment(date).format("MM-YYYY")], betweenMonths).result) {
                                let total = 0;

                                let travel_mode = fg.filter((i) => {
                                    return i.dpId === "DPA0291";
                                })[0].value.name;
                                let passenger = fg.filter((i) => {
                                    return i.dpId === "DPA0292";
                                })[0].value;

                                if (travel_mode.toLowerCase() === "air") {
                                    total =
                                        fg.filter((i) => {
                                            return i.dpId === "DP_co2e_mt";
                                        })[0].value * passenger;

                                    let updateind = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode;
                                    });

                                    if (updateind === -1) {
                                        monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode });
                                    } else {
                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                    }

                                    fg_total = total + fg_total;
                                } else if (travel_mode.toLowerCase() === "road") {
                                    let veh_cat = fg.filter((i) => {
                                        return i.dpId === "DPA0295";
                                    })[0].value.name;
                                    let veh_type = fg.filter((i) => {
                                        return i.dpId === "DPA0337";
                                    })[0].value;
                                    let fuel = fg.filter((i) => {
                                        return i.dpId === "DPA0338";
                                    })[0].value;
                                    let km = fg.filter((i) => {
                                        return i.dpId === "DP_KM";
                                    })[0].value;
                                    if (veh_cat.includes("Cars") || veh_cat.includes("Motor")) {
                                        if (veh_cat.includes("Cars")) {
                                            total =
                                                (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => {
                                                    return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.fuel_type === fuel.name && k.unit === "km";
                                                })[0]["co2e_in_kg"] /
                                                    1000) *
                                                km *
                                                passenger;
                                        } else {
                                            total =
                                                (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => {
                                                    return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.unit === "km";
                                                })[0]["co2e_in_kg"] /
                                                    1000) *
                                                km *
                                                passenger;
                                        }
                                    } else {
                                        total =
                                            (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => {
                                                return k.vehicle_category === veh_cat && k.unit === "passenger.km";
                                            })[0]["co2e_in_kg"] /
                                                1000) *
                                            km *
                                            passenger;
                                    }

                                    let updateind = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode;
                                    });

                                    if (updateind === -1) {
                                        monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode });
                                    } else {
                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                    }

                                    fg_total = total + fg_total;
                                } else if (travel_mode.toLowerCase() === "rail") {
                                    total =
                                        fg.filter((i) => {
                                            return i.dpId === "DP_KM";
                                        })[0].value *
                                        passenger *
                                        0.00116;

                                    let updateind = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode;
                                    });

                                    if (updateind === -1) {
                                        monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode });
                                    } else {
                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                    }

                                    fg_total = total + fg_total;
                                }
                            }
                        });
                    }

                    if (report.dcf === 11 && (search.indicator.id === 0 || search.indicator.id === 93)) {
                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths);

                        let total = 0,
                            co2 = 0,
                            ch4 = 0,
                            n2o = 0,
                            sc_data = [];
                        report.response.forEach((fg, ind) => {
                            let fuel_cat = fg.filter((i) => {
                                return i.dpId === "DPA0130";
                            })[0].value.name;
                            let fuel_type = fg.filter((i) => {
                                return i.dpId === "DPA0131";
                            })[0].value.name;
                            let unit = fg.filter((i) => {
                                return i.dpId === "DPA0132";
                            })[0].value.name;
                            let consumed = fg.filter((i) => {
                                return i.dpId === "DPA0336";
                            })[0].value;
                            let fuel_cat_ind = sc_data.findIndex((k) => {
                                return k.fuel_type === fuel_type && k.unit === unit;
                            });

                            if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                                let carbon = fg.filter((i) => {
                                    return i.dpId === "DPA0134";
                                })[0].value;
                                let cv = fg.filter((i) => {
                                    return i.dpId === "DPA0133";
                                })[0].value;
                                if (carbon > 0 && cv > 0) {
                                    let gj = (carbon * 3.664 * 1000) / cv;
                                    total = (gj * 0.000004184 * consumed) / 1000;
                                    fg["value"] = ["Emission Factor-", 3.664, " ,EnergyProduced-", 0.000004184];
                                    co2 = 0;
                                    ch4 = 0;
                                    n2o = 0;
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_type: fuel_type, ghg: total, co2, n2o, ch4, unit, co2_: 0, n2o_: 0, ch4_: 0 });
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total;
                                        sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                                        sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                                        sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                                    }
                                } else {
                                    total =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["co2e_in_kg"] /
                                            1000) *
                                        consumed;
                                    co2 =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["co2_in_kg"] /
                                            1000) *
                                        consumed;
                                    ch4 =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["ch4_in_kg"] /
                                            1000) *
                                        consumed;
                                    n2o =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["n2o_in_kg"] /
                                            1000) *
                                        consumed;
                                    let co2_ = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["co2_in_kg"];
                                    let ch4_ = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["ch4_in_kg"];
                                    let n2o_ = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["n2o_in_kg"];
                                    fg["value"] = ["co2-", co2 / consumed, " ,ch4-", ch4 / consumed, " ,n2o-", n2o / consumed];
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_type: fuel_type, ghg: total, unit, co2, n2o, ch4, co2_, n2o_, ch4_ });
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total;
                                        sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                                        sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                                        sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                                    }
                                }
                            } else {
                                total =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["co2e_in_kg"] /
                                        1000) *
                                    consumed;
                                co2 =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["co2_in_kg"] /
                                        1000) *
                                    consumed;
                                ch4 =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["ch4_in_kg"] /
                                        1000) *
                                    consumed;
                                n2o =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["n2o_in_kg"] /
                                        1000) *
                                    consumed;
                                let co2_ = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                    return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                })[0]["co2_in_kg"];
                                let ch4_ = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                    return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                })[0]["ch4_in_kg"];
                                let n2o_ = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                    return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                })[0]["n2o_in_kg"];

                                fg["value"] = ["co2-", co2 / consumed, " ,ch4-", ch4 / consumed, " ,n2o-", n2o / consumed];
                                if (fuel_cat_ind === -1) {
                                    sc_data.push({ fuel_type: fuel_type, ghg: total, co2, n2o, ch4, unit, co2_, n2o_, ch4_ });
                                } else {
                                    sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total;
                                    sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                                    sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                                    sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                                }
                            }
                        });
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, "MM-YYYY").toDate();
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            sc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.fuel_type === item.fuel_type && i.unit === item.unit;
                                });

                                if (updateind === -1) {
                                    monthly_fg.push({
                                        scope: 1,
                                        year: moment(date).format("YYYY"),
                                        month: moment(date).format("MMM-YYYY"),
                                        ghg: item.ghg / addedMonth.count,
                                        dcf: report.dcf,
                                        site: report.site,
                                        fuel_type: item.fuel_type,
                                        unit: item.unit,
                                        co2: (item.co2 * 1000) / addedMonth.count,
                                        n2o: (item.n20 * 1000) / addedMonth.count,
                                        ch4: (item.ch4 * 1000) / addedMonth.count,
                                        co2_: item.co2_,
                                        ch4_: item.ch4_,
                                        n2o_: item.n2o_,
                                    });
                                } else {
                                    monthly_fg[updateind].co2 = ((item.co2 * 1000) / addedMonth.count) * 1000 + monthly_fg[updateind].co2;
                                    monthly_fg[updateind].n2o = ((item.n2o * 1000) / addedMonth.count) * 1000 + monthly_fg[updateind].n2o;
                                    monthly_fg[updateind].ch4 = ((item.ch4 * 1000) / addedMonth.count) * 1000 + monthly_fg[updateind].ch4;
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + item.ghg / addedMonth.count;
                                }
                            });
                        });
                    }
                    if (report.dcf === 10 && (search.indicator.id === 0 || search.indicator.id === 116)) {
                        let add = [];

                        report.response.forEach((fg, ind) => {
                            let date = fg.filter((i) => {
                                return i.dpId === "DPA0137";
                            })[0].value;
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            if (checkReportingPeriod([moment(date).format("MM-YYYY")], betweenMonths).result) {
                                let gastype = fg.filter((i) => {
                                    return i.dpId === "DPA0136";
                                })[0].value.name;
                                let gasrefilled = fg.filter((i) => {
                                    return i.dpId === "DPA0138";
                                })[0].value;
                                let total =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => {
                                        return k.gas_type === gastype;
                                    })[0]["co2e_in_kg"] /
                                        1000) *
                                    gasrefilled;
                                let co2e_ = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => {
                                    return k.gas_type === gastype;
                                })[0]["co2e_in_kg"];
                                fg_total = total + fg_total;

                                fg["gas"] = gastype;
                                fg["gasfilled"] = gasrefilled;
                                fg["value"] = ["EmissionFactor-", total / gasrefilled];
                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.gastype === gastype;
                                });

                                if (updateind === -1) {
                                    monthly_fg.push({ scope: 1, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: 0, dcf: report.dcf, site: report.site, gastype: gastype, ghg: total, co2e_ });
                                } else {
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                }
                            }
                        });
                    }
                    if (report.dcf === 72 && (search.indicator.id === 0 || search.indicator.id === 121)) {
                        report.rp.forEach((i) => {
                            if (checkReportingPeriod([i], betweenMonths).result) {
                                let yearind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY");
                                });
                                let monthind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY") && i.month === moment(i, "MM-YYYY").format("MMM-YYYY");
                                });
                                let dcfind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY") && i.month === moment(i, "MM-YYYY").format("MMM-YYYY") && i.dcf === report.dcf;
                                });
                                let siteind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY") && i.month === moment(i, "MM-YYYY").format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                                });

                                let ef = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[1].stdTopics[0].data1[0].importedData[0]["kwh_in_tco2e"];

                                let renewable =
                                    report.response[0].filter((i) => {
                                        return i.dpId === "DPA0156";
                                    })[0].value / report.rp.length;
                                let nonrenewable =
                                    report.response[0].filter((i) => {
                                        return i.dpId === "DPA0157";
                                    })[0].value / report.rp.length;

                                if (yearind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, co2e_: ef, dcf: report.dcf, site: report.site, renewable, nonrenewable });
                                } else if (monthind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, co2e_: ef, dcf: report.dcf, site: report.site, renewable, nonrenewable });
                                } else if (dcfind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, co2e_: ef, dcf: report.dcf, site: report.site, renewable, nonrenewable });
                                } else if (siteind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, dcf: report.dcf, co2e_: ef, site: report.site, renewable, nonrenewable });
                                }
                            }
                        });
                    }
                    if (report.dcf === 15 && (search.indicator.id === 0 || search.indicator.id === 118)) {
                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths);

                        let ef_by_fuel = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[4].data1[0].importedData;
                        let ef_by_distance = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[1].data1[0].importedData;

                        let total = 0,
                            co2 = 0,
                            ch4 = 0,
                            n2o = 0,
                            mc_data = [];

                        report.response.forEach((fg, ind) => {
                            let mode = fg.filter((i) => {
                                return i.dpId === "DP_MODE";
                            })[0].value
                                ? "by distance"
                                : "by fuel";
                            let fuel_cat = fg.filter((i) => {
                                return i.dpId === "DPA0140";
                            })[0].value;
                            let fuel_type = fg.filter((i) => {
                                return i.dpId === "DPA0139";
                            })[0].value;
                            let fuel = fg.filter((i) => {
                                return i.dpId === "DPA0141";
                            })[0].value.name;
                            let unit = fg.filter((i) => {
                                return i.dpId === "DPA0339";
                            })[0].value;
                            let km = fg.filter((i) => {
                                return i.dpId === "DPA0144";
                            })[0].value;
                            let fuel_filled = fg.filter((i) => {
                                return i.dpId === "DPA0143";
                            })[0].value;
                            let fuel_cat_ind = mc_data.findIndex((k) => {
                                return k.fuel === fuel;
                            });

                            if (mode === "by distance") {
                                total = ef_by_distance.filter((k) => {
                                    return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name;
                                })[0]["co2e_in_kg"];

                                if (fuel_cat_ind === -1) {
                                    mc_data.push({ mode: fuel, ghg: total * km, co2e_: total, fuel_cat: fuel_cat.name });
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total;
                                }
                            } else {
                                total = ef_by_fuel.filter((k) => {
                                    return k.fuel.trim() === fuel.trim();
                                })[0]["co2e_in_kg"];

                                if (fuel_cat_ind === -1) {
                                    mc_data.push({ mode: fuel, ghg: total * fuel_filled, co2e_: total, fuel_cat: "" });
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total;
                                }
                            }
                        });
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, "MM-YYYY").toDate();

                            mc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode;
                                });

                                if (updateind === -1) {
                                    let updateind2 = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode;
                                    });
                                    if (updateind2 !== -1) {
                                        monthly_fg[updateind2].ghg = item.ghg / addedMonth.count + monthly_fg[updateind2].ghg;
                                        // monthly_fg[updateind2]['fuel_cat'] = item.fuel_cat
                                    } else {
                                        console.log("EF", item);
                                        monthly_fg.push({ scope: 1, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: 0, dcf: report.dcf, site: report.site, ghg: item.ghg / addedMonth.count, mode: item.mode, fuel_cat: item.fuel_cat, co2e_: item.co2e_ });
                                    }
                                } else {
                                    // monthly_fg[updateind]['fuel_cat'] = item.fuel_cat
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + item.ghg / addedMonth.count;
                                }
                            });
                        });
                    }
                }
            });

            let scope12_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 1 || i.scope === 2;
                }),
                "month"
            );
            let scope3_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 3;
                }),
                "month"
            );
            let scope1_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 1;
                }),
                "month"
            );
            let scope2_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 2;
                }),
                "month"
            );
            let pie = [];
            monthly_fg
                .filter((i) => {
                    return i.scope === 1;
                })
                .forEach((item) => {
                    let index = pie.findIndex((j) => {
                        return j.name === "Scope " + item.scope;
                    });
                    if (index === -1) {
                        pie.push({ name: "Scope " + item.scope, y: item.ghg });
                    } else {
                        pie[index].y = pie[index].y + item.ghg;
                    }
                });
            monthly_fg
                .filter((i) => {
                    return i.scope === 2;
                })
                .forEach((item) => {
                    let index = pie.findIndex((j) => {
                        return j.name === "Scope " + item.scope;
                    });
                    if (index === -1) {
                        pie.push({ name: "Scope " + item.scope, y: item.ghg });
                    } else {
                        pie[index].y = pie[index].y + item.ghg;
                    }
                });
            monthly_fg
                .filter((i) => {
                    return i.scope === 3;
                })
                .forEach((item) => {
                    let index = pie.findIndex((j) => {
                        return j.name === "Scope " + item.scope;
                    });
                    if (index === -1) {
                        pie.push({ name: "Scope " + item.scope, y: item.ghg });
                    } else {
                        pie[index].y = pie[index].y + item.ghg;
                    }
                });

            let index1 = pie.findIndex((j) => {
                return j.name === "Scope 1";
            });
            let index2 = pie.findIndex((j) => {
                return j.name === "Scope 2";
            });
            let index3 = pie.findIndex((j) => {
                return j.name === "Scope 3";
            });

            if (index1 === -1) {
                pie.push({ name: "Scope 1", y: 0 });
            }
            if (index2 === -1) {
                pie.push({ name: "Scope 2", y: 0 });
            }
            if (index3 === -1) {
                pie.push({ name: "Scope 3", y: 0 });
            }

            if (scope1_array.length !== 0) {
                let scope1_tier2 = [],
                    final = [];
                Object.keys(scope1_array).forEach((key) => {
                    scope1_array[key].forEach((item) => {
                        item.dcfId = item.dcf;
                        item.dcf =
                            dcflist.filter((l) => {
                                return l.id === item.dcf;
                            }).length === 0
                                ? item.dcf
                                : dcflist.filter((l) => {
                                    return l.id === item.dcf;
                                })[0].title;

                        if (
                            scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope1_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: "red",
                                y: item.ghg,

                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope1_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope1_tier2[ind].y = scope1_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope1_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope1_tier2[ind].subset[subind][1] = scope1_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    });
                });

                betweenYears.forEach((j) => {
                    let dataset = JSON.parse(JSON.stringify(scope1_tier2)).map((i) => {
                        i.subset.sort((a, b) => {
                            return moment(a[0], "MMM-YYYY").toDate() - moment(b[0], "MMM-YYYY").toDate();
                        });
                        i.subset = i.subset.filter((k) => {
                            return k[0].split("-")[1] === j;
                        });
                        i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
                        i.incomplete = false;
                        return i;
                    });

                    final.push(dataset);
                });
            }
            if (scope2_array.length !== 0) {
                let scope2_tier2 = [],
                    final = [];
                Object.keys(scope2_array).forEach((key) => {
                    scope2_array[key].forEach((item) => {
                        item.dcfId = item.dcf;
                        item.dcf =
                            dcflist.filter((l) => {
                                return l.id === item.dcf;
                            }).length === 0
                                ? item.dcf
                                : dcflist.filter((l) => {
                                    return l.id === item.dcf;
                                })[0].title;
                        if (
                            scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope2_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: "red",
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope2_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope2_tier2[ind].y = scope2_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope2_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope2_tier2[ind].subset[subind][1] = scope2_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    });
                });
                betweenYears.forEach((j) => {
                    let dataset = JSON.parse(JSON.stringify(scope2_tier2)).map((i) => {
                        i.subset.sort((a, b) => {
                            return moment(a[0], "MMM-YYYY").toDate() - moment(b[0], "MMM-YYYY").toDate();
                        });
                        i.subset = i.subset.filter((k) => {
                            return k[0].split("-")[1] === j;
                        });
                        i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
                        i.incomplete = false;
                        return i;
                    });

                    final.push(dataset);
                });
            }
            if (scope3_array.length !== 0) {
                let scope3_tier2 = [],
                    final = [];
                Object.keys(scope3_array).forEach((key) => {
                    scope3_array[key].forEach((item) => {
                        item.dcfId = item.dcf;
                        item.dcf =
                            dcflist.filter((l) => {
                                return l.id === item.dcf;
                            }).length === 0
                                ? item.dcf
                                : dcflist.filter((l) => {
                                    return l.id === item.dcf;
                                })[0].title;
                        if (
                            scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope3_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: "red",
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope3_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope3_tier2[ind].y = scope3_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope3_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope3_tier2[ind].subset[subind][1] = scope3_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    });
                });
                betweenYears.forEach((j) => {
                    let dataset = JSON.parse(JSON.stringify(scope3_tier2)).map((i) => {
                        i.subset.sort((a, b) => {
                            return moment(a[0], "MMM-YYYY").toDate() - moment(b[0], "MMM-YYYY").toDate();
                        });
                        i.subset = i.subset.filter((k) => {
                            return k[0].split("-")[1] === j;
                        });
                        i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
                        i.incomplete = false;
                        return i;
                    });

                    final.push(dataset);
                });
            }
        }

        return monthly_fg;
    };
    const showSite = () => {
        let site_ids = [],
            site_names = [];
        dcfass.forEach((i) => {
            !site_ids.includes(i.site) && site_ids.push(i.site);
        });

        site_ids.forEach((i, j) => {
            site_names.push(
                locationList.find((k) => {
                    return k.id === i;
                }).title
            );
        });

        return site_names;
    };
    return (
        <div>
            <div className="col-12" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div className="col-3 p-card" style={{ margin: 5, height: "calc(100vh - 9rem)", overflow: "scroll" }}>
                    <nav>
                        {headings.map((heading, ind) => {
                            let indexes = [];
                            return (
                                <>
                                    {heading.level === 0 ? (
                                        <label key={heading.id} style={{ display: "flex", margin: 5, fontWeight: activeId === heading.id ? "bold" : "normal", textDecoration: heading.text.includes("SECTION") && "underline" }} className={getClassName(heading.level)}>
                                            <a
                                                href={`#${heading.id}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    document.querySelector(`#${heading.id}`).scrollIntoView({
                                                        behavior: "smooth",
                                                        block: "start",
                                                        inline: "nearest",
                                                    });
                                                }}
                                                style={{
                                                    fontWeight: activeId === heading.id ? "bold" : "normal",
                                                }}
                                            >
                                                {heading.text}
                                            </a>
                                        </label>
                                    ) : (
                                        <ul>
                                            {heading.item.map((item, ind2) => {
                                                return (
                                                    <li key={item.id} className={getClassName(item.level)}>
                                                        <a
                                                            href={`#${item.id}`}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                document.querySelector(`#${item.id}`).scrollIntoView({
                                                                    behavior: "smooth",
                                                                });
                                                            }}
                                                            style={{
                                                                fontWeight: activeId === item.id ? "bold" : "normal",
                                                            }}
                                                        >
                                                            {item.text}
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    )}
                                </>
                            );
                        })}
                    </nav>
                </div>
                <div className="col-9 p-card" style={{ margin: 5, height: "calc(100vh - 9rem)", overflow: "scroll", color: "white" }}>
                    <div className="col-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button icon='pi pi-eye' style={{ marginRight: 10 }} rounded text raised aria-label="Filter" onClick={() => { exportTable2Excel(1) }}> </Button>

                        <Button icon='pi pi-cloud-download' rounded text raised aria-label="Filter" onClick={() => { exportTable2Excel(0) }}> </Button>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {/* <img id='ass' src='https://mpower-s3.s3-ap-southeast-1.amazonaws.com/uploads/hazards/1690426362525Pushing%20and%20Pulling.png' width={'100%'} /> */}
                        <div>
                            <div className="col-12 grid" style={{ margin: 5, color: "white" }}>
                                <div>
                                    <label
                                        style={{
                                            color: "black",
                                            padding: 15,
                                            justifyContent: "flex-start",
                                            display: "flex",
                                        }}
                                    >
                                        Select Year :
                                    </label>
                                </div>
                                <div className="col-4">
                                    <Dropdown options={[{ name: 2022 }, { name: 2023 }]} value={year} optionLabel="name" optionValue="name" onChange={(e) => { setYear(e.value) }} />
                                </div>

                            </div>
                            {/* <input type="file" onChange={handleFileChange} /> */}
                            {/* {tableData.length > 0 && (
                                <div className="gridlines-container">
                                    <table className="gridlines">
                                        <thead>
                                            <tr>
                                                {Object.keys(tableData[0]).map((field, index) => (
                                                    <th key={index} colSpan={getCellColSpan(0, index)}>
                                                        {field}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    {Object.keys(row).map((cellRef, colIndex) => {
                                                        const cellValue = row[cellRef];
                                                        const colSpan = getCellColSpan(rowIndex + 1, colIndex); // Increment rowIndex to exclude header
                                                        const rowSpan = getCellRowSpan(rowIndex + 1, colIndex); // Increment rowIndex to exclude header
                                                        return (
                                                            <td key={colIndex} colSpan={colSpan} rowSpan={rowSpan} className={cellValue === "" && workbook.Sheets[firstSheet][cellRef]?.s?.b ? "empty-cell" : ""}>
                                                                {cellValue}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )} */}
                        </div>
                        <div id="main" className="sgx" style={{ flexDirection: "column", display: "flex" }}>
                            <sectionheader id={"ed"} className="secHead">
                                Environmental Data
                            </sectionheader>

                            <sectionheader1 id={"ghg"}>GhG Emissions</sectionheader1>
                            <div className="m-3 para">Absolute emissions by: (a) Total, if appropriate</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Reporting Year
                                            </td>
                                            <td colSpan="2" className="tdHead">
                                                Greenhouse Gas Emissions in CO2 equivalents
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="1"></td>
                                            <td colSpan="2" className="tdHead">
                                                Scope 1: Direct Emissions
                                            </td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="1" className="tdHead">
                                                Emission Type
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Emissions
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Emission Quantity
                                            </td>
                                        </tr>
                                        {renderEmissionScope1()}
                                        <tr colSpan="2">
                                            <td colSpan="1"></td>
                                            <td colSpan="2" className="tdHead">
                                                Scope 2: Indirect Emissions
                                            </td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="1" className="tdHead">
                                                Emission Type
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Emissions
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Emission Quantity
                                            </td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="1" className="text-center">
                                                Energy Purchased from Third party sources
                                            </td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1">
                                                Subtotal Scope 2 Emissions
                                            </td>
                                            <td colspan="1" rowspan="1"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="1"></td>
                                            <td colSpan="2" className="tdHead">
                                                Scope 3: Indirect Emissions
                                            </td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="1" className="tdHead">
                                                Emission Type
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Emissions
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Emission Quantity
                                            </td>
                                        </tr>
                                        {renderEmissionScope3()}
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">Absolute emissions by: (b) Scope 1, Scope 2, if appropriate</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="2">
                                            <td colSpan="2" className="tdHead">
                                                Greenhouse Gas Emissions in CO2 equivalents
                                            </td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="2" className="tdHead">
                                                Scope 1: Direct Emissions
                                            </td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="1" className="tdHead">
                                                Emission Type
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Emissions
                                            </td>
                                        </tr>
                                        {renderEmissionScope1_()}
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="2">
                                            <td colSpan="2" className="tdHead">
                                                Greenhouse Gas Emissions in CO2 equivalents
                                            </td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="2" className="tdHead">
                                                Scope 2: Indirect Emissions
                                            </td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="1" className="tdHead">
                                                Emission Type
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Emissions
                                            </td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="1" className="text-center">
                                                Energy Purchased from Third party sources
                                            </td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="m-3 para">Absolute emissions by: (c) Scope 3, if appropriate</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="2">
                                            <td colSpan="2" className="tdHead">
                                                Greenhouse Gas Emissions in CO2 equivalents
                                            </td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="2" className="tdHead">
                                                Scope 3: Indirect Emissions
                                            </td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="1" className="tdHead">
                                                Emission Type
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Emissions
                                            </td>
                                        </tr>
                                        {renderEmissionScope3_()}
                                    </tbody>
                                </table>
                            </div>
                            <div className="m-3 para">Emission intensities by: (a) Total, if appropriate</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="2">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Total Emission Intensity (in tCO2e/organisation specific metrics)
                                            </td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="2" className="tdHead">
                                                Scope 1: Direct Emissions
                                            </td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="1" className=""></td>
                                            <td colSpan="1" className=""></td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="2" className="tdHead">
                                                Scope 2: Indirect Emissions
                                            </td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="1" className=""></td>
                                            <td colSpan="1" className=""></td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="2" className="tdHead">
                                                Scope 3: Other Indirect Emissions
                                            </td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="1" className=""></td>
                                            <td colSpan="1" className=""></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="m-3 para">Emission intensities by: (b) Scope 1, Scope 2, if appropriate</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="2">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Emission Type
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Emissions
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Total Emission Intensity (in tCO2e/organisation specific metrics)
                                            </td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="4" className="tdHead">
                                                Scope 1: Direct Emissions
                                            </td>
                                        </tr>
                                        {renderEmissionScope1_1()}
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="2">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Emission Type
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Emissions
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Total Emission Intensity (in tCO2e/organisation specific metrics)
                                            </td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="4" className="tdHead">
                                                Scope 2: Indirect Emissions
                                            </td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="1" className="text-center">
                                                Energy Purchased from Third party sources
                                            </td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="m-3 para">Emission intensities by: (c) Scope 3, if appropriate</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="2">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Emission Type
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Emissions
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Total Emission Intensity (in tCO2e/organisation specific metrics)
                                            </td>
                                        </tr>
                                        <tr colSpan="2">
                                            <td colSpan="4" className="tdHead">
                                                Scope 3: Other Indirect Emissions
                                            </td>
                                        </tr>

                                        {renderEmissionScope3_1()}
                                    </tbody>
                                </table>
                            </div>
                            <sectionheader1 id={"ei"}>Energy Intensity</sectionheader1>
                            <div className="m-3 fb5">Total Energy Consumption</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="3" className="tdHead">
                                                Total Energy Consumption
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Energy Source
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Type of Energy
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Energy Consumption in MWhS or GJ
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="m-3 fb5">Energy Consumption Intensity</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="3" className="tdHead">
                                                Energy Intensity
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Total energy Consumed in MWhS or GJ
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Scaling Factor (unit as per selection)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Intensity (MWhs or GJ/organisation specific metrics)
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <sectionheader1 id={"wc"}>Water Consumption</sectionheader1>
                            <div className="m-3 fb5">Total Water Consumption</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="3" className="tdHead">
                                                Water Consumption (in cubic meters or ML)
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Water Source
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Region or Location
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Water Consumption (in cubic meters or ML)
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center">
                                                Surface Water
                                            </td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center">
                                                Groundwater
                                            </td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center">
                                                Rainwater Harvesting
                                            </td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center">
                                                Other Sources
                                            </td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center">
                                                Total Water Consumption
                                            </td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="m-3 fb5">Water Consumption Intensity</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="2">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Water Consumption (in cubic meters or ML)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Organisation specific metric (e.g., Revenue, Units Produced)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Water Consumption Intensity (ML or m³/organisation specific metric)
                                            </td>
                                        </tr>

                                        <tr colSpan="2">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <sectionheader1 id={"wg"}>Water Generation</sectionheader1>
                            <div className="m-3 fb5">Total Waste Generated</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="3" className="tdHead">
                                                Total Waste Generated
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Type of Waste (e.g. hazardous vs non-hazardous, recycled vs non-recycled)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Total Waste Generated (in metric tons (t))
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center">
                                                Total Waste Generated
                                            </td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <sectionheader id={"sd"} className="secHead">
                                Social Data
                            </sectionheader>
                            <sectionheader1 id={"gdi"}>Gender Diversity</sectionheader1>
                            <div className="m-3 fb5">Current employees by gender</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Male Employees (%)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Female Employees (%)
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center">{year}</td>
                                            <td colSpan="1" className="text-center"> {handleNaNAndInfinity((getDataByDPArray(['DPK0032', 'DPK0033', 'DPK0034'], year) / getDataByDPArray(['DPK0028', 'DPK0029', 'DPK0030', ' DPK0032', 'DPK0033', 'DPK0034'], year)) * 100)} </td>
                                            <td colSpan="1" className="text-center"> {handleNaNAndInfinity((getDataByDPArray(['DPK0028', 'DPK0029', 'DPK0030'], year) / getDataByDPArray(['DPK0028', 'DPK0029', 'DPK0030', ' DPK0032', 'DPK0033', 'DPK0034'], year)) * 100)} </td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div className="m-3 fb5">New hires and turnover by gender</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                New Male Employee Hires (%)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                New Female Employee Hires (%)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Total New Employee Hires (%)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Male Turnover
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Female Turnover
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Total Turnover
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center">{year}</td>
                                            <td colSpan="1" className="text-center"> {handleNaNAndInfinity((getDataByDPArray(['DDPK0011', 'DPK0014', 'DPK0017'], year) / getDataByDPArray(['DPK0011', 'DPK0014', 'DPK0017', ' DPK0012', 'DPK0015', 'DPK0018'], year)) * 100)} </td>

                                            <td colSpan="1" className="text-center"> {handleNaNAndInfinity((getDataByDPArray(['DDPK0012', 'DPK0015', 'DPK0018'], year) / getDataByDPArray(['DPK0011', 'DPK0014', 'DPK0017', ' DPK0012', 'DPK0015', 'DPK0018'], year)) * 100)} </td>

                                            <td colSpan="1" className="text-center"> {handleNaNAndInfinity((getDataByDPArray(['DDPK0011', 'DPK0014', 'DPK0017', 'DDPK0012', 'DPK0015', 'DPK0018'], year) / getDataByDPArray(['DPK0028', 'DPK0029', 'DPK0030', ' DPK0032', 'DPK0033', 'DPK0034'], year)) * 100)} </td>
                                            <td colSpan="1" className="text-center"> {handleNaNAndInfinity(getDataByDPArray(['DPK0284', 'DPK0288', 'DPK0292']))} </td>
                                            <td colSpan="1" className="text-center"> {handleNaNAndInfinity(getDataByDPArray(['DPK0286', 'DPK0290', 'DPK0294']))} </td>
                                            <td colSpan="1" className="text-center"> {handleNaNAndInfinity(getDataByDPArray(['DPK0284', 'DPK0288', 'DPK0292', 'DPK0286', 'DPK0290', 'DPK0294']))} </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <sectionheader1 id={"abd"}>Age-Based Diversity</sectionheader1>
                            <div className="m-3 fb5">Current employees by age groups</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Employees under 30 years old (%)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Employees between 30-50 years old (%)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Employees over 50 years old (%)
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center">{year} </td>
                                            <td colSpan="1" className="text-center"> {handleNaNAndInfinity((getDataByDPArray([ 'DPK0028', 'DPK0032', 'DPK0036'], year) / getDataByDPArray([  'DPK0028', 'DPK0029',
  'DPK0030', 'DPK0032',
  'DPK0033', 'DPK0034',
  'DPK0036', 'DPK0037',
  'DPK0038'], year)) * 100)} </td>

<td colSpan="1" className="text-center"> {handleNaNAndInfinity((getDataByDPArray([ 'DPK0029', 'DPK0033', 'DPK0037'], year) / getDataByDPArray([  'DPK0028', 'DPK0029',
  'DPK0030', 'DPK0032',
  'DPK0033', 'DPK0034',
  'DPK0036', 'DPK0037',
  'DPK0038'], year)) * 100)} </td>
                                    <td colSpan="1" className="text-center"> {handleNaNAndInfinity((getDataByDPArray([ 'DPK0030', 'DPK0034', 'DPK0038'], year) / getDataByDPArray([  'DPK0028', 'DPK0029',
  'DPK0030', 'DPK0032',
  'DPK0033', 'DPK0034',
  'DPK0036', 'DPK0037',
  'DPK0038'], year)) * 100)} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="m-3 fb5">New hires and turnover by age groups</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                New Employee Hires under 30 years old (%)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                New Employee Hires between 30-50 years old (%)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                New Employee Hires over 50 years old (%)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Turnover of New Employee Hires under 30 years old (%)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Turnover of New Employee Hires between 30-50 years old (%)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Turnover of New Employee Hires over 50 years old (%)
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                        <td colSpan="1" className="text-center">{year} </td>
                                            <td colSpan="1" className="text-center"> {handleNaNAndInfinity((getDataByDPArray([ 'DPK0011', 'DPK0012'], year) / getDataByDPArray([ 'DPK0011', 'DPK0012', 'DPK0014', 'DPK0015', 'DPK0017', 'DPK0018'], year)) * 100)} </td>
                                            <td colSpan="1" className="text-center"> {handleNaNAndInfinity((getDataByDPArray([ 'DPK0014', 'DPK0015'], year) / getDataByDPArray([ 'DPK0011', 'DPK0012', 'DPK0014', 'DPK0015', 'DPK0017', 'DPK0018'], year)) * 100)} </td>
                                            <td colSpan="1" className="text-center"> {handleNaNAndInfinity((getDataByDPArray([ 'DPK0017', 'DPK0018'], year) / getDataByDPArray([ 'DPK0011', 'DPK0012', 'DPK0014', 'DPK0015', 'DPK0017', 'DPK0018'], year)) * 100)} </td>

                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <sectionheader1 id={"em"}>Employment</sectionheader1>
                            <div className="m-3 fb5">New hires and turnover by age groups</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Total Employees at the beginning of the Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Employees who left during the Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Total Turnover (Number)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Turnover Percentage (%)
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="m-3 fb5">Total number of employees</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Total Employees at the end of the Year
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <sectionheader1 id={"dat"}>Development and Training</sectionheader1>
                            <div className="m-3 fb5">Average training hours per employee</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Total Training Hours Provided
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Total Number of Employees
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Average Training Hours per Employee
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="m-3 fb5">Average training hours per employee by gender</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Total Training Hours Provided for Male
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Total Training Hours Provided for Female
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Total Number Male of Employees
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Total Number Female of Employees
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Average Training Hours per Male Employee
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Average Training Hours per Female Employee
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <sectionheader1 id={"ohs"}>Occupational Health and Safety</sectionheader1>
                            <div className="m-3 fb5">Fatalities</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="2" className="tdHead">
                                                Number of fatalities as a result of work-related injury - Employees
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Number of Cases
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="2" className="tdHead">
                                                Number of fatalities as a result of work-related injury - Workers who are not Employees
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Number of Cases
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="m-3 fb5">High- Consequence Injuries</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="2" className="tdHead">
                                                Number of high- consequence injuries - Employees
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Number of Cases
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="2" className="tdHead">
                                                Number of high-consequence injuries - Workers who are not Employees
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Number of Cases
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="m-3 fb5">Recordable Injuries</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="2" className="tdHead">
                                                Number of recordable work-related injuries - Employees
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Number of Cases
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="2" className="tdHead">
                                                Number of recordable work-related injuries - Workers who are not Employees
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Number of Cases
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="m-3 fb5">Recordable work-related ill health cases</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="2" className="tdHead">
                                                Number of recordable work-related illnesses or health conditions - Employees
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Number of Cases
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="2" className="tdHead">
                                                Number of recordable work-related illnesses or health conditions - Workers who are not Employees
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Number of Cases
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <sectionheader id={"gd"} className="secHead">
                                Governance Data
                            </sectionheader>
                            <sectionheader1 id={"bc"}>Board Composition</sectionheader1>

                            <div className="m-3 fb5">Board Independence</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Number of Independent Directors
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Total Number of Directors
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Percentage of Independent Directors (%)
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center">{year} </td>
                                            <td colSpan="1" className="text-center">{handleNaNAndInfinity(getDataByDP('DPR0003',year))} </td>
                                            <td colSpan="1" className="text-center">{handleNaNAndInfinity(getDataByDPArray([ 'DPS0254', 'DPS0252' ],year))} </td>
                                            <td colSpan="1" className="text-center"> {handleNaNAndInfinity((getDataByDP('DPR0003',year)/ getDataByDPArray([ 'DPS0254', 'DPS0252' ],year) )*100)} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="m-3 fb5">Women on the board</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Number of Female Directors
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Total Number of Directors
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Percentage of Female Directors (%)
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center">{year} </td>
                                            <td colSpan="1" className="text-center">{handleNaNAndInfinity(getDataByDP('DPS0254',year))} </td>
                                            <td colSpan="1" className="text-center">{handleNaNAndInfinity(getDataByDPArray([ 'DPS0254', 'DPS0252' ],year))} </td>
                                            <td colSpan="1" className="text-center"> {handleNaNAndInfinity((getDataByDP('DPS0254',year)/ getDataByDPArray([ 'DPS0254', 'DPS0252' ],year) )*100)} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <sectionheader1 id={"md"}>Management Diversity</sectionheader1>

                            <div className="m-3 fb5">Women in the Management Team</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Number of Female Senior Management
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Total Number of Employees in Senior Management
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Percentage of Female Senior Management (%)
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center">{year} </td>
                                            <td colSpan="1" className="text-center">{handleNaNAndInfinity(getDataByDP('DPS0258',year))} </td>
                                            <td colSpan="1" className="text-center">{handleNaNAndInfinity(getDataByDPArray([ 'DPS0256', 'DPS0258' ],year))} </td>
                                            <td colSpan="1" className="text-center"> {handleNaNAndInfinity((getDataByDP('DPS0258',year)/ getDataByDPArray([ 'DPS0256', 'DPS0258' ],year) )*100)} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <sectionheader1 id={"eb"}>Ethical Behaviour</sectionheader1>

                            <div className="m-3 fb5">Anti-corruption Disclosures</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Disclosure Standard
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Description of Standard
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center">
                                                Number of Standard
                                            </td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="m-3 fb5">Anti-corruption training for Employees</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Total Number of employees that received anti-corruption training
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Percentage of employees that received anti-corruption training (%)
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                        <td colSpan="1" className="text-center">{year} </td>
                                            <td colSpan="1" className="text-center">{handleNaNAndInfinity(getDataByDP('DPR0016',year))} </td>
                                            <td colSpan="1" className="text-center"> {handleNaNAndInfinity((getDataByDP('DPR0016',year)/ getDataByDPArray([ 'DPS0072', 'DPS0073', 'DPS0075', 'DPS0076' ],year) )*100)} </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <sectionheader1 id={"eb"}>Certifications</sectionheader1>
                            <div className="m-3 fb5">List of Certifications</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Certification (List all sustainability or ESG- related certification (e.g. ISO 45000 family, BCA Green Building, LEED, ENERGY STAR))
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Validity
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <sectionheader1 id={"awf"}>Alignment with Frameworks</sectionheader1>

                            <div className="m-3 fb5">Alignment with frameworks and disclosure practices</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Topic
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Metric
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Alignment with frameworks and disclosure practices (GRI/ TCFD/ SASB/ SDGs/ others)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Description of the extent of application of the framework
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <sectionheader1 id={"ass"}>Assurance</sectionheader1>

                            <div className="m-3 fb5">Assurance of Sustainability Report</div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Organization has Sustainability Report (Y/N)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Whether sustainability report has undertaken: (a) external independent assurance, (b) internal assurance or (c) no assurance
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Scope of assurance
                                            </td>
                                        </tr>
                                        <tr colSpan="3">
                                            <td colSpan="1" className="text-center">{year} </td>
                                            <td colSpan="1" className="text-center"> {getLastResponseByRFID(61,'checkbox-group-1691517292174-0')} </td>
                                            <td colSpan="1" className="text-center"> {getLastResponseByRFID(60,'checkbox-group-1691517214985-0')} </td>

                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SGXReport, comparisonFn);
