import axios from "axios";
import { baseurl } from "../components/constants/api_url";

const APIServices = axios.create({
    baseURL:baseurl,

    headers:{
        'Accept':'application/json',

        'Content-Type':'application/json'
    }
})

export default APIServices