import React, { useEffect, useState } from "react";
import APIServices from "../service/APIService";
import { API } from "../components/constants/api_url";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ReportListing = () => {
    const selector = useSelector(state => state.user.userdetail)
    const [data, setData] = useState([])
    const navigate = useHistory()
    useEffect(() => {
        APIServices.get(API.Report_Name_Ones + `?filter=${encodeURIComponent(JSON.stringify({ 'include': ['reportNameTwos'] }))}`).then((res) => {
            let loc = res.data.filter((i) => { return i.reportNameTwos !== undefined && i.reportNameTwos.length !== 0 })
            let data_ = []
            loc.forEach((i => {
                i.reportNameTwos.forEach((j) => {
                    if (selector.information.report.includes(j.id)) {
                        let index = data_.findIndex((k) => { return i.id === k.id })
                        if (index === -1) {
                            data_.push({ id: i.id, title: i.title, reports: [j] })
                        } else {
                            console.log(data_)
                            data_[index].reports.push(j)
                        }
                    }
                })

            }))
            setData(data_)
        })
    }, [])
    return (
        <div className="grid">
            <div className="col-12">
                <div className="grid">
                    {data.map((item) => {
                        return (
                            <div className="mb-1 col-4 ">
                                <div className="m-1 card" >
                                    <div className="text-bold fs-16 text-blue">

                                        {item.title}
                                    </div>
                                    <br />
                                    <div style={{ height: 180, overflow: 'auto' }}>{
                                        item.reports.map((report, ind) => {
                                            return (
                                                <label onClick={() => { return report.extra !== null ? navigate.push({ pathname: report.extra }) : null }} className="flex mb-2 p-2 hover-blue br-1 " >{ind + 1 + '. ' + report.title}</label>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                            </div>
                        )
                    })

                    }


                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ReportListing, comparisonFn);
