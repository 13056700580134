import React, { useEffect, useRef, useState } from "react";
import APIServices from "../../../service/APIService";
import { API } from "../../../components/constants/api_url";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import $ from "jquery";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import FileSaver, { saveAs } from "file-saver";
import { InputTextarea } from "primereact/inputtextarea";
import { TabMenu } from "primereact/tabmenu";
import ReactDatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { MultiSelect } from "primereact/multiselect";
import { ListBox } from "primereact/listbox";
import { Checkbox } from "primereact/checkbox";
import { DateTime } from "luxon";
import { Badge } from "primereact/badge";
import { Tooltip } from "primereact/tooltip";
import { FilterMatchMode, FilterService } from 'primereact/api';
import { Divider } from "primereact/divider";
import { Tag } from "primereact/tag";
import { checkRoleAccessByRoleIds } from "../../../components/BGHF/helper";

const AssignIndicatortoUser = () => {
    const fymonth = 1
    const [label1, label2, label3] = useSelector((state) => state.user.tierLabel);

    const level_list = [{ name: label1, id: 1 }, { name: label2, id: 2 }, { name: label3, id: 3 }]

    const frequency_list = [{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quarterly', id: 3 }, { name: 'Annually', id: 4 }, { name: 'Bi-Annually', id: 5 }, { name: 'Undefined', id: 6 }]
    const admin_data = useSelector((state) => state.user.userdetail);
    const [load, setLoading] = useState(true)
    const [submitted, setSubmitted] = useState(false)

    const forceUpdate = useForceUpdate();
    const [indicatorlist, setIndicatorList] = useState([])
    const [indicatorassdialog, setIndicatorAssDialog] = useState(false)
    const [indicatorassobj, setIndicatorAssObj] = useState({ levelOfApproval: null, frequencyOfApproval: null, responsibility: [], threshold: false, tvalue1: null, tvalue2: null })
    useEffect(() => {
        let uriString = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]

        }
        let uriString2 = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }


        const promise0 = APIServices.get(API.AssignDCFClient_UP(admin_data.id))
        const promise1 = APIServices.get(API.Categories + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`)

        Promise.all([promise0, promise1]).then((values) => {
            let indicator_list = []
            const shapedCategory = values[1].data.map(item => {
                if (item.newTopics) {
                    item.newTopics = item.newTopics.filter(topics =>
                        topics.newMetrics && topics.newMetrics.length > 0
                    );
                }
                return item;
            }).filter(item => item.newTopics && item.newTopics.length > 0)
            if (values[0].data.length !== 0) {
                // DF

                shapedCategory.flatMap(i => i.newTopics).forEach((top) => {
                    if (values[0].data[0].topic_ids.includes(top.id) && (top.tag === null || parseFloat(top.tag) === admin_data.id)) {
                        top.newMetrics.forEach((met) => {

                            if (values[0].data[0].metric_ids.includes(met.id) && !indicator_list.map(i => i.id).includes(met.id) && (met.tag === null || parseFloat(met.tag) === admin_data.id) && met.newDataPoints !== undefined) {
                                indicator_list.push(met)
                            }
                        })
                    }
                })
                setIndicatorList(indicator_list)


            }
            setLoading(false)
        })
    }, [])
    const openDialog = (item) => {
        setSubmitted(false)
        setIndicatorAssObj({ levelOfApproval: null, frequencyOfApproval: null, responsibility: [], threshold: false, tvalue1: null, tvalue2: null })
        setIndicatorAssDialog(true)

    }
    const updateIndicatorAssobj = (obj, val) => {
        let loc = JSON.parse(JSON.stringify(indicatorassobj))
        loc[obj] = val
        setIndicatorAssObj(loc)
        forceUpdate()

    }
    const SaveAssignment = () => {
        console.log(indicatorassobj)
    }

    return (<div className="font-lato">
        <div style={{
            fontSize: '20px',
            display: 'flex',
            justifyContent: 'center',
            fontWeight: '600', marginBottom: 10
        }} >Indicator Assignment {!load && '(' + indicatorlist.length + ')'}</div>

        <div>
            {indicatorlist.map((i) => {
                return (
                    <div className="m-2 card col-12" >
                        <div className="grid col-12 m-0 p-0"> <div className="col-11">{i.title} </div>
                            <div className="col-1 flex justify-content-end"> <i className="pi pi-cog fs-16" onClick={() => { openDialog(i) }} /> </div>
                        </div>
                    </div>
                )
            })}
        </div>
        <Dialog
            visible={indicatorassdialog}
            style={{
                width: "75%",
            }}
            header={"Assignment of Indicator"}
            modal
            className="p-fluid"

            onHide={() => { setIndicatorAssDialog(false) }}
        >
            <div>

                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10,
                            }}
                        >
                            Set Level of Approval
                        </label>
                    </div>
                    <div className="col-7 ddf">
                        <Dropdown style={{ width: '100%', height: '100%' }} value={indicatorassobj.levelOfApproval} optionValue="id" options={level_list} onChange={(e) => updateIndicatorAssobj("levelOfApproval", e.value)} optionLabel="name" placeholder="Select frequency" />

                    </div>



                </div>
                {submitted && (indicatorassobj.levelOfApproval === null) &&
                    (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red", marginLeft: '22%'
                            }}
                        >

                            Select Frequency
                        </small>
                    )}
                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10,
                            }}
                        >
                            Set Approver Frequency
                        </label>
                    </div>
                    <div className="col-7 ddf">
                        <Dropdown style={{ width: '100%', height: '100%' }} value={indicatorassobj.frequencyOfApproval} optionValue="id" options={frequency_list} onChange={(e) => updateIndicatorAssobj("frequencyOfApproval", e.value)} optionLabel="name" placeholder="Select frequency" />

                    </div>



                </div>
                {submitted && (indicatorassobj.frequencyOfApproval === null) &&
                    (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red", marginLeft: '22%'
                            }}
                        >

                            Select Frequency
                        </small>
                    )}


                <div className="flex justify-content-end">
                    <Button style={{ marginTop: 20, width: 'auto' }} onClick={() => { SaveAssignment() }}>Assign User </Button>
                </div>

            </div>
        </Dialog>
    </div>)
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AssignIndicatortoUser, comparisonFn);