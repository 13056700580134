import React, { useEffect, useState, useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';
import { canvasPreview } from './canvasPreview';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import { Button } from 'primereact/button';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { TabView, TabPanel } from 'primereact/tabview';
import { OrderList } from 'primereact/orderlist';
import useForceUpdate from 'use-force-update';
import { ListBox } from 'primereact/listbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';

import Swal from 'sweetalert2';
import 'react-image-crop/dist/ReactCrop.css'
import exportAsImage from './exportAsImage';
import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import { FaRegSquare, FaRegCheckSquare, FaMinusSquare } from "react-icons/fa";
import { IoMdArrowDropright } from "react-icons/io";
import { MdPlayArrow, MdCheckBoxOutlineBlank, MdOutlineIndeterminateCheckBox } from 'react-icons/md'
import { TbCheckbox } from "react-icons/tb"

import cx from "classnames";

import { useSelector } from 'react-redux';
import { Checkbox } from 'primereact/checkbox';
import $ from'jquery'
import { API } from './constants/api_url';
import APIServices from '../service/APIService';
const google = window.google;
// @ts-ignore
window.jQuery = $;
// @ts-ignore
window.$ = $;
// (0.79/1000)

const AddClientSupplier = () => {

    const [data, setData] = useState([])
    const [apidata, setAPiData] = useState([])

    const [selectedConfigIndex, setSelectedConfigIndex] = useState(null)
    const [adddialog, setAddDialog] = useState(false)
    const [cropdialog, setCropDialog] = useState(false)
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [config, setConfig] = useState({selectedLocationIds:[],selectedLocation:[],selectedModule:[], selectedModuleIds:[],admin:{approver:false,user:true}})
    const [selectedconfig, setSelectedConfig] = useState({ name: 'Assigned Locations' })
    const [configdialog, setConfigDialog] = useState(false)
    const imgRef = useRef(null)
    const selector = useSelector((state)=> state.user.userdetail)
    const previewCanvasRef = useRef(null)
    const [supplier, setSupplier] = useState({ empname: '',supmailid: '',empcontactname:'',emplocation:'',category:null})
    const [submitted, setSubmitted] = useState(false)
    const [filter, setFilter] = useState('')
    const forceUpdate = useForceUpdate()
    const dt = useRef(null);
    const [loctree,setLocTree] = useState([])
    const [modtree,setModTree] = useState()

    let configmenu = [{ name: 'Assigned Locations'},{name:'Admin'}]
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  
   
    const locationtree = {"name":"","children":[{"name":"India","children":[{"name":"TamilNadu",parent:100, "children":[{"name":"Vellore"},{"name":"Sholinghur"}]},{"name":"AP","children":[{"name":"Chittoor"},{"name":"Puttur"}]}]},{"name":"Japan","children":[{"name":"Yuhan","children":[{"name":"Site1"},{"name":"Site2"}]},{"name":"Beijing","children":[{"name":"Site3"},{"name":"Site4"}]}]}]}
   
    useEffect(() => {
        let folder = {
            name: "", children:[]
        }
        selector.information.config[0].location.countrylist.forEach((item,index1)=>{
            folder.children.push({name:item.country,children:[]})
             item.city.forEach((citem,index2)=>{
                   folder.children[index1].children.push({name:citem.city,children:[]})
                   item.city[index2]['location'].forEach((litem,index3)=>{
                     folder.children[index1].children[index2].children.push({name: litem['location']})
                 
             })  
                   })
           
        })
        setLocTree(folder)
    }, [selector])
   useEffect(()=>{

    renderData()

   },[])
   
     const renderData=()=>{
        let datas=[]
        let promise = new Promise((resolve,error)=>
        { 
            APIServices.get( API.UserProfile)
          .then((res) => {
            setAPiData(res.data)
          
         res.data.forEach((item,index)=>{
      if(item.role === 'clientsupplier' && item.information.companyid === selector.userId){
      
               datas.push({id:item.id, empname: item.information.empname, empcontactname: item.information.empcontactname,supmailid: item.email,information:item,emplocation: item.information.emplocation  })
              
      }
      if(index === res.data.length-1){
          resolve(datas)
      }
         })
          })
      })
          promise.then((d)=>{console.log(d); setData(d);forceUpdate()})
        
          
     }
     
    
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2" >
                    <Button label="New Supplier" icon="pi pi-plus" className="p-button-success mr-2" style={{ width: 150 }} onClick={() => {setSupplier({ empname: '',supmailid: '',empcontactname:'',emplocation:'',category:null}); setAddDialog(true) }} />

                </div>
            </React.Fragment>
            <span className="block mt-2 md:mt-0 p-input-icon-left" style={{ width: '40%' }}>
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => { setFilter(e.target.value) }} placeholder="Search..." />
            </span>
        </div>
    );
    

    const checkValidMailID = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }
    const addNewsupplier = () => {
        if(supplier.id === undefined){
        let loc = data
        setSubmitted(true)
         if (checkValidMailID(supplier.supmailid.trim()) &&  supplier.empname.trim().length !== 0 && supplier.empcontactname.trim().length !== 0 && supplier.emplocation.trim().length !== 0  ) {
     
        let obj = supplier
            let newObj = { information: {} }
            Object.keys(obj).forEach((i) => {
                if (i === 'supmailid') {
                    newObj['email'] = obj[i]
                } else {
                    
if(i === 'emplocation'){

    newObj.information['emplocation'] =obj[i].trim()
}else{
    newObj.information[i] = obj[i]
}
                 

                }
            })
            
        
            newObj.information['companyname'] = selector.information.companyname
            newObj.information['companyid'] = selector.userId
            newObj.information['blocked'] = false;
            newObj.information['cid'] = selector.id
            newObj['clientId']=  selector.id
            newObj['role'] = 'clientsupplier'
            console.log(newObj)
             APIServices.post(API.NewUserProfile, newObj )
                .then((res) => {
                   
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: `User Added successfully`,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    setData(loc)
                    renderData()
                                }).catch((e)=>{
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: `Email Id exist / check internet connection `,
                        showConfirmButton: false,
                        timer: 1500
                    }) 
                
                })
       
        setAddDialog(false)
setSelectedConfig({ name: 'Assigned Locations' })
setSubmitted(false)
         setSupplier({ empname: '',supmailid: '',empcontactname:'',emplocation:'',category:null})
      
        forceUpdate()
         }
        }else{
            setSubmitted(true)
            if (checkValidMailID(supplier.supmailid.trim()) && supplier.empcontactname.trim().length !== 0 &&  supplier.emplocation.trim().length !== 0  && supplier.empname.trim().length !== 0 ) {
                let obj = supplier
                let newObj = { information: {} }
                Object.keys(obj).forEach((i) => {
                    if (i === 'supmailid') {
                        
                    } else {
                        
    if(i === 'emplocation'){
       console.log(obj[i])
        newObj.information['emplocation'] = obj[i].trim()
    }
    if(i !== 'id' && i !== 'information' && i !== 'emplocation'){
    
                        newObj.information[i] = obj[i]
    }
    
                    }
                })
               
          
            newObj.information['companyname'] = selector.information.companyname
            newObj.information['companyid'] = selector.userId
            newObj.information['cid'] = selector.id
            newObj.information['blocked'] = supplier.information.blocked
            newObj['company'] = selector.information.companyname
            newObj['role'] = supplier.information.role
            newObj['clientId']=  selector.id
            if(newObj['clientId'] !== null){
            APIServices.patch( API.UserProfile_Edit(supplier.id),  newObj )
            .then((res) => {
                setSupplier({ empname: '',supmailid: '',empcontactname:'',emplocation:'',category:null})
               
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `Updated successfully`,
                    showConfirmButton: false,
                    timer: 1500
                })
               
                renderData()
                            }).catch((e)=>{
                                setSupplier({ empname: '',supmailid: '',empcontactname:'',emplocation:'',category:null})

                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: `Something went wrong`,
                    showConfirmButton: false,
                    timer: 1500
                }) 
            
            })
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: `Something went wrong kindly logout & try again.`,
                showConfirmButton: false,
                timer: 2500
            })
        }
            setAddDialog(false)
            setSelectedConfig({ name: 'Assigned Locations' })
            setSubmitted(false)
               
        }
    }
    }
    const addDialogFooter = () => {
        return (<div>
            <Button label="Submit" icon="pi pi-check" className="p-button-text" onClick={() => { addNewsupplier() }} />

        </div>)
    }
    const updateDataConfig =(data2)=>{
        let id = data[selectedConfigIndex].id
        let apidatalocal = apidata
        let apiIndex =apidata.findIndex((i)=>{return i.id === id})
        
      let newObj =  apidatalocal[apiIndex]
      delete newObj.username;
      delete newObj.email;

      newObj.information.config = data2
      newObj.company = selector.information.companyname
     
      let promise = new Promise((resolve,error)=>{
        APIServices.patch(API.UserProfile_Edit(id), newObj).then((res)=>{
           if(res.status=== 200){
resolve(true)
           }else{
            resolve(false)
           }
        })
      })
       promise.then((status)=>{
        if(status){
            renderData()
        }
       })
    }
    const saveClientSupplier =()=>{
        let loc = data;
        if((config.selectedLocationIds.size !== 0 && config.selectedLocationIds.length !== 0 ) && (config.admin.approver || config.admin.user)){
        if (selectedConfigIndex !== null) {
          
          loc[selectedConfigIndex].config = [config]
          
          updateDataConfig([config])
           
        }else{
            let locc = supplier
            
            locc.config = [config]
            setSupplier(locc)
         
        }
      

setConfigDialog(false)
setSelectedConfigIndex(null)
    }else{
        Swal.fire({
            position: 'center',
            icon: 'warning',
            title: `Location & Module should be selected`,
            showConfirmButton: false,
            timer: 1500
        })
    }
        
    }
    const addDialogFooter2 = (rowData) => {
        return (<div>
            <Button label="Submit" icon="pi pi-check" className="p-button-text" onClick={() => { saveClientSupplier()}} />

        </div>)
    }
    const cropandsave = async () => {

        let canvas = previewCanvasRef.current
        let URLs = canvas.toDataURL('image/png')
        let img = document.createElement('img')
        img.src = URLs
        img.style.height = canvas.height
        img.style.width = canvas.width

        setTimeout(() => {
         

            // atob to base64_decode the data-URI
            var image_data = atob(URLs.split(',')[1]);
            // Use typed arrays to convert the binary data to a Blob
            var arraybuffer = new ArrayBuffer(image_data.length);
            var view = new Uint8Array(arraybuffer);
            for (var i = 0; i < image_data.length; i++) {
                view[i] = image_data.charCodeAt(i) & 0xff;
            }
            try {
                // This is the recommended method:
                var blob = new Blob([arraybuffer], { type: 'application/octet-stream' });
            } catch (e) {
                // The BlobBuilder API has been deprecated in favour of Blob, but older
                // browsers don't know about the Blob constructor
                // IE10 also supports BlobBuilder, but since the `Blob` constructor
                //  also works, there's no need to add `MSBlobBuilder`.
                var bb = new (window.WebKitBlobBuilder || window.MozBlobBuilder);
                bb.append(arraybuffer);
                var blob = bb.getBlob('application/octet-stream'); // <-- Here's the Blob
            }

            // Use the URL object to create a temporary URL
            var url = (window.webkitURL || window.URL).createObjectURL(blob);
            let loc = supplier
            loc.logo = url
            setSupplier(supplier)
            setCropDialog(false)
        }, 1000)

    }
    useEffect(() => {

        if (completedCrop?.width &&
            completedCrop?.height &&
            imgRef.current &&
            previewCanvasRef.current) {

            canvasPreview(
                imgRef.current,
                previewCanvasRef.current,
                completedCrop,
                1,
                0,
            )
        }
    }, [completedCrop, crop])
   const editSupplier=(user)=>{
console.log(user)
setAddDialog(true)
 setSupplier({id:user.id,information:user.information, empname: user.empname,emplocation:user.information.information.emplocation, empcontactname: user.empcontactname,supmailid: user.information.email,category:user.information.information.category})
   }
   const deleteUser=(user)=>{
    console.log(user);

    Swal.fire({
  title: 'Delete user ?'+user.empname,
  text: "You won't be able to reuse mail id "+user.supmailid,
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes, delete User!'
}).then(async (result) => {
  if (result.isConfirmed) {
    const { value: password } = await Swal.fire({
        title: 'Enter Master password',
        input: 'password',
        inputLabel: 'Password',
        inputPlaceholder: 'Enter Master password',
        inputAttributes: {
          maxlength: 10,
          autocapitalize: 'off',
          autocorrect: 'off'
        }
      })
      
      if (password) {
      if(password === 'failletter'){
        APIServices.delete( API.UserProfile_Edit(user.id) )
        .then((res) => {

           
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: `User deleted successfully`,
                showConfirmButton: false,
                timer: 1500
            })
           
            renderData()
                        }).catch((e)=>{
  
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: `Something went wrong`,
                showConfirmButton: false,
                timer: 1500
            }) 
        
        })
      }
      }
    
  }
})
       }
       const blockUser=(user)=>{
        let title = (user.information.information['blocked'] === 'undefined' || !user.information.information['blocked'] ) ? 'Block user '+user.empname+' ?' : 'Unblock user '+user.empname+' ?'
        let text = (user.information.information['blocked'] === 'undefined' || !user.information.information['blocked'] ) ? "Once Blocked user cannot access the platform " : "Once user unblocked, they can access the platform "
        let cnftxt = (user.information.information['blocked'] === 'undefined' || !user.information.information['blocked'] ) ? 'Yes, Block User!' : 'Yes, Unblock User!'
    
        Swal.fire({
      title,
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: cnftxt,
    }).then((result) => {
      if (result.isConfirmed) {
let newObj = user.information;
delete newObj.email
delete newObj.username
newObj.information['blocked'] = user.information.information['blocked'] === undefined ? true : !user.information.information['blocked']  ;
console.log(newObj)       
APIServices.patch(API.UserProfile_Edit(user.id),newObj )
                .then((res) => {
    
                   
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: `User details updated successfully`,
                        showConfirmButton: false,
                        timer: 1500
                    })
                   
                    renderData()
                                }).catch((e)=>{
          
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: `Something went wrong`,
                        showConfirmButton: false,
                        timer: 1500
                    }) 
                
                })
      }
    })
           }
    const nameTemplate = (rowData) => {
console.log(rowData)
        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.empname}
            </>
        );
    }
    const logoTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                <img src={rowData.logo} width='50' />
            </>
        );
    }
    const configTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                <i onClick={() => { openConfig(rowData) }} className='material-icons'>settings</i>
            </>
        );
    }
    const roleTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                <span>{rowData.config[0].admin.approver ? 'Approver' : 'User'}</span>
            </>
        );
    }
    const editTemplate = (rowData) => {

        return (
            <div style={{display:'flex',justifyContent:'space-evenly'}}>
            
                <i  onMouseOut={(e)=>{e.currentTarget.style.color = '#495057'}}  style={{cursor:'pointer'}}  onMouseEnter={(e)=>{e.currentTarget.style.color = 'green'}} onClick={()=>{editSupplier(rowData)}} className='material-icons'>edit</i>
                <i onMouseOut={(e)=>{e.currentTarget.style.color = '#495057'}}  style={{cursor:'pointer'}}     onMouseEnter={(e)=>{e.currentTarget.style.color = 'red'}} onClick={()=>{deleteUser(rowData)}} className='material-icons'>delete</i>
                <i onMouseOut={(e)=>{e.currentTarget.style.color = rowData.information.information.blocked ? 'red': '#495057'}}  style={{cursor:'pointer',color:rowData.information.information.blocked ? 'red':'#495057'}}     onMouseEnter={(e)=>{e.currentTarget.style.color = 'red'}} onClick={()=>{blockUser(rowData)}} className='material-icons'>block</i>
            
            </div>
        );
    }

 
    const addConfigCountry = () => {
  
        if (config.location.newcountry !== '') {
            let loc = config;
            let index = loc.location.countrylist.findIndex((i) => { return i.country.trim().toLowerCase() === config.location.newcountry.trim().toLowerCase() })

            if (index === -1) {
                loc.location.countrylist.push({ country: config.location.newcountry, city: [] })
                loc.location.newcountry = ''
                setConfig(loc)
                forceUpdate()
            }
        }

    }
    const addConfigCity = () => {
   
        if (config.location.newcity !== '') {
            let loc = config;
            let index = loc.location.countrylist.findIndex((i) => { return i.country.trim().toLowerCase() === config.location.selectedcountry.trim().toLowerCase() })

            let index2 = loc.location.countrylist[index].city.findIndex((i) => { return i.city.trim().toLowerCase() === config.location.newcity.trim().toLowerCase() })
            if (index2 === -1) {
                loc.location.countrylist[index].city.push({ city: config.location.newcity, location: [] })
                loc.location.newcity = ''
                setConfig(loc)
                forceUpdate()
            }

        }

    }
    const addConfigLocation = () => {
        if (config.location.newlocation !== '') {
            let loc = config;
            let index = loc.location.countrylist.findIndex((i) => { return i.country.trim().toLowerCase() === config.location.selectedcountry.trim().toLowerCase() })

            let index2 = loc.location.countrylist[index].city.findIndex((i) => { return i.city.trim().toLowerCase() === config.location.selectedcity.trim().toLowerCase() })

            let index3 = loc.location.countrylist[index].city[index2].location.findIndex((i) => { return i.location.trim().toLowerCase() === config.location.newlocation.trim().toLowerCase() })

            if (index3 === -1) {
                loc.location.countrylist[index].city[index2].location.push({ location: config.location.newlocation })
                loc.location.newlocation = ''
                setConfig(loc)
                forceUpdate()
            }

        }
    }
    const ArrowIcon = ({ isOpen, className }) => {
        const baseClass = "arrow";
        const classes = cx(
          baseClass,
          { [`${baseClass}--closed`]: !isOpen },
          { [`${baseClass}--open`]: isOpen },
          className
        );
        return <IoMdArrowDropright className={classes} />;
      };
      
      const CheckBoxIcon = ({ variant, ...rest }) => {
        switch (variant) {
            case "all":
                return <TbCheckbox style={{ fontSize: 19 }} {...rest} />;
            case "none":
                return <MdCheckBoxOutlineBlank style={{ fontSize: 19 }} {...rest} />;
            case "some":
                return <MdOutlineIndeterminateCheckBox style={{ fontSize: 19 }} {...rest} />;
            default:
                return null;
        }
    };
    const openConfig = (config) => {
     
        setSelectedConfigIndex(data.findIndex(((item) =>  { return item.id === config.id })))
        setSelectedConfig({ name: 'Location' })
    
        if (config.config.length === 0) {
           
            setConfig({selectedLocationIds:[],selectedModuleIds:[],selectedLocation:[],selectedModule:[],admin:{approver:false,user:true}})
        } else {
           
            setConfig(config.config[0])
        }

        setConfigDialog(true)
    }
    const contactTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.empid}
            </>
        );
    }
    const destTemplate =( rowData)=>{
        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.empcontactname}
            </>
        );
    }
    const emailTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.supmailid}
            </>
        );
    }
    const userIDTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.emplocation}
            </>
        );
    }
    const updatesupplier = (obj, val) => {
        let loc = supplier
        loc[obj] = val
        setSupplier(loc)
        forceUpdate()
    }
    const centerAspectCrop = (
        mediaWidth,
        mediaHeight,
        aspect
    ) => {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    }
    const onImageLoad = (e) => {

        const { width, height } = e.currentTarget
        setCrop(centerAspectCrop(width, height, 1))

    }
    const getCountryIndex = () => {
        let index = config.location.countrylist.findIndex((i) => { return i.country === config.location.selectedcountry })

        return index
    }
    const getCityIndex = () => {
        let index = config.location.countrylist[getCountryIndex()].city.findIndex((i) => { return i.city === config.location.selectedcity })
        return index
    }
    return (
        <div className="grid p-fluid">
           {selector.role === 'clientadmin' ? <div className="col-12 md:col-12">
                <div className="card ">
                    <div className="col-12 md:col-12" style={{
                    
                        textAlign: 'center',
                        textDecorationColor: 'green'
                    }}>
                        <h5> {`Users (${data.length})`} </h5>
                    </div>
                 
                        <div>
                            <DataTable ref={dt} value={data}
                                dataKey="id" paginator rows={12} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                                globalFilterFields={['empname']} globalFilter={filter} emptyMessage="No records found." header={header} responsiveLayout="scroll">
                                <Column field="empname" header="Supplier Name"  body={nameTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                <Column field="supmailid" header="Mail ID"  body={emailTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                <Column  header="Location"  body={userIDTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                {/* <Column field="config" header="Config" sortable body={configTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                <Column field="role" header="role" sortable body={roleTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column> */}
                                <Column  header="Action"  body={editTemplate} headerStyle={{ width: '5%', minWidth: '2rem' }}></Column>
                            
                            </DataTable>
                            <Dialog visible={adddialog} style={{ width: '450px' }} header="Add Supplier" modal className="p-fluid" footer={addDialogFooter} onHide={() => {setSubmitted(false); setAddDialog(false) }}>

                                <div className="field">
                                    <label htmlFor="refdate">Supplier Company Name <span style={{color:'red'}}>*</span></label>
                                    <InputText type={'text'} value={supplier.empname} onChange={(e) => { updatesupplier('empname', e.target.value) }} placeholder="Name" />
                                    {submitted && supplier.empname.trim().length === 0 && <small className="p-invalid" style={{ color: 'red' }}>Supplier Company Name is mandatory</small>}

                                </div>
                                {/* <div className="field">
                                    <label htmlFor="refdate">User ID</label>
                                    <InputText type={'text'} disabled={supplier.id !== undefined} value={supplier.empid} onChange={(e) => { updatesupplier('empid', e.target.value) }} placeholder="Unique ID for login" />
                                    {submitted && supplier.empid.trim().length === 0 && <small className="p-invalid" style={{ color: 'red' }}>User Id is mandatory</small>}

                                </div> */}
                                <div className="field">
                                    <label htmlFor="refdate">Contact person e-mail id / Login ID <span style={{color:'red'}}>*</span></label>
                                    <InputText disabled={supplier.id !== undefined} type={'email'} value={supplier.supmailid} onChange={(e) => { updatesupplier('supmailid', e.target.value) }} placeholder="Mail ID" />
                                    {submitted && !checkValidMailID(supplier.supmailid.trim()) && <small className="p-invalid" style={{ color: 'red' }}>Supplier Mail ID is mandatory</small>}

                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">Category <span style={{color:'red'}}>*</span></label>
                                    <Dropdown value={supplier.category} optionLabel='name' onChange={(e) => { updatesupplier('category', e.target.value) }} placeholder="Category"  options={[{name:'Others',value:0},{name:'Service Provider',value:1}]} />
                                    {submitted && supplier.category === null  && <small className="p-invalid" style={{ color: 'red' }}>Supplier Category is mandatory</small>}

                                </div>
                                <div className="field">
                                    <label htmlFor="refdate">Country <span style={{color:'red'}}>*</span></label>
                                    <InputText type={'text'} value={supplier.emplocation} onChange={(e) => { updatesupplier('emplocation', e.target.value) }} placeholder="location name" />
                                    {submitted && supplier.emplocation.trim().length === 0  && <small className="p-invalid" style={{ color: 'red' }}>Supplier location is mandatory</small>}

                                </div>
                    
                                <div className="field">
                                    <label htmlFor="refdate">Contact Person Name  <span style={{color:'red'}}>*</span></label>
                                    <InputText type={'email'} value={supplier.empcontactname} onChange={(e) => { updatesupplier('empcontactname', e.target.value) }} placeholder="Supervisor mail id" />
                                    {submitted && supplier.empcontactname.trim().length === 0  && <small className="p-invalid" style={{ color: 'red' }}>Contat person name is mandatory</small>}
                                 

                                </div>
                                {/* <div className="col-12">
                                <div className="col-4">
                                    <Button label="Config" icon="pi pi-cog" className="p-button-text" onClick={(e) => {e.stopPropagation();  setConfigDialog(true);  if( supplier.config.length !== 0 ){ setConfig(supplier.config[0]) }else{setConfig({selectedLocationIds:[],selectedModuleIds:[],selectedLocation:[],selectedModule:[],admin:{approver:false}} )} }} />
                                </div>{
                                    supplier.config.length !== 0 &&
                                    <div className="col-4">
                                        <label htmlFor="refdate">Configured</label>
                                    </div>
                                }
                                {submitted && supplier.config.length === 0 && <small className="p-invalid" style={{ color: 'red' }}>Configuration is mandatory</small>}

                            </div> */}
                           
                            </Dialog>
                        
                            <Dialog visible={configdialog} style={{ width: '75%' }} header="Configure Authorisations" modal className="p-fluid" footer={addDialogFooter2} onHide={() => { setConfigDialog(false) }}>

                                <div className="field">
                                    <label htmlFor="refdate">Select setting menu</label>
                                    <Dropdown style={{ width: '450px' }} value={selectedconfig} onChange={(e) => setSelectedConfig(e.value)} options={configmenu} optionLabel="name" placeholder="Select" />

                                
                                    {/* <div id='tree' >{
                                        renderTree()
                                    }
                                        </div> */}
                                    {/* {selectedconfig.name === 'Assigned Entry Rights' &&
                                       <TreeView
                                       data={modtreedata}
                                       selectedIds={config.selectedModuleIds}
                                       aria-label="Checkbox tree"
                                       multiSelect
                                       propagateSelect
                                       propagateSelectUpwards
                                       togglableSelect
                                       
                                       nodeRenderer={({
                                         element,
                                         isBranch,
                                         isExpanded,
                                         isSelected,
                                         isHalfSelected,
                                         getNodeProps,
                                         level,treeState,
                                         handleSelect,
                                         handleExpand,
                                         
                                       }) => {
                                        updateTree(treeState,2)
                                         return (
                                           <div
                                             {...getNodeProps({ onClick: handleExpand })}
                                             style={{ marginLeft: 40 * (level - 1) }}
                                           >
                                             {isBranch && <ArrowIcon isOpen={isExpanded} />}
                                             <CheckBoxIcon
                                               className="checkbox-icon"
                                               onClick={(e) => {
                                                 handleSelect(e);
                                                 e.stopPropagation();
                                               }}
                                               variant={
                                                 isHalfSelected ? "some" : isSelected ? "all" : "none"
                                               }
                                             />
                                             <span className="name">{element.name}</span>
                                           </div>
                                         );
                                       }}
                                     />

                                    } */}
                                    {selectedconfig.name === 'Admin' && 
                                    <div>
                                     <div className="col-12">
    <Checkbox inputId="cb1" value={'Approver'} onChange={(e)=>{let loc= config; loc.admin.approver=e.checked; setConfig(loc); forceUpdate()}} checked={config.admin.approver}></Checkbox>
    <label htmlFor="cb1" className="p-checkbox-label">Approver</label>
</div>
<div className="col-12">
    <Checkbox inputId="cb2" value={'User'} onChange={(e)=>{let loc= config; loc.admin.user=e.checked; setConfig(loc); forceUpdate()}} checked={config.admin.user}></Checkbox>
    <label htmlFor="cb2" className="p-checkbox-label">User</label>
</div>
                                        </div>
                                        
                                    }
                                </div>

                            </Dialog>
                        </div>
                     






                </div>
            </div>:
           <div className='card col-12'>
                You have no rights to access this page
                </div>
            }
        </div>
    )
}

export default AddClientSupplier






// let data = {
//     "countrylist": [
//         {
//             "country": "India",
//             "city": [
//                 {
//                     "city": "TN",
//                     "location": [
//                         {
//                             "location": "Vellore"
//                         },
//                         {
//                             "location": "Ranipet"
//                         }
//                     ]
//                 },
//                 {
//                     "city": "AP",
//                     "location": [
//                         {
//                             "location": "Chitoor"
//                         }
//                     ]
//                 }
//             ]
//         },
//         {
//             "country": "Japan",
//             "city": [
//                 {
//                     "city": "Yuhan",
//                     "location": [
//                         {
//                             "location": "Yuhan1"
//                         },
//                         {
//                             "location": "Yuhan2"
//                         }
//                     ]
//                 }
//             ]
//         }
//     ],
//     "newcountry": "",
//     "newcity": "",
//     "newlocation": "",
//     "selectedcountry": "Japan",
//     "citylist": [],
//     "selectedcity": "Yuhan",
//     "locationlist": [],
//     "selectedlocation": ""
// }

// let folder ={name:"",children:[]}
// let selectedIds= [2,4,5,8]
// let count = 1;
// let selectedloc=[]
// let selectedcity=[]
// let selectedcountry=[]
// data.countrylist.forEach((item,index1)=>{
//       selectedcountry.push({id:count++,name:item.country})
    
//             folder.children.push({name:item.country,children:[]})
//              item.city.forEach((citem,index2)=>{
                 
//                   selectedcity.push({id:count++,city:citem.city,country:item.country})
//                     folder.children[index1].children.push({name:citem.city,children:[]})
//                     item.city[index2]['location'].forEach((litem,index3)=>{
//                     selectedloc.push({id:count++,location:litem.location,
//                         city:item.city[index2].city,country:item.country
//                     })
//                      folder.children[index1].children[index2].children.push({name: litem['location']})
                 
//              })  
//                     })
           
//         })
//         let finaldata =[]
// selectedIds.forEach((sid)=>{
//     let countryind = selectedcountry.findIndex((i)=>{return i.id === sid})
    
//         let locationind = selectedloc.findIndex((i)=>{return i.id === sid})
//    if(countryind !== -1){
//           finaldata.push(data.countrylist[data.countrylist.findIndex((j)=>{return j.country === selectedcountry[countryind].name})])
//    }

// })
// selectedIds.forEach((sid)=>{
//     let cityind = selectedcity.findIndex((i)=>{return i.id === sid})
//    if(cityind !== -1 && finaldata.findIndex((i)=>{return i.country === selectedcity[cityind].country }) === -1){
    
//    let filtercity = [data.countrylist[data.countrylist.findIndex((j)=>{return j.country === selectedcity[cityind].country })]]
//    console.log(selectedcity[cityind].city)
//   filtercity.forEach((item)=>{
//       item.city.
//   })
   
//      }}
//    )

      
        
        
//          console.log(JSON.stringify(finaldata))