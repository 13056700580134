import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { FileUpload } from 'primereact/fileupload';
import { RadioButton } from 'primereact/radiobutton'
import { useDropzone } from 'react-dropzone';


import { Badge } from 'primereact/badge';
import { Message } from 'primereact/message';
import moment from "moment";
import useForceUpdate from "use-force-update";
import { DateTime } from 'luxon';

import { API } from '../../../components/constants/api_url';
import APIServices from '../../../service/APIService';
import { Tag } from 'primereact/tag';
import { InputTextarea } from 'primereact/inputtextarea';
import { error } from 'jquery';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { MultiSelect } from 'primereact/multiselect';


const getID = () => {
    return parseInt(Date.now() * Math.random()) + moment().unix() + randomIntFromInterval(1, 1000)

}

const randomIntFromInterval = (min, max) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}
const Certification = ({ }) => {

    const userList = useSelector(state => state.userlist.userList)

    const [submitted, setSubmitted] = useState(false)
    const [visible, setVisible] = useState(false);
    const [record, setRecord] = useState([])
    const [certificates, setCertificates] = useState([]);
    const [certificatesbk, setCertificatesBk] = useState([]);
    const [levels, setLevels] = useState([]);
    const [authorities, setAuthorities] = useState([]);
    const [rawsitelist, setRawSitelist] = useState([]);
    const [locList, setLocList] = useState({ country: [], city: [], location: [] })
    const selector = useSelector(state => state.user.userdetail)

    const [selectedcertificate, setSelectedCertificate] = useState({ title: '' })
    const [newcertificatedialog, setNewCertifcateDialog] = useState(false)
    const [attachmentdialog, setAttachmentDialog] = useState(false)
    const [attachmentobj, setAttachmentObj] = useState('')
    const [selectedcertificatelevel, setSelectedCertificateLevel] = useState({ title: '' })
    const [newcertificateleveldialog, setNewCertifcateLevelDialog] = useState(false)

    const [selectedcertificateauthority, setSelectedCertificateAuthority] = useState({ title: '' })
    const [newcertificateauthoritydialog, setNewCertifcateAuthorityDialog] = useState(false)

    const forceUpdate = useForceUpdate()
    const [certificate, setCertificate] = useState({
        certificateId: null,
        scopeType: null,
        status: null,
        certLevelId: null,
        issuedDate: null,
        validity: null,
        remark: '',
        expectedDate: null,
        comments: '',
        attachment: [],
        applicability: { country: 0, city: 0, location: 0 },

    });


    useEffect(() => {
        const uriString = {
            include: [
                {
                    relation: "locationTwos",
                    scope: { include: [{ relation: "locationThrees" }] },
                },
            ],
        };

        const promise0 = APIServices.get(
            API.LocationOne_UP(selector.id) +
            `?filter=${encodeURIComponent(JSON.stringify(uriString))}`
        );
        const promise1 = APIServices.get(API.Client_Certification_UP(selector.clientId))
        const promise2 = APIServices.get(API.Certifcation_UP(selector.id) + '/?filter=%7B%22include%22%3A%5B%22certificationLevels%22%5D%20%7D')

        Promise.all([
            promise0,
            promise1,
            promise2
        ]).then((values) => {

            const shapedSite = values[0].data
                .map((item) => {
                    if (item.locationTwos) {
                        item.locationTwos = item.locationTwos.filter(
                            (locationTwo) =>
                                locationTwo.locationThrees &&
                                locationTwo.locationThrees.length > 0
                        );
                    }
                    return item;
                })
                .filter((item) => item.locationTwos && item.locationTwos.length > 0);
            setRawSitelist(shapedSite)

            setLocList({ country: shapedSite.map(location => ({ name: location.name, id: location.id })) });
            setRecord(values[1].data);
            setCertificates(values[2].data)
            setCertificatesBk(values[2].data)
        })

    }, []);
    // Include openDialog in the dependency array



    const uploadFilesApi = async (file, filelist) => {
        let promise = new Promise((resolve, rej) => {
            if (file.size <= 20000000) {
                let formData = new FormData();
                formData.append("file", file);
                APIServices.post(API.FilesUpload, formData, {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                    mode: "no-cors",
                }).then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        resolve(res.data.files[0]);
                    } else {
                        resolve(null);
                    }
                }).catch((res) => {
                    resolve(null);
                })
            } else {
                resolve(null);
            }


        });

        return promise;
    };








    const handleCertificateChange = (e) => {

        const selectedCertificateId = e.value;
        const selectedCertificate = certificatesbk.find(cert => cert.id === selectedCertificateId);
        setCertificate({ ...certificate, certificateId: selectedCertificateId, scopeType: null, status: null, certLevelId: null, issuedDate: null, validity: null, remark: '', expectedDate: null, comments: '' });

        // const selectedCert = certificates.find(cert => cert.id === selectedCertificate);

        if (selectedCertificate) {


            if (selectedCertificate.certificationLevels) {
                setLevels(selectedCertificate.certificationLevels);
            } else {
                setLevels([]);
            }
        } else {

            setLevels([]);
        }

        forceUpdate();
    };







    const status = [

        { label: 'Issued', value: 1 },

        { label: 'Under Progress', value: 2 },

    ]


    // const saveData = () => {


    //     setSubmitted(true);
    //     let local = certificate;
    //     let local2 = record;
    //     if (certificate.certificateId !== null && certificate.status !== null && certificate.certAuthorityId !== null) {



    //         if (local.id) {
    //             let index = local2.findIndex((i) => {
    //                 return i.id === local.id;
    //             });
    //             local2[index] = local;
    //         } else {
    //             local.id = getID();
    //             local2.push(local);
    //         }



    //         setCertificate({
    //             certificateId: null,
    //             certAuthorityId: null,
    //             scopeOfCertrificate: '',
    //             status: null,
    //             certLevelId: null,
    //             issuedDate: null,
    //             validity: null,
    //             remark: '',
    //             expectedDate: null,
    //             comments: '',
    //             attachment: null,

    //         });

    //         setRecord(local2)
    //         forceUpdate()
    //         setVisible(false)




    //     }
    //     console.log(local2)
    //     console.log(local);

    // };


    const saveData = () => {
        // Logic to save data
        // onHide();

        setSubmitted(true);
        let local = certificate;
        let local2 = record;
        if (certificate.certificateId !== null && certificate.status !== null && certificate.scopeType !== null && (certificate.status === 1 ? (certificate.certLevelId !== null && certificate.issuedDate !== null && certificate.validity !== null) : (certificate.expectedDate !== null))) {

            if (certificate.id === undefined) {
                let newObj = { certificateId: certificate.certificateId, status: certificate.status, scopeType: certificate.scopeType, created_on: DateTime.utc(), created_by: selector.id, applicability: certificate.applicability }
                if (certificate.attachment && certificate.attachment.length) {
                    newObj.attachment = certificate.attachment
                }

                if (certificate.status === 1) {
                    newObj.certLevelId = certificate.certLevelId
                    newObj.issuedDate = certificate.issuedDate
                    newObj.validity = certificate.validity
                    newObj.remark = certificate.remark

                    newObj.comments = ''
                    newObj.expectedDate = null

                }
                else {
                    newObj.certLevelId = certificate.certLevelId
                    newObj.issuedDate = certificate.issuedDate
                    newObj.validity = certificate.validity
                    newObj.remark = ''
                    newObj.comments = certificate.comments
                    newObj.expectedDate = certificate.expectedDate

                }
                APIServices.post(API.Client_Certification_UP(selector.id), newObj).then((res) => {

                    local2.push(res.data);

                    setCertificate({
                        certificateId: null,

                        scopeType: null,
                        status: null,
                        certLevelId: null,
                        issuedDate: null,
                        validity: null,
                        remark: '',
                        expectedDate: null,
                        comments: '',
                        attachment: [],
                        applicability: { country: 0, city: 0, location: 0 },

                    });
                    setRecord(local2)
                    forceUpdate()
                    setVisible(false)
                    setSubmitted(false)
                })
            }
            else {
                let newObj = { certificateId: certificate.certificateId, status: certificate.status, scopeType: certificate.scopeType, modified_on: DateTime.utc(), modified_by: selector.id, applicability: certificate.applicability }
                if (certificate.attachment && certificate.attachment.length) {
                    newObj.attachment = certificate.attachment
                }

                newObj.certLevelId = certificate.certLevelId
                newObj.issuedDate = certificate.issuedDate
                newObj.validity = certificate.validity
                newObj.remark = certificate.remark

                newObj.comments = certificate.comments
                newObj.expectedDate = certificate.expectedDate



                delete newObj.created_on
                delete newObj.created_by
                APIServices.patch(API.Client_Certification_Edit(certificate.id), newObj).then((res) => {

                    // let index = local2.findIndex((i) => {
                    //     return i.id === local.id;
                    // });
                    // local2[index] = local;

                    let index = local2.findIndex(i => i.id === certificate.id)
                    if (index !== -1) {
                        local2[index] = { ...local2[index], ...newObj }
                    }

                    setRecord(local2)

                    setVisible(false)
                    setSubmitted(false)


                })
            }

        }
        console.log(certificate)
    };


    const getStatusLabel = (value) => {
        const statusObj = status.find(status => status.value === value);
        return statusObj ? statusObj.label : '';

    };


    const getLevelLabel = (value) => {

        const certIndex = certificates.findIndex(cert => cert.id === value.certificateId);
        let text1 = ''
        if (certIndex !== -1) {
            const levelIndex = certificates[certIndex].certificationLevels.findIndex(cert => cert.id === value.level)
            if (levelIndex !== -1) {
                text1 = certificates[certIndex].certificationLevels[levelIndex].title
            }
        }
        return text1;

        // const levelObj = levels.find(level => level.id === value);
        // return levelObj ? levelObj.title : '';

    };

    const getCertificateLabel = (value) => {
        const certificateObj = certificatesbk.find(cert => cert.id === value);
        return certificateObj ? certificateObj.title : '';
    };








    const onHide = () => setVisible(false);



    // Template for the 'Status' column
    // const statusBodyTemplate = (rowData) => {
    //     let severity;
    //     switch (rowData.currentStatus) {
    //         case 'Completed':
    //             severity = 'success';
    //             break;
    //         case 'Planned':
    //             severity = 'info';
    //             break;
    //         case 'In-progress':
    //             severity = 'warn';
    //             break;
    //         default:
    //             severity = 'info';
    //             break;
    //     }

    //     return <Message severity={severity} text={rowData.currentStatus} />;
    // };

    const dateFormating = (rowData) => {
        // Parse the date string or JavaScript Date object
        if (typeof rowData === 'string') {
            return DateTime.fromISO(rowData, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')
        } else {
            const luxonDate = DateTime.fromJSDate(rowData); // Assuming rowData.startDate is a JavaScript Date object

            // Format the date to "dd-MM-yyyy" format
            const formattedDate = luxonDate.toLocal().toFormat('dd-MM-yyyy');
            return formattedDate;
        }

    }

    const dateOfIssuance = (rowData) => {
        return (
            <div>
                {rowData.status === 2 ? "N/A" : (dateFormating(rowData.issuedDate) || "N/A")}

            </div>
        )
    }

    const validityTemplate = (rowData) => {
        return (
            <div>

                {rowData.status === 2 ? "N/A" : (dateFormating(rowData.validity) || "N/A")}
            </div>
        )
    }
    const getUser = (uid) => {
        let user_ = userList.find(user => user.id === uid);
        if (uid === selector.id) {
            return 'Admin'
        } else if(user_) {
            return user_.information.empname
        }else{
            return 'Not Found'
        }
    }
    const createdByTemplate = (rowData) => {

        return (
            <div>

                {getUser(rowData.created_by)}
            </div>
        )
    }
    const applicabilityTemplate = (rowData) => {
        rowData.ent = getCoverageText(rowData.applicability)
        return (
            <div>

                {getCoverageText(rowData.applicability) || "N/A"}
            </div>
        )
    }
    const expectedDateTemplate = (rowData) => {
        return (
            <div>

                {rowData.status === 1 ? "N/A" : (dateFormating(rowData.expectedDate) || "N/A")}


            </div>
        )
    }

    const statusLevel = (value) => {
        const certIndex = certificates.findIndex(cert => cert.id === value.certificateId);

        let text = ''
        if (certIndex !== -1 && certificates[certIndex].certificationLevels) {
            const authIndex = certificates[certIndex].certificationLevels.findIndex(cert => cert.id === value.certLevelId);
            if (authIndex !== -1) {
                text = certificates[certIndex].certificationLevels[authIndex].title
            }

        }
        return text

    }

    const newCertificate = () => {
        setCertificate({
            certificateId: null,
            scopeType: null,

            status: null,
            certLevelId: null,
            issuedDate: null,
            validity: null,
            remark: '',
            expectedDate: null,
            comments: '',
            attachment: [],
            applicability: { country: 0, city: 0, location: 0 },
        });

        let item = { country: 0, city: 0, location: 0 }
        let country_list = [{ name: 'All', id: 0 }]
        let city_list = [{ name: 'All', id: 0 }]
        let location_list = [{ name: 'All', id: 0 }]
        rawsitelist.forEach((country) => {
            country_list.push({ name: country.name, id: country.id })
            if (country.id === item.country || item.country === 0) {
                if (country.locationTwos) {
                    country.locationTwos.forEach((city) => {
                        city_list.push({ name: city.name, id: city.id })
                        if (city.id === item.city || item.city === 0) {
                            if (city.locationThrees) {
                                city.locationThrees.forEach((site) => {
                                    location_list.push({ name: site.name, id: site.id })

                                })
                            }
                        }
                    })

                }

            }

        })

        setLocList((prev) => ({ ...prev, 'country': country_list, 'city': city_list, 'location': location_list }))
        setVisible(true);
        setSubmitted(false)
    }

    // const updateCertificate = (obj, val) => {
    //     let local = certificate;

    //     local[obj] = val;
    //     console.log(local)
    //     setCertificate(local)
    //     forceUpdate();
    // }

    const updateCertificate = (obj, val) => {
        let local = certificate
        if (obj === 'applicability') {
            let item = val
            let selected_item = { country: 0, city: 0, location: 0 }
            let country_list = [{ name: 'All', id: 0 }]
            let city_list = [{ name: 'All', id: 0 }]
            let location_list = [{ name: 'All', id: 0 }]
            rawsitelist.forEach((country) => {
                country_list.push({ name: country.name, id: country.id })
                if (country.id === item.country || item.country === 0) {
                    if (country.locationTwos) {
                        country.locationTwos.forEach((city) => {
                            city_list.push({ name: city.name, id: city.id })
                            if (city.id === item.city || item.city === 0) {
                                if (city.locationThrees) {
                                    city.locationThrees.forEach((site) => {
                                        location_list.push({ name: site.name, id: site.id })

                                    })
                                }
                            }
                        })

                    }

                }

            })
            setLocList((prev) => ({ ...prev, 'country': country_list, 'city': city_list, 'location': location_list }))

        } else if (obj === 'status') {
            local.validity = null
            local.expectedDate = null
            local.issuedDate = null;
            local.certLevelId = null;
            local.comments = ''
            local.remark = ''

        } else if (obj === 'issuedDate') {
            local.validity = null
        }


        local[obj] = val

        console.log(local[obj], obj)
        setCertificate((prev) => ({ ...prev, ...local }))
        forceUpdate();
    }



    // Header for the table with a search input
    const renderHeader = () => {
        return (
            <div className="table-header-container">
                <h5>Certification</h5>

                <div className="table-header-actions py-4 d-flex justify-content-between">

                    <Button onClick={() => { newCertificate(); setVisible(true); }} label="Add New" icon="pi pi-plus" className="p-button-primary" /* Add click event logic here */ />
                </div>
            </div>
        );
    };


    // Styles for the dropzone
    const dropzoneStyle = {
        border: '2px dashed gray',
        borderRadius: '5px',
        padding: '40px 20px', // Increased padding as requested
        textAlign: 'center',
        cursor: 'pointer'
    };

    // Icon style
    const iconStyle = {
        width: '48px',
        height: '48px',
        color: '#9e9e9e'
    };

    const dialogFooter = (
        <div>
            <Button label="Cancel" icon="pi pi-times" onClick={onHide} className="p-button-text" />
            <Button label="Save" icon="pi pi-check"
                onClick={() => {
                    saveData()
                }}
            />
        </div>
    );

    const editVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))

        if (items.status === 1) {

            items.issuedDate = DateTime.fromISO(items.issuedDate, { zone: 'utc' }).toJSDate()
            items.validity = DateTime.fromISO(items.validity, { zone: 'utc' }).toJSDate()

        }
        else {

            items.expectedDate = DateTime.fromISO(items.expectedDate, { zone: 'utc' }).toJSDate()

        }
        const selectedCertificate = certificates.find(cert => cert.id === items.certificateId);


        if (selectedCertificate) {

            if (selectedCertificate.certIssueAuthorities) {
                setAuthorities(selectedCertificate.certIssueAuthorities);
            }
            if (selectedCertificate.certificationLevels) {
                setLevels(selectedCertificate.certificationLevels);
            }
        } else {
            items.certLevelId = null

            setAuthorities([]);
            setLevels([]);
        }
        let item_ = items.applicability

        let country_list = [{ name: 'All', id: 0 }]
        let city_list = [{ name: 'All', id: 0 }]
        let location_list = [{ name: 'All', id: 0 }]
        rawsitelist.forEach((country) => {
            country_list.push({ name: country.name, id: country.id })
            if (country.id === item_.country || item_.country === 0) {
                if (country.locationTwos) {
                    country.locationTwos.forEach((city) => {
                        city_list.push({ name: city.name, id: city.id })
                        if (city.id === item_.city || item_.city === 0) {
                            if (city.locationThrees) {
                                city.locationThrees.forEach((site) => {
                                    location_list.push({ name: site.name, id: site.id })

                                })
                            }
                        }
                    })

                }

            }

        })
        setLocList(() => ({ 'country': country_list, 'city': city_list, 'location': location_list }))

        setCertificate(items)
        setVisible(true);
        forceUpdate()
    }



    const deleteRow = (item) => {
        Swal.fire({
            title: `Are you sure want to delete ${item.title} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                APIServices.delete(API.Client_Certification_Edit(item.id))
                    .then(() => {
                        let loc = record.filter(rec => rec.id !== item.id);
                        setRecord(loc);
                    })
                    .catch(error => {
                        console.error('Error deleting record:', error);
                    });
            }
        })

    };


    const actionBodyTemplate = (rowData) => {
        return (
            <>


                <div className="actions">

                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    const titleTemplate = (rowData) => {

        rowData.cert = getCertificateLabel(rowData.certificateId)
        return (
            <div className="text-underline clr-navy fw-5 cur-pointer"
                onClick={() => { editVal(rowData) }}>{getCertificateLabel(rowData.certificateId)}
            </div>
        );
    };

    function getFileExtension_(filename) {
        const lastDot = filename.lastIndexOf('.');
        let ext = lastDot === -1 ? '' : filename.slice(lastDot + 1).toLowerCase();
        let ext_types = ['pdf', 'jpeg', 'jpg', 'png', 'bmp']
        console.log(ext)
        return ext_types.includes(ext)
    }
    function getFileExtension(filename) {
        const lastDot = filename.lastIndexOf('.');
        return lastDot === -1 ? '' : filename.slice(lastDot + 1).toLowerCase();
    }

    const attachmentTemplate = (file, index) => {

        return (<div className="flex align-items-center justify-content-between text-three-dot col-10 mt-2">
            {(getFileExtension(file.originalname) === 'pdf') ?
                <img src={require('../../../assets/images/pdf_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                (getFileExtension(file.originalname) === 'xlsx' || getFileExtension(file.originalname) === 'xls') ?
                    <img src={require('../../../assets/images/excel_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                    (getFileExtension(file.originalname) === 'pptx' || getFileExtension(file.originalname) === 'ppt') ?
                        <img src={require('../../../assets/images/ppt_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                        (getFileExtension(file.originalname) === 'docx' || getFileExtension(file.originalname) === 'doc') ?
                            <img src={require('../../../assets/images/word_icon.png').default} width={100} style={{ borderRadius: 10 }} />
                            :
                            <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
            <span className="flex align-items-center text-left ml-3  clr-navy cur-pointer" onClick={() => { window.open(API.Docs + file.originalname) }}>
                {file.originalname.slice(13)}
                <Tag className={'status-tag-gray ml-2'} style={{ display: 'block', textDecoration: 'none' }} value={'Updated_on: ' + DateTime.fromISO(file.uploaded_on, { zone: 'utc' }).toLocal().toFormat('dd-LL-yyyy hh:mm a')} />

            </span>
            <span>
                <i onClick={() => { certificate.attachment.splice(index, 1); forceUpdate() }} className='material-icons clr-delete'>delete</i>
            </span>
        </div>)
    }
    const certificateItemTemplate = (rowData) => {
        return (



            <div>
                <div className="grid justify-content-between col-12" style={{ padding: 0 }} >
                    <div className="col-10">
                        {rowData.title}
                    </div>

                    <div className="col-2" style={{ zIndex: 10 }}>
                        <i className="material-icons" style={{ color: 'green' }} onClick={(e) => {
                            e.stopPropagation();

                            setSelectedCertificate(JSON.parse(JSON.stringify(rowData))); setNewCertifcateDialog(true)
                        }}>edit</i>
                    </div>
                </div>
            </div>




        )
    }
    const certificateAuthorityTemplate = (rowData) => {
        return (
            <div className="grid justify-content-between col-12" style={{ padding: 0 }} >
                <div className="col-10">
                    {rowData.title}
                </div>

                <div className="col-2" style={{ zIndex: 10 }}>
                    <i className="material-icons" style={{ color: 'green' }} onClick={(e) => {
                        e.stopPropagation();

                        setSelectedCertificateAuthority(JSON.parse(JSON.stringify(rowData))); setNewCertifcateAuthorityDialog(true)
                    }}>edit</i>
                </div>
            </div>


        )
    }
    const certificateLevelTemplate = (rowData) => {
        return (

            < div >
                {
                    rowData.id === null ?
                        <div className='hide-add-new-in-dd' onClick={(e) => { e.stopPropagation(); setSelectedCertificateLevel({ title: '' }); setNewCertifcateLevelDialog(true); }}> Add New </div> :
                        <div>
                            <div className="grid justify-content-between col-12" style={{ padding: 0 }} >
                                <div className="col-10">
                                    {rowData.title}
                                </div>

                                <div className="col-2" style={{ zIndex: 10 }}>
                                    <i className="material-icons" style={{ color: 'green' }} onClick={(e) => {
                                        e.stopPropagation();

                                        setSelectedCertificateLevel(JSON.parse(JSON.stringify(rowData))); setNewCertifcateLevelDialog(true)
                                    }}>edit</i>
                                </div>
                            </div>
                        </div>

                }
            </div >


        )
    }
    const { getRootProps, getInputProps, isDragActive } = useDropzone({

        accept: "image/*,application/pdf",
        onDrop: (acceptedFiles) => {
            console.log(acceptedFiles);
            if (acceptedFiles.filter(i => i.size <= 20000000).length !== 0 && getFileExtension_(acceptedFiles[0].name)) {
                let fileList = acceptedFiles.filter(i => i.size <= 20000000)
                let formData = new FormData()
                fileList.forEach((file) => {
                    formData.append('file', file);
                })

                APIServices.post(API.FilesUpload, formData, {
                    headers: {
                        'content-type': 'multipart/form-data'

                    }
                }).then((res) => {
                    let loc = certificate
                    let filelist = []

                    res.data.files.forEach((file) => {
                        filelist.push(file)
                    })
                    loc.attachment = filelist

                    console.log(res.data)
                    setCertificate(loc)
                    forceUpdate();
                })
            }

            // Handle file logic here
        }

    });

    const attachmentBodyTemplate = (rowData) => {
        return (
            <div className='flex justify-content-center align-items-center'>
                {(!rowData.attachment || rowData.attachment.length === 0) ?
                    <span className="attachment-link">
                        {rowData.status === 2 ? 'NA' : 'Not Uploaded'}
                    </span>
                    :
                    <div className='grid' >
                        {rowData.attachment && rowData.attachment.map(i => {
                            return (
                                <span className="attachment-link">
                                    <div onClick={() => { setAttachmentObj(API.Docs + i.originalname); setAttachmentDialog(true) }} >
                                        {i.originalname.includes('.pdf') ? <i className="pi pi-file-pdf fs-16" style={{ 'marginRight': '0.5em', 'color': 'red' }}></i> :

                                            <i className="pi pi-image fs-16" style={{ 'marginRight': '0.5em', 'color': 'red' }}></i>}

                                    </div>
                                </span>
                            )
                        })

                        }
                    </div>}


            </div>

        );
    };

    const getCoverageText = (rowData) => {
        console.log(rowData)
        let text = 'Not Found'
        if (rowData.country === 0 && rowData.city === 0 && rowData.location === 0) {
            text = 'Corporate'
        } else if (rowData.country !== 0 && rowData.city === 0 && rowData.location === 0) {
            let country_index = rawsitelist.findIndex(i => i.id === rowData.country)
            console.log(country_index)
            if (country_index !== -1) {
                text = rawsitelist[country_index].name
            }
        } else if (rowData.country !== 0 && rowData.city !== 0 && rowData.location === 0) {
            let city_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.city_id === rowData.city })
            if (city_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[city_index].city_name
            }
        } else {
            let site_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.site_id === rowData.location })
            if (site_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[site_index].site_name
            }
        }


        return text
    }
    const addCertificateFooter = () => {
        return (
            <div>
                <Button label="Save" disabled={selectedcertificate.title.trim().length === 0} onClick={(e) => {
                    e.stopPropagation();
                    addNewCertificate()
                }} />
            </div>
        )
    }
    const addCertificateAuthorityFooter = () => {
        return (
            <div>
                <Button label="Save" disabled={selectedcertificateauthority.title.trim().length === 0} onClick={(e) => {
                    e.stopPropagation();
                    addNewCertificateAuthority()
                }} />
            </div>
        )
    }
    const addCertificateLevelFooter = () => {
        return (
            <div>
                <Button label="Save" disabled={selectedcertificatelevel.title.trim().length === 0} onClick={(e) => {
                    e.stopPropagation();
                    addNewCertificateLevel()
                }} />
            </div>
        )
    }
    const addNewCertificate = () => {
        if (selectedcertificate.title.trim().length) {
            if (selectedcertificate.id) {
                let newObj = { ...selectedcertificate }
                delete newObj.created_by
                delete newObj.created_on
                delete newObj.certificationLevels
                delete newObj.certIssueAuthorities

                APIServices.patch(API.Certifcation_Edit(selectedcertificate.id), newObj).then((res) => {
                    let loc = JSON.parse(JSON.stringify(certificates))
                    let loc2 = JSON.parse(JSON.stringify(certificatesbk))
                    let index = loc.findIndex(i => i.id === selectedcertificate.id)
                    let index2 = loc2.findIndex(i => i.id === selectedcertificate.id)
                    if (index !== -1 && index2 !== -1) {
                        loc2[index2] = { ...loc2[index2], ...newObj }
                        loc[index] = loc2[index]
                        setCertificates(loc)
                        setCertificatesBk(loc2)
                        setNewCertifcateDialog(false)
                    }

                })
            } else {
                let newObj = { ...selectedcertificate, created_by: selector.id, created_on: DateTime.utc() }


                APIServices.post(API.Certifcation_UP(selector.id), newObj).then((res) => {
                    let loc = JSON.parse(JSON.stringify(certificates))
                    let loc2 = JSON.parse(JSON.stringify(certificatesbk))
                    loc2.push(res.data)
                    loc.push(res.data)
                    setCertificates(loc)
                    setCertificatesBk(loc2)
                    setNewCertifcateDialog(false)


                })
            }
        }
    }
    const addNewCertificateAuthority = () => {
        console.log(selectedcertificateauthority)
        if (selectedcertificateauthority.title.trim().length) {
            if (selectedcertificateauthority.id) {
                let newObj = { ...selectedcertificateauthority }
                delete newObj.created_by
                delete newObj.created_on
                delete newObj.certificationLevels
                delete newObj.certIssueAuthorities

                APIServices.patch(API.Authority_Issue_Edit(selectedcertificateauthority.id), newObj).then((res) => {
                    let loc = JSON.parse(JSON.stringify(authorities))
                    let loc2 = JSON.parse(JSON.stringify(certificatesbk))
                    let index = loc.findIndex(i => i.id === selectedcertificateauthority.id)
                    let index2 = loc2.findIndex(i => i.id === certificate.certificateId)

                    if (index !== -1) {


                        let index3 = loc2[index2].certIssueAuthorities.findIndex(i => i.id === selectedcertificateauthority.id)
                        if (index3 !== -1) {
                            loc2[index2].certIssueAuthorities[index3] = { ...selectedcertificateauthority }
                        }
                        setCertificatesBk(loc2)
                        loc[index] = { ...loc[index], ...newObj }
                        setCertificates(loc)

                        setNewCertifcateAuthorityDialog(false)
                    }

                })
            } else {
                let newObj = { ...selectedcertificateauthority, created_by: selector.id, created_on: DateTime.utc() }


                APIServices.post(API.Authority_Certifcation(certificate.certificateId), newObj).then((res) => {
                    let loc = JSON.parse(JSON.stringify(authorities))
                    let loc2 = JSON.parse(JSON.stringify(certificatesbk))
                    loc.push(res.data)
                    setAuthorities(loc)
                    let index2 = loc2.findIndex(i => i.id === certificate.certificateId)
                    if (index2 !== -1) {
                        if (loc2[index2].certIssueAuthorities) {
                            loc2[index2].certIssueAuthorities.push(res.data)
                        } else {
                            loc2[index2].certIssueAuthorities = [res.data]
                        }

                    }

                    setAuthorities(loc)

                    setNewCertifcateAuthorityDialog(false)


                })
            }
        }
    }
    const addNewCertificateLevel = () => {
        if (selectedcertificatelevel.title.trim().length) {
            if (selectedcertificatelevel.id) {
                let newObj = { ...selectedcertificatelevel }
                delete newObj.created_by
                delete newObj.created_on
                delete newObj.certificationLevels
                delete newObj.certIssueAuthorities

                APIServices.patch(API.Certifcation_Level_Edit(selectedcertificatelevel.id), newObj).then((res) => {
                    let loc = JSON.parse(JSON.stringify(levels))
                    let loc2 = JSON.parse(JSON.stringify(certificatesbk))
                    let index = loc.findIndex(i => i.id === selectedcertificatelevel.id)
                    let index2 = loc2.findIndex(i => i.id === certificate.certificateId)

                    if (index !== -1) {
                        let index3 = loc2[index2].certificationLevels.findIndex(i => i.id === selectedcertificatelevel.id)
                        if (index3 !== -1) {
                            loc2[index2].certificationLevels[index3] = { ...selectedcertificatelevel }
                        }
                        setCertificatesBk(loc2)
                        loc[index] = { ...loc[index], ...newObj }
                        setLevels(loc)
                        setCertificates(loc)

                        setNewCertifcateLevelDialog(false)
                    }

                })
            } else {
                let newObj = { ...selectedcertificatelevel, created_by: selector.id, created_on: DateTime.utc() }


                APIServices.post(API.Cert_Level_Certifcation(certificate.certificateId), newObj).then((res) => {
                    let loc = JSON.parse(JSON.stringify(levels))
                    let loc2 = JSON.parse(JSON.stringify(certificatesbk))

                    let index2 = loc2.findIndex(i => i.id === certificate.certificateId)
                    if (index2 !== -1) {
                        if (loc2[index2].certificationLevels) {
                            loc2[index2].certificationLevels.push(res.data)
                        } else {
                            loc2[index2].certificationLevels = [res.data]
                        }

                    }
                    loc.push(res.data)
                    setLevels(loc)

                    setNewCertifcateLevelDialog(false)


                })
            }
        }
    }
    const sortRPValidity = (e) => {
        console.log(e.data);
        if (e.order === 1) {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromISO(a.validity, { zone: 'utc' });
                let dateB = DateTime.fromISO(b.validity, { zone: 'utc' });


                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            });
        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromISO(a.validity, { zone: 'utc' });
                let dateB = DateTime.fromISO(b.validity, { zone: 'utc' });
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            });
        }
    };
    const sortRPIssuance = (e) => {
        console.log(e.data);
        if (e.order === 1) {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromISO(a.issuedDate, { zone: 'utc' });
                let dateB = DateTime.fromISO(b.issuedDate, { zone: 'utc' });


                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            });
        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromISO(a.issuedDate, { zone: 'utc' });
                let dateB = DateTime.fromISO(b.issuedDate, { zone: 'utc' });
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            });
        }
    };
    const sortRPExpected = (e) => {
        console.log(e.data);
        if (e.order === 1) {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromISO(a.expectedDate, { zone: 'utc' });
                let dateB = DateTime.fromISO(b.expectedDate, { zone: 'utc' });


                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            });
        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromISO(a.expectedDate, { zone: 'utc' });
                let dateB = DateTime.fromISO(b.expectedDate, { zone: 'utc' });
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            });
        }
    };
    const entityRowFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(record))
        let entOptions = allentity.map(i => getCoverageText(i.applicability))
        console.log(allentity)
        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                value={options.value}
                options={entOptions}

                onChange={(e) => options.filterApplyCallback(e.value)}

                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const certRowFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(certificates))
        let entOptions = allentity.map(i => i.title)
        console.log(allentity)
        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                filter={true}
                value={options.value}
                options={entOptions}

                onChange={(e) => options.filterApplyCallback(e.value)}

                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    return (
        <div className='font-lato'>

            {selector.role === "clientadmin" ? (
                <DataTable
                    value={record} filters={{ ent: { value: null, matchMode: 'in' }, cert: { value: null, matchMode: 'in' } }}
                    dataKey="id"
                    header={renderHeader()} rows={10} rowsPerPageOptions={[10, 20, 50, 100]}
                    //   header={edit === 1 && renderHeader()}
                    className="p-datatable-initiatives">
                    <Column header="Applicability" field='ent' showFilterMatchModes={false} filter showApplyButton={false} filterElement={entityRowFilterTemplate}
                        body={applicabilityTemplate}
                    />
                    <Column header="Submitted By" field='created_by'
                        body={createdByTemplate}
                    />
                    <Column
                        field="cert"
                        header="Certificate" showFilterMatchModes={false} filter showApplyButton={false} filterElement={certRowFilterTemplate}
                        // body={(rowData) => { return <> {getCertificateLabel(rowData.certificateId)} </> }}
                        body={titleTemplate}
                    />




                    <Column field="status" header="Status"
                        body={(rowData) => { return <>{getStatusLabel(rowData.status)}</> }}
                    />

                    <Column field="level" header="Certificate Level"
                        // body={(rowData) => { return <>{getLevelLabel(rowData.level)}</> }}
                        body={statusLevel}
                    />

                    <Column field="issueDate" header="Date of Issuance" sortable sortFunction={sortRPIssuance}
                        body={dateOfIssuance} />


                    <Column field="validity" header="Validity" sortable sortFunction={sortRPValidity}
                        body={validityTemplate}
                    />

                    <Column field="expectedDate" header="Expected Date " sortable sortFunction={sortRPExpected}
                        body={expectedDateTemplate}
                    />
                    <Column header='View Certificate' body={attachmentBodyTemplate} />

                    <Column
                        body={actionBodyTemplate}
                    >

                    </Column>

                </DataTable>
            ) :
                <div className="col-12 card">You have no rights to access this page </div>
            }

            <Dialog header="Add Certification" visible={visible} style={{ width: '50vw' }} footer={dialogFooter} onHide={() => {
                setVisible(false);
                setSubmitted(false);
                // setCertificate({ certificateId: '', certAuthorityId: '' });
                // setAuthorities([]);
            }} modal>
                <div className="p-fluid row p-3">
                    <div className="p-field  col-12 pe-0 ps-0 pb-4">
                        <div className='flex justify-content-between'><label htmlFor="category">Select Certification  <span className='mandatory'>*</span></label> </div>
                        {/* <Dropdown id="category" value={certificate.certificateId} options={certificates.map(item => ({label: item.title, value: item.id}))} onChange={(e) => setCertificate({ ...certificate, certificateId: e.value })} placeholder="Select Certificate" /> 
                        */}
                        <Dropdown id="certify" value={certificate.certificateId}
                            optionValue='id'
                            options={[...certificates.map(cert => ({ title: cert.title, id: cert.id }))]}

                            optionLabel='title'
                            onChange={handleCertificateChange}
                            placeholder="Select Certificate" />

                        {submitted && certificate.certificateId === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Select Certificate
                            </small>
                        )}
                    </div>



                    {certificate.certificateId && ( // Check if an authority is selected
                        <>

                            <div className="p-field col-12 ">
                                <fieldset style={{
                                    padding: '20px',
                                    borderRadius: '10px',
                                    border: '1px solid #ced4da',

                                }}>
                                    <legend>Entity</legend>
                                    <div className='row justify-content-between'>
                                        <div className='col-4'>
                                            <label>Country</label>
                                            <Dropdown className='mt-2 mb-2' style={{ width: '100%' }} value={certificate.applicability.country} options={locList.country} optionLabel="name" optionValue="id"
                                                onChange={(e) => { updateCertificate('applicability', { country: e.value, city: 0, location: 0 }); }} placeholder="Select Country"
                                            />
                                        </div>
                                        {certificate.applicability.country !== 0 &&
                                            <div className='col-4'>  <label>City</label>
                                                <Dropdown className='mt-2 mb-2' style={{ width: '100%' }} value={certificate.applicability.city} options={locList.city} optionLabel="name" optionValue="id"
                                                    onChange={(e) => { updateCertificate('applicability', { country: certificate.applicability.country, city: e.value, location: 0 }); }} placeholder="Select City"
                                                />
                                            </div>
                                        }
                                        {certificate.applicability.country !== 0 && certificate.applicability.city !== 0 && <div className='col-4'> <label>Location</label>
                                            <Dropdown className='mt-2 mb-2' style={{ width: '100%' }} value={certificate.applicability.location} options={locList.location} optionLabel="name" optionValue="id"
                                                onChange={(e) => { updateCertificate('applicability', { country: certificate.applicability.country, city: certificate.applicability.city, location: e.value }); }} placeholder="Select Site"
                                            /></div>}
                                    </div>
                                </fieldset>
                            </div>

                            <div className="p-field col-12 pe-0 ps-0 pb-4">
                                <label htmlFor="description">Type of Scope <span className='mandatory'>*</span></label>
                                <Dropdown id="certify" value={certificate.scopeType}
                                    optionValue='id'
                                    options={[{ name: 'Operational', id: 1 }, { name: 'Building Design & Construction', id: 2 }]}

                                    optionLabel='name'
                                    onChange={(e) => updateCertificate('scopeType', e.value)}
                                    placeholder="Select Scope Type" />

                                {submitted && certificate.scopeType === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >

                                        Select Scope Type
                                    </small>
                                )}


                            </div>

                            <div className="p-field col-12 pe-0 ps-0 pb-4">
                                <label htmlFor="category">Status <span className='mandatory'>*</span></label>
                                <Dropdown id="category" value={certificate.status} optionValue="value" options={status} onChange={(e) => { updateCertificate("status", e.target.value) }} placeholder="Select  status" />


                                {submitted && certificate.status === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >

                                        Select Status
                                    </small>
                                )}

                            </div>

                        </>
                    )}



                    {certificate.status === 1 && (
                        <>
                            <div className="p-field col-12 pe-0 ps-0 pb-4">

                                <div className='flex justify-content-between'><label htmlFor="category">Select Certification Level<span className='mandatory'>*</span></label></div>
                                <Dropdown id="level"
                                    value={certificate.certLevelId}
                                    optionValue='id'
                                    optionLabel='title'
                                    options={[...levels.map(authority => ({ title: authority.title, id: authority.id }))]}

                                    onChange={(e) => { updateCertificate("certLevelId", e.value) }}

                                    placeholder="Certification Level" />

                                {submitted && certificate.certLevelId === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >

                                        Select Certification Level                          </small>
                                )}
                            </div>
                            <div className="p-field col-12 pe-0 ps-0 pb-4">
                                <label htmlFor="DateOfIssuance">Date of Issuance <span className='mandatory'>*</span></label>
                                <Calendar id="issuedDate" value={certificate.issuedDate}
                                    onChange={(e) => { updateCertificate("issuedDate", e.value) }} showIcon />

                                {submitted && certificate.issuedDate === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >

                                        Select Date of Issuance  </small>
                                )}
                            </div>
                            {certificate.issuedDate !== null &&
                                <div className="p-field col-12 pe-0 ps-0 pb-4">
                                    <label htmlFor="completionDate">Validity <span className='mandatory'>*</span></label>
                                    <Calendar id="completionDate" value={certificate.validity}
                                        onChange={(e) => { updateCertificate("validity", e.value) }} minDate={certificate.issuedDate} showIcon />

                                    {submitted && certificate.validity === null && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >

                                            Select validity date
                                        </small>
                                    )}
                                </div>}

                            <div className="p-field col-12 pe-0 ps-0 pb-4">
                                <label htmlFor="description">Remarks </label>
                                <InputText id="description" value={certificate.remark} onChange={(e) => setCertificate({ ...certificate, remark: e.target.value })} />

                            </div>

                            <div className="p-field  col-12 ">
                                <div >
                                    <label htmlFor="attachment" className='flex' >Upload Certificate </label>

                                    {certificate.attachment && certificate.attachment.length > 0 && <FileUpload
                                        mode="basic"

                                        className="flex justify-content-end"
                                        chooseLabel={
                                            "Add More"}
                                        customUpload
                                        uploadHandler={(files) => {
                                            uploadFilesApi(files.files[0]).then((res) => {
                                                if (res !== null) {
                                                    let local = certificate;
                                                    if (local.attachment === null) {
                                                        local.attachment = [res];
                                                    } else {
                                                        local.attachment.push(res);
                                                    }

                                                    setCertificate(local);
                                                    forceUpdate();
                                                }
                                                files.options.clear();
                                            });
                                        }}
                                        name="demo[]"
                                        auto={true}
                                        accept="image/*,application/pdf"
                                        maxFileSize={10000000}
                                    />}
                                </div>


                                {certificate.attachment && certificate.attachment.length ?
                                    <div>{certificate.attachment.map((file, index) => {
                                        return (
                                            attachmentTemplate(file, index)
                                        )

                                    })
                                    }
                                    </div>

                                    : <div {...getRootProps()} className='mt-2' style={dropzoneStyle}>
                                        <input {...getInputProps()} />
                                        <i className='pi pi-cloud-upload'></i>
                                        <p>Drag and drop or click to upload</p>
                                    </div>
                                }
                            </div>

                        </>
                    )}

                    {certificate.status === 2 && (
                        <>
                            <div>
                                <div className="p-field col-12 pe-0 ps-0 pb-4">
                                    <label htmlFor="completionDate">Expected Date of Achievement <span className='mandatory'>*</span> </label>
                                    <Calendar id="completionDate" value={certificate.expectedDate}
                                        onChange={(e) => { updateCertificate("expectedDate", e.value) }} showIcon />

                                    {submitted && certificate.expectedDate === null && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >

                                            Enter Issuing Authority                            </small>
                                    )}
                                </div>
                            </div>

                            <div className="p-field col-12 pe-0 ps-0 pb-4">
                                <label htmlFor="description">Comments</label>
                                <InputText id="description" value={certificate.comments} onChange={(e) => setCertificate({ ...certificate, comments: e.target.value })} />


                            </div>

                        </>
                    )}
                </div>
            </Dialog>

            <Dialog visible={newcertificatedialog} footer={addCertificateFooter} onHide={() => { setNewCertifcateDialog(false) }} style={{ width: '50%' }} header={selectedcertificate.id ? 'Edit ' + selectedcertificate.title : 'Add New Certificate'} >

                <div>
                    <div className='field flex justify-content-around align-items-center'>
                        <label>Title</label>
                        <InputText onChange={(e) => setSelectedCertificate({ ...selectedcertificate, title: e.target.value })} value={selectedcertificate.title} />
                    </div>

                </div>
            </Dialog>
            <Dialog visible={newcertificateauthoritydialog} footer={addCertificateAuthorityFooter} onHide={() => { setNewCertifcateAuthorityDialog(false) }} style={{ width: '50%' }} header={selectedcertificateauthority.id ? 'Edit ' + selectedcertificateauthority.title : 'Add New Authority'} >

                <div>
                    <div className='field flex justify-content-around align-items-center'>
                        <label>Title</label>
                        <InputText onChange={(e) => setSelectedCertificateAuthority({ ...selectedcertificateauthority, title: e.target.value })} value={selectedcertificateauthority.title} />
                    </div>

                </div>
            </Dialog>
            <Dialog visible={newcertificateleveldialog} footer={addCertificateLevelFooter} onHide={() => { setNewCertifcateLevelDialog(false) }} style={{ width: '50%' }} header={selectedcertificatelevel.id ? 'Edit ' + selectedcertificatelevel.title : 'Add New Auhtority'} >

                <div>
                    <div className='field flex justify-content-around align-items-center'>
                        <label>Title</label>
                        <InputText onChange={(e) => setSelectedCertificateLevel({ ...selectedcertificatelevel, title: e.target.value })} value={selectedcertificatelevel.title} />
                    </div>

                </div>
            </Dialog>
            <Dialog visible={attachmentdialog} onHide={() => { setAttachmentDialog(false) }} style={{ width: '50%' }} header={'Certificate '} >
                <div className='p-2' style={{ width: '100%' }}>
                    {attachmentobj.includes('.pdf') ?
                        <iframe width={'100%'} style={{ height: 'calc(100vh - 200px )' }} src={attachmentobj + '#toolbar=0'} />
                        :
                        <img src={attachmentobj} width={'100%'} />

                    }
                </div>
            </Dialog>
        </div>

    );
};

export default Certification;
