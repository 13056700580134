import { useEffect, useState, useRef } from 'react';

export function useHeadsObserver() {
  const observer = useRef(null);
  const [activeId, setActiveId] = useState('');

  useEffect(() => {
    const handleObserver = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveId(entry.target.id);
        }
      });
    };

    observer.current = new IntersectionObserver(handleObserver, {
      rootMargin: "-10px 0px -35% 0px", // Adjust these values as needed
    });

    const elements = document.querySelectorAll("#sectionheader, #sectionheader1, #sectionheader2");
    elements.forEach((elem) => observer.current.observe(elem));

    return () => {
      observer.current.disconnect();
    };
  }, []);

  return { activeId };
}
