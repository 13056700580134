import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import $ from "jquery";
import { API } from "../components/constants/api_url";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { InputTextarea } from "primereact/inputtextarea";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { TabMenu } from "primereact/tabmenu";
import ReactDatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import APIServices from "../service/APIService";
window.jQuery = $;
window.$ = $;
const { DateTime } = require('luxon')


const ClientUserDCFAssignment = () => {
    const selector = useSelector((state) => state.user.userdetail);
    const locationList = useSelector((state) => state.sitelist.locationList)
    const [dcfconfig, setDCFConfig] = useState({ frequency: null, start_date: null, end_date: null, standard: 0, user_id: null, approver_id: null, site: null, dcfId: null, type: 0, comments: [] });
    const [selDataPoint, setSelDataPoint] = useState([])
    const configtype = [{ name: 'Location' }, { name: 'Data Point' }]
    const [assignedlist, setAssignedList] = useState([])
    const [justifylist, setJustifyList] = useState([])
    const [sitelist, setSiteList] = useState([])
    const [sitesuggestion, setSiteSuggestion] = useState([])
    const [assignedlistbk, setAssignedListBK] = useState([])
    const [unassignedlist, setUnAssignedList] = useState([])
    const [unassignedlistBK, setUnAssignedListBK] = useState([])
    const [stdlist, setStdList] = useState([])
    const [stdlistBk, setStdListBK] = useState([])
    const [frequencyMonths, setFrequencyMonths] = useState([])
    const [assigndialog, setAssignDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [search, setSearch] = useState('')
    const [search_, setSearch_] = useState({ ass: '', unass: '', jus: '' })

    const forceUpdate = useForceUpdate();
    const [old, setOld] = useState([]);
    const [datapoint, setDataPoint] = useState([]);
    const [dcflist, setDCFList] = useState([]);
    const [dcf, setDCF] = useState('');
    const [dcfitems, setDCFItems] = useState([]);
    const [locationtree, setLocationTree] = useState([]);
    const frequency_list = [{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quarterly', id: 3 }, { name: 'Annually', id: 4 }, { name: '6-Monthly', id: 5 }, { name: 'Undefined', id: 6 }]
    const [location, setLocation] = useState([]);
    const [userList, setUserList] = useState([]);
    const [approverList, setApproverList] = useState([]);
    const [userRawList, setUserRawList] = useState([]);
    const [approverRawList, setApproverRawList] = useState([]);
    const [userConfig, setUserConfig] = useState({
        client: "", type: "",
        location: ''
    });
    const [module, setModule] = useState({
        tier1: "",
        tier2: "",
        tier3: "",
    });
    const [selected, setSelected] = useState(1);
    const [cascade, setCascade] = useState("");
    const [showSave, setShowSave] = useState(0);
    const [tier1, setTier1] = useState([]);
    const [tier2, setTier2] = useState([]);
    const [tier3, setTier3] = useState([]);
    const [moduleList, setModuleList] = useState({
        mod: [],
        title: [],
        topic: [],
    });
    useEffect(() => {

        let loc = userConfig
        let uriString2 = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }
        let locuser = [], locapprover = [], dcf_list = [], capture = []
        let site_list = []
        APIServices.get(API.EF_Std).then((res) => { setStdListBK(res.data); })
        let REQUEST_URL_WITH_ID_2 = API.LocationOne_UP(selector.id)
        let url_2 = REQUEST_URL_WITH_ID_2 + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`;
        let counter = 0;
        APIServices.get(url_2)
            .then((res) => {
                if (res.status === 200) {

                    counter++



                    res.data.forEach((cat, i) => {

                        if (cat.locationTwos !== undefined) {


                            cat.locationTwos.forEach((topic, j) => {

                                if (topic.locationThrees !== undefined) {

                                    topic.locationThrees.forEach((metric, k) => {


                                        site_list.push({ name: metric.name + " (" + topic.name + ")", id: metric.id, country: { name: cat.name, id: cat.id }, city: { name: topic.name, id: topic.id } })


                                        let capi = capture.findIndex((aa) => { return aa.value === cat.id })
                                        if (capi === -1) {
                                            capture.push({
                                                value: cat.id, text: cat.name, id: cat.id + '_country', children: [
                                                    {
                                                        value: topic.id, text: topic.name, id: topic.id + '_city', children: [
                                                            {
                                                                value: metric.id, text: metric.name, id: metric.id + '_site'


                                                            }
                                                        ]
                                                    }
                                                ]
                                            })

                                        } else {
                                            let topi = capture[capi].children.findIndex((aa) => { return aa.value === topic.id })
                                            if (topi === -1) {
                                                capture[capi].children.push({

                                                    value: topic.id, text: topic.name, id: topic.id + '_city', children: [
                                                        {
                                                            value: metric.id, text: metric.name, id: metric.id + '_site'
                                                        }
                                                    ]

                                                })
                                            } else {
                                                let meti = capture[capi].children[topi].children.findIndex((aa) => { return aa.value === metric.id })
                                                if (meti === -1) {
                                                    capture[capi].children[topi].children.push({


                                                        value: metric.id, text: metric.name, id: metric.id + '_site'


                                                    })
                                                }
                                                else {

                                                }

                                            }
                                        }




                                    })
                                }
                            })
                        }
                    })

                    setSiteList(site_list)
                    loc.location = capture
                    setUserConfig(loc);
                    renderLocationTree(capture)
                }
            })
            .catch((e) => {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: `Something went wrong, try again later `,
                    showConfirmButton: false,
                    timer: 1500,
                });
            })
        let uriString = {
            "include": [{ "relation": "newDataPoints" }]

        }


        const promise0 = APIServices.get(API.Metric + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`)
        const promise1 = APIServices.get(API.DCF)
        const promise2 = APIServices.get(API.AssignDCFClient_UP(selector.id))
        const promise3 = APIServices.get(API.UserProfile)
        const promise4 = APIServices.get(API.AssignDCFUser_UP(selector.id))
        Promise.all([promise1, promise2, promise3, promise4, promise0]).then(function (values) {

            if (values[1].data.length !== 0) {
                values[4].data.forEach((j) => {
                    if (values[1].data[0].metric_ids.includes(j.id)) {
                        if (j.newDataPoints) {
                            j.newDataPoints.forEach((k) => {
                                if (Array.isArray(k.data1) && k.data1[0].datasource && typeof k.data1[0].datasource === 'number') {
                                    let dcfindex = values[0].data.findIndex((m) => { return m.id === k.data1[0].datasource })
                                    let dcfindex2 = dcf_list.findIndex((m) => { return m.id === k.data1[0].datasource })

                                    if (dcfindex !== -1 && dcfindex2 === -1) {
                                        dcf_list.push(values[0].data[dcfindex])
                                    }
                                }
                            })
                        }
                    }

                })
                if (values[1].data[0].cf_ids !== null && values[1].data[0].cf_ids.length !== 0) {
                    values[1].data[0].cf_ids.forEach((id) => {
                        let dcfindex = values[0].data.findIndex((m) => { return m.id === id })
                        if (dcfindex !== -1) {
                            dcf_list.push(values[0].data[dcfindex])
                        }
                    })
                }
                setDCFList(dcf_list);
            }
            values[2].data.forEach((item) => {

                if ((item.clientId === selector.id && item.role === 'clientuser')) {

                    if (item.id !== selector.id && item.role === 'clientuser' && item.information.role.reporter === true) {

                        locuser.push({
                            name: item.information.empname,
                            id: item.id,
                        })
                    }

                    if (item.id !== selector.id && item.role === 'clientuser' && item.information.role.approver === true) {

                        locapprover.push({
                            name: item.information.empname,
                            id: item.id,
                        })
                    }



                }
            });
            setApproverList(locapprover)
            setUserList(locuser);
            setUserRawList(locuser);
            setApproverRawList(locapprover);
            let data_ = []
            values[3].data.forEach((item) => {
                console.log(item)
                if (dcf_list.findIndex((k) => { return k.id === item.dcfId }) !== -1) {

                    delete item.assignDcfClientId
                    delete item.formCollectionId
                    item.sites = site_list.filter((i) => { return i.id === item.site[0] })
                    item.frequency = frequency_list[frequency_list.findIndex((k) => { return k.id === item.frequency })]
                    item.dcfId = dcf_list[dcf_list.findIndex((k) => { return k.id === item.dcfId })]
                    item.user_id = locuser[locuser.findIndex((k) => { return k.id === item.user_id })]
                    item.approver_id = locapprover[locapprover.findIndex((k) => { return k.id === item.approver_id })]
                    data_.push(item)
                }
            })

            setAssignedListBK((prev) => ([...prev, ...data_]))
            setAssignedList(data_.filter((i) => { return i.type === 0 }))
            setJustifyList(data_.filter((i) => { return i.type === 1 }))
        })

    }, [selector])
    // useEffect(()=>{console.log(assignedlist)},[assignedlist])
    useEffect(() => {


        getUnAssigned(JSON.parse(JSON.stringify(assignedlistbk)))



    }, [assignedlistbk])

    const getSiteName = (id) => {
        let result = ''
        userConfig.location.forEach((item) => {
            if (item.id === id) {

                result = item.text
            } else {
                item.children.forEach((item2) => {
                    if (item2.id === id) {
                        result = item2.text
                    } else {
                        item2.children.forEach((item3) => {
                            if (item3.id === id) {
                                result = item3.text
                            }
                        })
                    }
                })
            }
        })
        return result
    }
    const getSiteNames = (id) => {


        let loc = JSON.parse(JSON.stringify(sitelist))
        let result = null
        loc.forEach((item3) => {


            if (item3.id === id) {

                result = item3
            }

        })


        return result


    }
    const getUnassignedDCFList = () => {

    }
    const getUnAssigned = (asslist) => {

        let loc = JSON.parse(JSON.stringify(sitelist))
        let dcf__list = JSON.parse(JSON.stringify(dcflist))
        let loclist = JSON.parse(JSON.stringify(locationList))
        let list = []
        dcf__list.forEach((assitem) => {

            list.push({ id: assitem.id, name: assitem.title, site: [], unassign: loclist.map((k) => { return k.id }), unassigns: JSON.parse(JSON.stringify(locationList)) })

        })
        asslist.forEach((unass) => {
            let index = list.findIndex((k) => { return k.id === unass.dcfId.id })

            if (index !== -1) {
                list[index].site.push(unass.site[0])
                let siteindex = list[index].unassigns.findIndex((l) => { return l.id === unass.site[0] })
                if (siteindex !== -1) {
                    list[index].unassigns.splice(siteindex, 1)
                }

                let siteindex2 = list[index].unassign.findIndex((l) => { return l === unass.site[0] })
                if (siteindex2 !== -1) {
                    list[index].unassign.splice(siteindex2, 1)
                }


            }
        })

        setUnAssignedList(list.filter((i) => { return i.unassigns.length !== 0 }))
        setUnAssignedListBK(list.filter((i) => { return i.unassigns.length !== 0 }))
    }
    const getUnAssFilterData = (unassdata) => {
        let sttunass = []
        unassdata.filter((i) => { return i.unassigns.length !== 0 }).forEach((i) => {
            i.unassigns.forEach((j) => {
               
                sttunass.push({ dcf: i.name,dcf_id:i.id,site_id:j.id, site: j.country.name + '>' + j.city.name + '>' + j.title })
            })
        })
        return sttunass
    }
    const updateDCFConfig = (obj, val) => {
        let loc = dcfconfig;

        if (obj === 'dcfId' && val !== null && val !== undefined) {
            let locsite = JSON.parse(JSON.stringify(sitelist))
            let locass = JSON.parse(JSON.stringify(assignedlist)).filter((i) => { return i.dcfId.id === val.id })
            let locjus = JSON.parse(JSON.stringify(justifylist)).filter((i) => { return i.dcfId.id === val.id })
            

            locsite.forEach((i) => {
                let index = locass.sort((a, b) => { return b.id - a.id }, 0).findIndex((res) => { return res.site.includes(i.id) })
                let index1 = locjus.findIndex((res) => { return res.site.includes(i.id) })
                if ((index !== -1 && (typeof locass[index].end_date !== 'string')) || index1 !== -1) {
                    i.disabled = true
                } else {
                    i.disabled = false
                }
            })
            setSiteList(locsite)
            setStdList(stdlistBk.filter(i => {return i.dcf_ids !== null && i.dcf_ids.includes(val.id)}))
            console.log(val,stdlistBk,stdlistBk.filter(i => { i.dcf_ids !== null && i.dcf_ids.includes(val.id)}))
            loc['standard'] = 0
            loc['site'] = null
        }
        if (obj === 'site') {

            loc[obj] = val === null ? [] : val
        }
        if (obj === 'start_date' || obj === 'end_date') {
            if (loc[obj] === null) {
                loc[obj] = val === undefined ? null : val;
            } else {
                loc[obj] = val === undefined ? null : val;
            }

            console.log(loc[obj], val)
        }
        if (obj !== 'comments' && obj !== 'site' && obj !== 'start_date' && obj !== 'end_date' && obj !== 'frequency' && obj !== 'standard') {

            loc[obj] = val === undefined ? null : val
        } else if (obj === 'comments') {
            loc[obj][0] = val
            loc[obj][1] = moment.utc()
        } else if (obj === 'frequency') {
            loc['start_date'] = null
            loc[obj] = val === undefined ? null : val
            console.log(val.id)
            if (val !== null) {
                if (val.id === 1 || val.id === 6) {
                    setFrequencyMonths([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])
                } else if (val.id === 3 || val.id === 4 || val.id === 5) {
                    setFrequencyMonths([0, 3, 6, 9])
                } else {
                    setFrequencyMonths([0, 2, 4, 6, 8, 10])
                }
            }
        } else if (obj === 'standard') {
            loc[obj] = (val === undefined || val === null) ? 0 : val
        }

        // loc[obj]= val

        // if (obj === 'user_id') {

        //     if (val !== undefined) {
        //         let locapprover = JSON.parse(JSON.stringify(approverRawList));
        //         let index = locapprover.findIndex((i) => { return i.id === val.id })
        //         if (index !== -1) {
        //             locapprover.splice(index, 1)
        //             setApproverList(locapprover)

        //         }
        //     } else {
        //         setApproverList(approverRawList)
        //     }
        // } else if (obj === 'approver_id') {
        //     if (val !== undefined) {
        //         let locuser = JSON.parse(JSON.stringify(userRawList));
        //         let index = locuser.findIndex((i) => { return i.id === val.id })
        //         if (index !== -1) {
        //             locuser.splice(index, 1)
        //             setUserList(locuser)

        //         }
        //     } else {
        //         setUserList(userRawList)
        //     }
        // }

        console.log(loc)
        setDCFConfig(loc)
        forceUpdate()
    }



    const handleSearch = (event) => {
        setSearch(event)
        let backup = JSON.parse(JSON.stringify(assignedlistbk))
        let filter = backup.filter((item) => { if (item.dcfId.title.trim().toLowerCase().includes(event.trim().toLowerCase()) || item.frequency.name.trim().toLowerCase().includes(event.trim().toLowerCase()) || (item.sites.findIndex((i) => { return i.trim().toLowerCase().includes(event.trim().toLowerCase()) })) !== -1) { return item } })

        setAssignedList(filter)
    }

    const search_dcf = (event) => {

        let _items = dcflist.filter((k) => { return k.title.trim().toLowerCase().includes(event.query.trim().toLowerCase()) });
        setDCFItems(_items);
        forceUpdate()
    }
    const search_site = (event) => {

        let _items = sitelist.filter((k) => { return k.name.trim().toLowerCase().includes(event.query.trim().toLowerCase()) });
        setSiteSuggestion(_items);
        forceUpdate()
    }


    const renderLocationTree = (data, type) => {

        if (document.getElementById('loctree') !== null) {

            $('#loctree').jstree({
                'core': {
                    'check_callback': true,
                    'data':
                        data,
                    "themes": {
                        "icons": false
                    }
                },

                'checkbox': {
                    'keep_selected_style': false
                },
                'search': {
                    'case_insensitive': true,
                    'show_only_matches': true
                },
                'plugins': ['checkbox', 'search']
            });
            $('#loctree').on('check_node.jstree uncheck_node.jstree changed.jstree', function (e,
                data) {
                // Get the list of checked node IDs


                if (data.event) {
                    var id = $('#type-id').val();
                    var type = $('#user-type').val();
                    var selected = $('#loctree').jstree("get_selected");

                    var overall = $('#loctree').jstree(true).get_selected(true);

                    let sorted_selection = overall.filter((k) => { return k.state.disabled === false }).map((i) => { return i.id })
                    setDCFConfig((prev) => ({ ...prev, 'site': sorted_selection }))

                    // Send the list of checked node IDs to the backend server
                    // if (id && type) {
                    //     save_checked_units(id, type, selected, overall);
                    //     save_assignment(id, type, selected, overall);
                    // }
                }
                // console.log(checkedNodeIds);


            });
            $('#loctree').delegate("a", "click", function (event, data) {
                if (event.currentTarget.className.split(' ').length === 3) {
                    $('#loctree').jstree(true).open_all(this, false, false);
                    $(event.target).find('.jstree-checkbox').click()
                } else {
                    $('#loctree').jstree(true).close_all(this, false, false);
                    $(event.target).find('.jstree-checkbox').click()
                }

            })
            $('#loctree').jstree(true).settings.core.data = data;
            if (type === undefined) {
                $('#loctree').jstree(true).refresh();
            }


        }
    }
    const assignDCFUser = () => {
        setSubmitted(true)
        let ser = search_
console.log(dcfconfig)
        if ((dcfconfig.type === 1 && dcfconfig.site !== null && dcfconfig.site.length !== 0 && dcfconfig.comments.length !== 0 && dcfconfig.comments[0].trim().length !== 0) || (dcfconfig.type === 0 &&  (stdlist.length !== 0 ?  dcfconfig.standard !== 0 : true)   &&  dcfconfig.approver_id !== null && dcfconfig.start_date !== null && dcfconfig.frequency !== null && dcfconfig.site !== null && dcfconfig.site.length !== 0 && dcfconfig.user_id !== null && dcfconfig.user_id.id !== dcfconfig.approver_id.id) ) {
            let data = {}
            if (dcfconfig.type === 0) {
                data = { end_date: dcfconfig.end_date, standard: dcfconfig.standard, approver_id: dcfconfig.approver_id.id, start_date: dcfconfig.start_date, user_id: dcfconfig.user_id.id, frequency: dcfconfig.frequency.id, site: [dcfconfig.site.id], dcfId: dcfconfig.dcfId.id, comments: ['', ''], type: 0 }

            } else {
                data = { approver_id: 0, user_id: 0, frequency: 0, site: dcfconfig.site, dcfId: dcfconfig.dcfId.id, comments: dcfconfig.comments, type: 1 }

            }
         setStdList([])
            if (dcfconfig.id === undefined) {
                delete data.end_date
                data['site'] = Array.isArray(dcfconfig.site) ? dcfconfig.site : [dcfconfig.site.id]
                data['created'] = moment.utc()
                data['creator_id'] = selector.id
                APIServices.post(API.AssignDCFUser_UP(selector.id), data).then((res) => {
                    ser.ass = ''
                    ser.jus = ''
                    ser.unass = ''
                    setSearch_(ser)
                    let dcf_list = JSON.parse(JSON.stringify(dcflist))
                    let locuser = JSON.parse(JSON.stringify(userRawList))
                    let locapprover = JSON.parse(JSON.stringify(approverRawList))

                    let asslistbk = JSON.parse(JSON.stringify(assignedlistbk))
                    let item = res.data
                    delete item.assignDcfClientId
                    delete item.formCollectionId

                    item.frequency = frequency_list[frequency_list.findIndex((k) => { return k.id === item.frequency })]
                    item.dcfId = dcf_list[dcf_list.findIndex((k) => { return k.id === item.dcfId })]
                    item.user_id = locuser[locuser.findIndex((k) => { return k.id === item.user_id })]
                    item.approver_id = locapprover[locapprover.findIndex((k) => { return k.id === item.approver_id })]
                    item.sites = sitelist.filter((i) => { return i.id === item.site[0] })
                    asslistbk.push(item)
                    setAssignedListBK((prev) => [...prev, item])
                    setAssignedList(asslistbk.filter((i) => { return i.type === 0 }))
                    setJustifyList(asslistbk.filter((i) => { return i.type === 1 }))

                    setSearch('')
                    forceUpdate()
                    setSubmitted(false)
                    setAssignDialog(false)
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Assignment Added Successfully",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                })
            } else {
                if (data.end_date === null) {
                    delete data.end_date
                }
                if (data.standard === null) {
                    data.standard = 0
                }
                data['modified_on'] = moment.utc()
                data['modifier_id'] = selector.id
                data['site'] = dcfconfig.site

                APIServices.patch(API.DCF_User_Edit(dcfconfig.id), data).then((res) => {
                    let data2 = dcfconfig
                    let assignls = assignedlistbk
                    let dcf_list = JSON.parse(JSON.stringify(dcflist))
                    let locuser = JSON.parse(JSON.stringify(userList))
                    ser.ass = ''
                    ser.jus = ''
                    ser.unass = ''
                    setSearch_(ser)
                    data2['sites'] = data2.site.map((siteid) => { return getSiteNames(siteid) }).filter((item) => { return item !== null })
                    assignls[assignls.findIndex((k) => { return k.id === dcfconfig.id })] = data2


                    setAssignedListBK((prev) => (assignls))
                    setAssignedList(assignls.filter((i) => { return i.type === 0 }))
                    setJustifyList(assignls.filter((i) => { return i.type === 1 }))

                    setSearch('')
                    forceUpdate()
                    setSubmitted(false)
                    setAssignDialog(false)
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Assignment updated Successfully",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                })
            }
        }
    }
    const deleteAssignDCF = async (item) => {
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to delete DCF Assignment
          </div>`,

            confirmButtonColor: 'red',
            showCancelButton: true,

            confirmButtonText:
                'Delete',

        })
        if (accept) {
            let loc = JSON.parse(JSON.stringify(assignedlistbk));

            APIServices.delete(API.DCF_User_Edit(item.id)).then((a) => {
                let ser = search_
                ser.ass = ''
                ser.unass = ''
                setSearch_(ser)
                loc.splice(loc.findIndex((k) => { return k.id === item.id }), 1)

                setAssignedListBK(loc)
                setAssignedList(loc.filter((k) => { return k.type === 0 }))


                forceUpdate()
            })
        }

    }
    const getJustificationCount = () => {


        return assignedlistbk.filter((i) => { return i.type === 1 }).length
    }
    const getUnassignedCount = () => {
        let count = 0;
        unassignedlist.forEach((item) => {

            count = count + item.unassigns.length
        })
        return count
    }
    const getAssignedCount = () => {
        let total = 0;
        let count = 0;
        if (sitelist.length !== 0) {
            let loctree = JSON.parse(JSON.stringify(sitelist))
            loctree.forEach((item) => {

                total = total + 1

            })
        }
        assignedlist.forEach((item) => {

            count = count + item.site.length
        })
        return (total * dcflist.length)
    }
    const editDCFConfig = (items, type) => {
        setStdList(stdlistBk.filter( (i) => {return i.dcf_ids !== null && i.dcf_ids.includes(items.dcfId.id)}))
        console.log(typeof items.end_date)
        if (items.start_date !== undefined && items.start_date !== null && (typeof items.start_date === 'string')) {

            items.start_date = DateTime.fromISO(items.start_date, { zone: 'utc' }).toLocal().toJSDate()
        } else if (items.start_date === undefined) {

            items.start_date = null
        }
        if (items.standard === undefined) {
            items.standard = 0
        }

        if (items.end_date !== undefined && items.end_date !== null && (typeof items.end_date === 'string' || typeof items.end_date === 'object')) {
            items.disable_end = true
            if (typeof items.end_date === 'string') {
                items.end_date = DateTime.fromISO(items.end_date, { zone: 'utc' }).toLocal().toJSDate()

            }
        } else if (items.end_date === undefined) {
            items.end_date = null
            delete items.disable_end
        }
        setDCFConfig(items)
        setAssignDialog(true)

        forceUpdate()
    }
    const editDCFConfig_ = (items, type) => {


        items.start_date = moment(items.start_date).toDate()
        setDCFConfig(items)
        setAssignDialog(true)
        let asslist = JSON.parse(JSON.stringify(assignedlistbk))
        let loc = JSON.parse(JSON.stringify(sitelist))
        let dcf__list = JSON.parse(JSON.stringify(dcflist))

        let assigned = [], complete_site = []
        asslist.forEach((dcf) => {

            let dcfindex = assigned.findIndex((i) => { return i.id === dcf.dcfId.id })

            if (dcf.dcfId.id === items.dcfId.id) {

                if (dcfindex === -1) {

                    assigned.push({ id: dcf.dcfId.id, site: dcf.site })
                } else {

                    dcf.site.forEach((site) => {
                        if (!assigned[dcfindex].site.includes(site)) {
                            assigned[dcfindex].site.push(site)
                        }
                    })

                }
            }
        })
        loc.forEach((item3) => {

            if (!complete_site.includes(item3.id)) {
                complete_site.push(item3.id)
            }


        })

        let disabled = []

        assigned.forEach((item) => {

            if (item.id === items.dcfId.id) {

                if (item.site.length !== complete_site.length) {
                    item.unassign = complete_site.filter(function (n) {
                        return item.site.indexOf(n) === -1;
                    })
                    disabled = complete_site.filter(function (n) {
                        return item.site.indexOf(n) === -1;
                    })
                    item.unassigns = item.unassign.map((siteid) => { return getSiteNames(siteid) }).filter((item) => { return item !== null })

                } else {
                    item.unassign = complete_site.filter(function (n) {
                        return item.site.indexOf(n) !== -1;
                    })
                    disabled = complete_site.filter(function (n) {
                        return item.site.indexOf(n) === -1;
                    })
                    item.unassigns = item.unassign.map((siteid) => { return getSiteNames(siteid) }).filter((item) => { return item !== null })

                }

            }
        })



        forceUpdate()
    }

    const actionBodyTemplate = (rowData) => {

        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="mr-2" style={{ width: 30, height: 30, borderRadius: 15, background: 'dodgerblue' }}
                        onClick={() => {
                            editDCFConfig(rowData, 1)
                        }}
                    />

                    <Button
                        icon="pi pi-trash"
                        className="mr-2 " style={{ width: 30, height: 30, borderRadius: 15, background: 'palevioletred' }}
                        onClick={() => {
                            deleteAssignDCF(rowData)
                        }}
                    />
                </div>
            </>
        )
    }

    const justifyDCF = (item) => {

        console.log(item)
    }
    const actionBodyTemplate_ = (rowData) => {
        let index = dcflist.findIndex((i) => { return i.id === rowData.id })
        let data = { dcfId: dcflist[index], site: rowData.unassign, frequency: null, start_date: null, user_id: null, approver_id: null }

        return (
            <>
                <div className="actions" style={{
                    justifyContent: 'space-evenly',
                    display: 'flex'
                }}>
                    <Button
                        label="Assign"

                        onClick={() => {
                            editDCFConfig(data, 1)
                        }}
                    />

                    <Button

                        label="Justify"
                        onClick={() => {
                            justifyDCF(rowData)
                        }}
                    />
                </div>
            </>
        )
    }
    const dcfNameTemplate = (rowData) => {

        return (<>{rowData.dcfId.title}</>)
    }
    const dcfNameTemplate_ = (rowData) => {
        let index = dcflist.findIndex((i) => { return i.id === rowData.id })
        
        return (<>{rowData.dcf}</>)
    }
    const dcfNameTemplate_1 = (rowData) => {
        let index = dcflist.findIndex((i) => { return i.id === rowData.dcfId.id })
        return (<>{dcflist[index].title}</>)
    }

    const userNameTemplate = (rowData) => {
        return (<>{rowData.user_id !== null && rowData.user_id !== undefined && rowData.user_id.name}</>)
    }
    const approverNameTemplate = (rowData) => {

        return (<>{rowData.approver_id !== null && rowData.approver_id !== undefined && rowData.approver_id.name}</>)
    }
    const frequencyTemplate = (rowData) => {
        return (<>{rowData.frequency !== null && rowData.frequency !== undefined && rowData.frequency.name}</>)
    }
    const siteTemplate = (rowData) => {

        return (<>{rowData.sites.map((item) => {
            return <label style={{ display: 'flex', width: '100%' }}>{item.country + ">" + item.city + ">" + item.site}</label>
        })
        }</>)
    }
    const siteTemplate_assign = (rowData) => {
        let txt = 'Site Not Found'

        let index = locationList.findIndex((i) => { return i.id === rowData.site[0] })
        if (index !== -1) {
            txt = locationList[index].title
        }

        return <label style={{ display: 'flex', width: '100%' }}>{txt}</label>


    }
    const siteTemplate_ = (rowData) => {
      let index = dcflist.findIndex((i) => { return i.id === rowData.dcf_id })
      let val = null
      if(index !== -1){
        val = dcflist[index]
      }
            return (<div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%',
                padding: '10px',
                background: 'whitesmoke',
                margin: '5px',
                borderRadius: '10px',
                color: 'black'
            }}>
                <div>
                    <label style={{ backgroundColor: (search_.unass.trim().length !== 0 && rowData.site.trim().toLowerCase().includes(search_.unass.trim().toLowerCase())) && 'yellow' }} >{rowData.site} </label>
                </div>
                <div style={{ display: 'flex' }}>
                    <span style={{
                        margin: 3, cursor: 'pointer', background: 'cornflowerblue',
                        color: 'white',
                        padding: '5px',
                        borderRadius: '10px'
                    }} onClick={() => {
                        let data = { disabled: true, dcfId: dcflist[index], site: [rowData.site_id], frequency: null, start_date: null, user_id: null, approver_id: null, type: 0, comments: [] }
                        editDCFConfig(data, 1)
                    }} >Assign</span>
                    <span style={{
                        margin: 3, cursor: 'pointer', background: 'cornflowerblue',
                        color: 'white',
                        padding: '5px',
                        borderRadius: '10px'
                    }} onClick={() => {
                        let data = { disabled: true, dcfId: dcflist[index], site: [rowData.site_id], frequency: null, start_date: null, user_id: null, approver_id: null, type: 1, comments: [] }
                        editDCFConfig(data, 1)
                    }} >Justify</span>
                </div>
            </div>
            )

    
        
    }
    const siteTemplate_1 = (rowData) => {
        let index = dcflist.findIndex((i) => { return i.id === rowData.dcfId.id })

        return (<>{rowData.sites.map((item) => {
            return (<div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%',
                padding: '10px',
                background: 'whitesmoke',
                margin: '5px',
                borderRadius: '10px',
                color: 'black'
            }}>
                <div>
                    <label  >{item.country.name + ">" + item.city.name + ">" + item.name.split('(')[0]} </label>
                </div>
                <div style={{ display: 'flex' }}>
                    <span style={{
                        margin: 3, cursor: 'pointer', background: 'cornflowerblue',
                        color: 'white',
                        padding: '5px',
                        borderRadius: '10px'
                    }} onClick={() => {
                        let data = { disabled: true, id: rowData.id, dcfId: dcflist[index], creator_id: rowData.creator_id, created: rowData.created, modifier_id: selector.id, modified_on: moment.utc(), site: [item.id], frequency: null, start_date: null, user_id: null, approver_id: null, type: 0, comments: ['', ''] }
                        editDCFConfig(data, 1)
                    }} >Assign</span>
                    <span style={{
                        margin: 3, cursor: 'pointer', background: 'cornflowerblue',
                        color: 'white',
                        padding: '5px',
                        borderRadius: '10px'
                    }} onClick={() => {
                        let data = { id: rowData.id, dcfId: dcflist[index], creator_id: rowData.creator_id, created: rowData.created, modifier_id: selector.id, modified_on: moment.utc(), site: [item.id], frequency: null, start_date: null, user_id: null, approver_id: null, type: 1, comments: rowData.comments }
                        editDCFConfig(data, 1)
                    }} >View/Edit reason</span>
                </div>
            </div>
            )

        })
        }</>)
    }
    const getSite = (ids) => {


        return ids.map((siteid) => { return getSiteNames(siteid) }).filter((item) => { return item !== null })

    }
    const renderLocationTreeByDCF = (id) => {
        let asslist = JSON.parse(JSON.stringify(assignedlistbk))
        let loc = JSON.parse(JSON.stringify(userConfig.location))
        let loctree = JSON.parse(JSON.stringify(userConfig.location))
        let assigned = [], complete_site = []
        asslist.forEach((dcf) => {

            let dcfindex = assigned.findIndex((i) => { return i.id === dcf.dcfId.id })
            if (dcfindex === -1) {

                assigned.push({ id: dcf.dcfId.id, site: dcf.site })
            } else {
                dcf.site.forEach((site) => {
                    if (!assigned[dcfindex].site.includes(site)) {
                        assigned[dcfindex].site.push(site)
                    }
                })

            }

        })
        loc.forEach((item) => {

            item.children.forEach((item2) => {

                item2.children.forEach((item3) => {
                    if (!complete_site.includes(item.id)) {
                        complete_site.push(item.id)
                    }

                    if (!complete_site.includes(item2.id)) {
                        complete_site.push(item2.id)
                    }
                    if (!complete_site.includes(item3.id)) {
                        complete_site.push(item3.id)
                    }

                })

            })

        })
        let data = []
        assigned.forEach((item) => {
            if (item.id === id.id) {
                data = complete_site.filter(function (n) {
                    return item.site.indexOf(n) === -1;
                })

            }

        })
        if (assigned.length === 0) {
            data = complete_site
        }
        loctree.map((item) => {
            if (data.indexOf(item.id) !== -1) {

                item['state'] = { disabled: false }

            } else {
                item['state'] = { disabled: true, "checkbox_disabled": true }
            }
            item.children.forEach((item2) => {
                if (data.indexOf(item2.id) !== -1) {
                    item2['state'] = { disabled: false }
                } else {

                    item2['state'] = { disabled: true, "checkbox_disabled": true }
                }
                item2.children.forEach((item3) => {
                    if (data.indexOf(item3.id) !== -1) {
                        item3['state'] = { disabled: false }
                    } else {
                        item3['state'] = { disabled: true, "checkbox_disabled": true }
                    }
                })
            }
            )


        })

        renderLocationTree(loctree)
    }
    const addNewAssign = () => {

        setDCFConfig((prev) => ({ frequency: null, start_date: null, end_date: null, standard: 0, user_id: null, approver_id: null, site: [], dcfId: null, type: 0, comments: [] }));
        setAssignDialog(true);
    }
    const export2Excel = () => {
        let loc = [];
        let obj = { DCF: '' };

        dcflist.forEach((l) => {
            obj = {};

            sitelist.forEach((i) => {
                let index = assignedlist.findIndex((k) => { return k.site[0] === i.id && l.id === k.dcfId.id })
                let index1 = justifylist.findIndex((k) => { return k.site[0] === i.id && l.id === k.dcfId.id })
                obj['DCF'] = l.title
                obj[i.name.split('(')[0]] = (index === -1 && index1 === -1) ? 'Unassigned' : index === -1 ? "Justified - " + justifylist[index1].comments[0] : assignedlist[index].user_id.name + ' / ' + assignedlist[index].approver_id.name
            })

            loc.push(obj);

        });

        const path = require('path')
        const os = require('os')
        const homedir = os.homedir()
        const downloadFolder = path.join(homedir, 'Downloads')
        const filename = path.join(downloadFolder, 'hellow.xlsx')
        const ws = XLSX.utils.json_to_sheet(loc);
        const range = { s: { c: 0, r: 0 }, e: { c: sitelist.length, r: dcflist.length } }
        //         const cell = XLSX.utils.sheet_to_json(ws,{range});

        // cell.forEach(c =>{
        //    c.s ={fill:{fgColor:{rgb:'FFFF0000'}}}
        // })
        // XLSX.utils.sheet_add_json(ws,cell,{skipHeader:true,origin:range.s})
        const wb = {
            Sheets: { ["DCF Assignment"]: ws },
            SheetNames: ["DCF Assignment"],
        };
        const worksheet = wb.Sheets['DCF Assignment']
        // {style:{type:'pattern', patternType: 'solid', fgColor: { rgb: 'FFFF0000' } }}
        const fill = { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'FFFF0000' } }
        let style = []
        for (let row = range.s.r; row <= range.e.r; row++) {
            for (let col = range.s.c; col <= range.e.c; col++) {

                let cellAddress = XLSX.utils.encode_cell({ r: row, c: col })
                if (worksheet[cellAddress].v === 'Unassigned') {
                    style.push({ range: XLSX.utils.encode_range({ s: cellAddress, e: cellAddress }) + ':' + XLSX.utils.encode_range({ s: cellAddress, e: cellAddress }), color: 'FFFF0000' })

                } else if (worksheet[cellAddress].v.toLowerCase().includes('justified -')) {
                    style.push({ range: XLSX.utils.encode_range({ s: cellAddress, e: cellAddress }) + ':' + XLSX.utils.encode_range({ s: cellAddress, e: cellAddress }), color: '097969' })

                }
            }
        }
        //  XLSX.writeFile(wb,filename)

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        const data = new Blob([excelBuffer], {
            type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        XlsxPopulate.fromDataAsync(data).then((workbook) => {
            workbook.sheets().forEach((sheet) => {

                sheet.range(`A1:${XLSX.utils.encode_col(sitelist.length)}1`).style({

                    fontColor: 'ffffff',
                    fill: '6082B6'

                });
                style.forEach((cell) => {

                    sheet.range(cell.range).style({

                        fontColor: cell.color,
                        bold: cell.color === 'FFFF0000' ? false : true

                    });
                })


            })
            workbook
                .outputAsync()
                .then((workbookBlob) =>
                    FileSaver.saveAs(URL.createObjectURL(workbookBlob), "DCFAssignment.xlsx")
                )

            // sheet.range(dataInfo.theadRange1).style({
            //     fill: "808080",
            //     bold: true,
            //     horizontalAlignment: "center",
            //     fontColor: "ffffff",
            //   });
        })
        // FileSaver.saveAs(data, "DCFAssignment.xlsx");
    }
    const searchAssignment = (e) => {
        let ser = search_
        ser.ass = e.target.value
        setSearch_(ser)
        let up = []
        assignedlistbk.forEach((k) => {

            if (k.dcfId.title.trim().toLowerCase().includes(e.target.value.trim().toLowerCase()) && k.type === 0) {
                up.push(k)

            }

        })


        setAssignedList(up)
        forceUpdate()
    }
    const splitArray = (array, chunkSize) => {


        return array.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / chunkSize)

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
            }

            resultArray[chunkIndex].push(item)

            return resultArray
        }, [])
    }
    const getTotalCard = (sd, ed, f) => {
        let count = 0
        console.log(sd, ed)
        let betweenMonths = []
        if (typeof sd === 'object' && typeof ed === 'object') {
            sd = DateTime.fromJSDate(sd);
            ed = DateTime.fromJSDate(ed);
            while (sd <= ed) {

                betweenMonths.push(sd.toFormat('LLL-yyyy'));
                sd = sd.plus({ months: 1 })

            }
        }




        return splitArray(betweenMonths, f === 4 ? 12 : f === 5 ? 6 : f === 6 ? 1 : f).length
    }
    const searchJustify = (e) => {
        let ser = search_
        ser.jus = e.target.value
        setSearch_(ser)
        let up = []
        assignedlistbk.forEach((k) => {

            if (k.dcfId.title.trim().toLowerCase().includes(e.target.value.trim().toLowerCase()) && k.type === 1) {
                up.push(k)

            }

        })


        setJustifyList(up)
        forceUpdate()
    }

    const searchUnassigned = (e) => {
        let ser = search_
        ser.unass = e.target.value
        setSearch_(ser)
        console.log(unassignedlist)
        let up = []
        unassignedlistBK.forEach((k) => {

            if (k.name.trim().toLowerCase().includes(e.target.value.trim().toLowerCase()) || k.unassigns.filter((l) => { return l.name.toLowerCase().trim().includes(e.target.value.trim().toLowerCase()) }).length !== 0) {
                up.push(k)

            }

        })


        setUnAssignedList(up)
        forceUpdate()
    }
    const renderMonthContent = (month, shortMonth, longMonth) => {
        const tooltipText = `Tooltip for month: ${longMonth}`;
        console.log(month)
        return frequencyMonths.includes(month) ? <span title={tooltipText}>{shortMonth} </span> : null
    };
    const items = [
        { label: 'Assigned' + ' (' + assignedlistbk.filter((i) => { return i.type === 0 }).length + '/' + getAssignedCount() + ')', command: () => setSelected(1) },
        { label: 'To be Assigned' + ' (' + getUnassignedCount() + ')', command: () => setSelected(2) },
        { label: 'Intentionally not Assigned' + ' (' + justifylist.length + ')', command: () => setSelected(3) },

    ];
    return (
        <div className="grid">
            <div className="col-12">
                <div className="card" style={{ height: 'calc(100vh - 9rem)', marginTop: '0rem' }}>
                    <div style={{
                        fontSize: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: '600', marginBottom: 10
                    }} >Internal DCF/CF Assignment</div>
                    <div>
                        <TabMenu model={items} />
                        {/* <div className="col-12" style={{ display: 'flex', flexDirection: 'row' }} >
                            <div className='col-4' >
                                <div style={{
                                    padding: 5,
                                    background: 'gainsboro',
                                    borderRadius: '10px',
                                    flexDirection: 'column',
                                    display: 'flex',
                                    color: 'black',
                                    border: selected === 0 ? '3px solid blue' : 'none'

                                }} onClick={() => { setSelected(0) }}>

                                    <label style={{ margin: 5 }}>Assigned</label>
                                    <label style={{ margin: 5 }}>{assignedlistbk.filter((i) => { return i.type === 0 }).length + '/' + getAssignedCount()}</label>
                                </div>
                            </div>
                            <div className='col-4' >
                                <div style={{
                                    padding: 5,
                                    background: 'gainsboro',
                                    borderRadius: '10px',
                                    flexDirection: 'column',
                                    display: 'flex',
                                    color: 'black',
                                    border: selected === 1 ? '3px solid blue' : 'none'
                                }} onClick={() => { setSelected(1) }}>
                                    <label style={{ margin: 5 }}>To be Assigned</label>
                                    <label style={{ margin: 5 }}>{getUnassignedCount()}</label>
                                </div>
                            </div>
                            <div className='col-4' >
                                <div style={{
                                    padding: 5,
                                    background: 'gainsboro',
                                    borderRadius: '10px',
                                    flexDirection: 'column',
                                    display: 'flex',
                                    color: 'black',
                                    border: selected === 2 ? '3px solid blue' : 'none'
                                }} onClick={() => { setSelected(2) }}>
                                    <label style={{ margin: 5 }}>Intentionally not Assigned</label>
                                    <label style={{ margin: 5 }}>{justifylist.length}</label>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    {selector.role === "clientadmin" ?
                        <div>
                            {selected === 1 && <div>
                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    {/* <div className="col-6" >
                                                <span className="p-input-icon-left">
                                                    <i className="pi pi-search" />
                                                    <InputText value={search} onChange={(e) => { handleSearch(e.target.value) }} style={{ width: 300 }} placeholder="Search by dcf/site/frequency" />
                                                </span>
                                            </div> */}
                                    <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between' }} >
                                        <div className="col-6" >
                                            <span className="p-input-icon-left">
                                                <i className="pi pi-search" />
                                                <InputText value={search_.ass} onChange={searchAssignment} style={{ width: 350 }} placeholder="Search DCF" />
                                            </span>
                                        </div>
                                        <div className="col-6" style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                            <Button style={{ marginRight: 20 }} onClick={() => { export2Excel() }}> Export Report</Button>

                                            <Button onClick={() => { addNewAssign() }}> + Assign DCF</Button>
                                        </div>
                                    </div>
                                </div>
                                <DataTable
                                    value={assignedlist}
                                    dataKey="id"
                                    paginator
                                    scrollable
                                    style={{ height: 0 }}
                                    rows={20}
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    className="datatable-responsive"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} assignment"

                                    emptyMessage="No Assignment found."

                                    responsiveLayout="scroll"
                                >


                                    <Column
                                        field="dcfId"
                                        header="DCF"
                                        body={dcfNameTemplate}
                                        sortable
                                        headerClassName="stickyToTopTableHeaders"
                                        headerStyle={{
                                            width: "25%",
                                            minWidth: "8rem", background: '#EEF2FF'
                                        }}
                                    >

                                    </Column>
                                    <Column
                                        field="site"
                                        header="Site"
                                        headerClassName="stickyToTopTableHeaders"
                                        body={siteTemplate_assign}

                                        headerStyle={{
                                            width: "15%",
                                            minWidth: "8rem", background: '#EEF2FF'
                                        }}
                                    >

                                    </Column>
                                    <Column
                                        field="frequency"
                                        header="Frequency"
                                        headerClassName="stickyToTopTableHeaders"
                                        body={frequencyTemplate}
                                        headerStyle={{
                                            width: "15%",
                                            minWidth: "8rem", background: '#EEF2FF'
                                        }}
                                    >
                                    </Column>
                                    <Column
                                        field="user_id"
                                        header="Submitter"
                                        headerClassName="stickyToTopTableHeaders"
                                        body={userNameTemplate}
                                        headerStyle={{
                                            width: "15%",
                                            minWidth: "8rem", background: '#EEF2FF'
                                        }}
                                    >

                                    </Column>
                                    <Column
                                        field="approver_id"
                                        header="Approver"
                                        headerClassName="stickyToTopTableHeaders"
                                        body={approverNameTemplate}
                                        headerStyle={{
                                            width: "15%",
                                            minWidth: "8rem", background: '#EEF2FF'
                                        }}
                                    >

                                    </Column>
                                    <Column header="Action"
                                        headerClassName="stickyToTopTableHeaders"
                                        body={actionBodyTemplate}
                                        headerStyle={{
                                            background: '#EEF2FF', width: '15%'
                                        }} > </Column>
                                </DataTable>
                            </div>}


                            {selected === 2 && <div>
                                <div style={{ flexDirection: 'row', display: 'flex' }}>

                                    <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between' }} >
                                        <div className="col-6" >
                                            <span className="p-input-icon-left">
                                                <i className="pi pi-search" />
                                                <InputText value={search_.unass} onChange={searchUnassigned} style={{ width: 350 }} placeholder="Search DCF/Site/City" />
                                            </span>
                                        </div>
                                        <div className="col-6" style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                            <Button style={{ marginRight: 20 }} onClick={() => { export2Excel() }}> Export Report</Button>

                                            <Button onClick={() => { addNewAssign() }}> + Assign DCF</Button>
                                        </div>
                                    </div>
                                </div>
                                <DataTable


                                    value={getUnAssFilterData(unassignedlist)}
                                    dataKey="id"
                                    paginator
                                    scrollable
                                    style={{ height: 0, marginTop: 30 }}
                                    rows={5}
                                    rowsPerPageOptions={[10, 20, 50]}
                                    className="datatable-responsive"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} DCF"

                                    emptyMessage="No UnAssignment DCF found."

                                    responsiveLayout="scroll"
                                >


                                    <Column
                                        field="dcfId"
                                        header="DCF"
                                        body={dcfNameTemplate_}
                                        
                                        headerClassName="stickyToTopTableHeaders"
                                        headerStyle={{
                                            width: "30%",
                                            minWidth: "8rem", background: '#EEF2FF'
                                        }}
                                    >

                                    </Column>
                                    <Column
                                        field="site"
                                        header="Location"
                                        headerClassName="stickyToTopTableHeaders"
                                        body={siteTemplate_}

                                        headerStyle={{
                                            width: "70%",
                                            minWidth: "8rem", background: '#EEF2FF'
                                        }}
                                    >

                                    </Column>





                                </DataTable>
                            </div>}

                            {selected === 3 && <div>
                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    {/* <div className="col-6" >
                                                <span className="p-input-icon-left">
                                                    <i className="pi pi-search" />
                                                    <InputText value={search} onChange={(e) => { handleSearch(e.target.value) }} style={{ width: 300 }} placeholder="Search by dcf/site/frequency" />
                                                </span>
                                            </div> */}
                                    <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between' }} >
                                        <div className="col-6" >
                                            <span className="p-input-icon-left">
                                                <i className="pi pi-search" />
                                                <InputText value={search_.jus} onChange={searchJustify} style={{ width: 350 }} placeholder="Search DCF" />
                                            </span>
                                        </div>

                                    </div>
                                </div>
                                <DataTable
                                    scrollable

                                    value={justifylist}
                                    dataKey="id"
                                    paginator
                                    rows={20}
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    className="datatable-responsive"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} assignment"
                                    style={{ height: 0, marginTop: 30 }}
                                    emptyMessage="No Assignment found."

                                    responsiveLayout="scroll"
                                >


                                    <Column
                                        field="dcfId"
                                        header="DCF"
                                        body={dcfNameTemplate_1}
                                        sortable
                                        headerClassName="stickyToTopTableHeaders"
                                        headerStyle={{
                                            width: "30%",
                                            minWidth: "8rem", background: '#EEF2FF'
                                        }}
                                    >

                                    </Column>
                                    <Column
                                        field="site"
                                        header="Sites"
                                        headerClassName="stickyToTopTableHeaders"
                                        body={siteTemplate_1}

                                        headerStyle={{
                                            width: "70%",
                                            minWidth: "8rem", background: '#EEF2FF'
                                        }}
                                    >

                                    </Column>





                                </DataTable>
                            </div>}






                        </div>
                        :
                        <div className="card col-12">You have no rights to access this page</div>

                    }
                </div>
                <Dialog
                    visible={assigndialog}
                    style={{
                        width: "75%",
                    }}
                    header={(dcfconfig.type === 0 ? "Assign " : "Justification for ") + ((dcfconfig.dcfId !== null && dcfconfig.dcfId.title !== undefined) ? dcfconfig.dcfId.title : 'DCF')}
                    modal
                    className="p-fluid"

                    onHide={() => { setAssignDialog(false) }}
                >
                    <div>

                        <div style={{ flexDirection: 'row', display: 'flex', height: '62vh' }}>

                            <div className="col-6">
                                {(dcfconfig.id === undefined && (dcfconfig.disabled === undefined || !dcfconfig.disabled)) &&
                                    <div style={{ marginBottom: 20 }}>
                                        <label
                                            style={{
                                                marginRight: 10,
                                            }}
                                        >
                                            Select DCF
                                        </label>
                                        <AutoComplete field="title" forceSelection disabled={dcfconfig.id !== undefined || dcfconfig.disabled} value={dcfconfig.dcfId} suggestions={dcfitems} completeMethod={search_dcf} onChange={(e) => {console.log(e.value); setDCF(e.value); updateDCFConfig('dcfId', e.value); }} dropdown />

                                    </div>
                                }
                                {submitted && (dcfconfig.dcfId === null || dcfconfig.dcfId === undefined) &&
                                    (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red", marginLeft: '22%'
                                            }}
                                        >

                                            select DCF
                                        </small>
                                    )}
                                {(dcfconfig.id === undefined && (dcfconfig.disabled === undefined || !dcfconfig.disabled)) ?
                                    <>
                                        {dcfconfig.dcfId !== null &&
                                            <>
                                                <div style={{ marginBottom: 20 }}>
                                                    <label

                                                    >
                                                        Select Site
                                                    </label>

                                                    <AutoComplete forceSelection field="name" value={dcfconfig.site} suggestions={sitesuggestion} completeMethod={search_site} onChange={(e) => updateDCFConfig('site', e.value)} dropdown />


                                                </div>
                                                {(submitted && (dcfconfig.site === null || dcfconfig.site.length === 0)) &&
                                                    (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red", marginLeft: '22%'
                                                            }}
                                                        >

                                                            select site
                                                        </small>
                                                    )}
                                                {dcfconfig.type === 0 &&
                                                    <>

                                                        {dcfconfig.dcfId !== undefined && dcfconfig.dcfId !== null && stdlist.length !== 0 &&
                                                            <div style={{ marginBottom: 20 }}>
                                                                <label
                                                                    style={{
                                                                        marginRight: 10, marginBottom: 20
                                                                    }}
                                                                >
                                                                    Select Standard  <span style={{color:'red'}}>*</span>
                                                                </label>

                                                                <Dropdown disabled={dcfconfig.id !== undefined ? dcfconfig.disabled === undefined ? true : dcfconfig.disabled !== true : false} showClear style={{ width: '100%' }} optionValue={'id'} value={dcfconfig.standard} options={stdlist} onChange={(e) => {console.log(e.value); updateDCFConfig("standard", e.value) }} optionLabel="title" placeholder="Select Standard" />





                                                            </div>}
                                                        {dcfconfig.end_date !== null && dcfconfig.id !== undefined && <div>
                                                            <label>Total Card :  {getTotalCard(dcfconfig.start_date, dcfconfig.end_date, dcfconfig.frequency.id)}</label>
                                                        </div>}
                                                    </>
                                                }

                                            </>
                                        }
                                    </>
                                    :
                                    <div>
                                        <label style={{ fontWeight: 'bold' }}>Selected Location</label>
                                        {getSite(dcfconfig.site).map((item) => {

                                            return (<div>
                                                <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                                                    <label>Country : {item.country.name}</label>
                                                    <label>City : {item.city.name}</label>
                                                    <label>Site : {item.name.split('(')[0]}</label>

                                                </div>
                                            </div>)
                                        })}
                                        {dcfconfig.type === 0 &&   stdlist.length !== 0 &&
                                            <>
                                                <div style={{ marginTop: 20 }}>
                                                    <label
                                                        style={{
                                                            marginRight: 10, marginBottom: 20
                                                        }}
                                                    > 
                                                        Selected Standard 
                                                    </label>

                                                    <Dropdown disabled={dcfconfig.id !== undefined ? dcfconfig.disabled === undefined ? true : dcfconfig.disabled !== true : false}  style={{ width: '100%' }} optionValue={'id'} value={dcfconfig.standard} options={stdlist} onChange={(e) => { updateDCFConfig("standard", e.value) }} optionLabel="title" placeholder="Select Standard" />

                                                </div>
                                                {dcfconfig.end_date !== null && dcfconfig.id !== undefined && <div>
                                                    <label>Total Card :  {getTotalCard(dcfconfig.start_date, dcfconfig.end_date, dcfconfig.frequency.id)}</label>
                                                </div>}
                                            </>
                                        }
                                    </div>
                                }

                            </div>
                            {(dcfconfig.dcfId !== null && dcfconfig.type === 0) ?
                                <>
                                    <div className="col-6">
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <div className="col-5">
                                                    <label
                                                        style={{
                                                            margin: 10,
                                                        }}
                                                    >
                                                        Set Data Frequency
                                                    </label>
                                                </div>
                                                <div className="col-7 ddf">
                                                    <Dropdown disabled={dcfconfig.id !== undefined && dcfconfig.disabled === undefined} style={{ width: '100%', height: '100%' }} value={dcfconfig.frequency} options={frequency_list} onChange={(e) => updateDCFConfig("frequency", e.value)} optionLabel="name" placeholder="Select frequency" />

                                                </div>



                                            </div>
                                            {submitted && (dcfconfig.frequency === null) &&
                                                (
                                                    <small
                                                        className="p-invalid"
                                                        style={{
                                                            color: "red", marginLeft: '22%'
                                                        }}
                                                    >

                                                        Select Frequency
                                                    </small>
                                                )}
                                            {dcfconfig.frequency !== null && <>
                                                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <div className="col-5">
                                                        <label
                                                            style={{
                                                                margin: 10,
                                                            }}
                                                        >
                                                            Start Date
                                                        </label>
                                                    </div>
                                                    <div className="col-7">
                                                        <ReactDatePicker
                                                            disabled={dcfconfig.id !== undefined && dcfconfig.start_date !== null}
                                                            selected={dcfconfig.start_date}
                                                            onSelect={(e) => { updateDCFConfig("start_date", e) }}
                                                            renderMonthContent={renderMonthContent}
                                                            showMonthYearPicker

                                                            dateFormat="MM/yyyy"
                                                        />
                                                        {/* <Calendar  dateFormat="M/yy" view='month' maxDate={moment(moment.utc()).toDate()} value={dcfconfig.start_date} placeholder="date" onChange={(e) => {
                                                        updateDCFConfig("start_date", e.value);
                                                    }} /> */}

                                                    </div>



                                                </div>
                                                {submitted && (dcfconfig.start_date === null) &&
                                                    (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red", marginLeft: '22%'
                                                            }}
                                                        >

                                                            set start date
                                                        </small>
                                                    )}
                                            </>}
                                            {dcfconfig.id !== undefined && dcfconfig.start_date !== null && dcfconfig.disabled !== true && <>
                                                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <div className="col-5">
                                                        <label
                                                            style={{
                                                                margin: 10,
                                                            }}
                                                        >
                                                            End Date
                                                        </label>
                                                    </div>
                                                    <div className="col-7">
                                                        <ReactDatePicker
                                                            disabled={((dcfconfig.id === undefined || dcfconfig.disable_end !== undefined))}
                                                            selected={dcfconfig.end_date}
                                                            onSelect={(e) => { updateDCFConfig("end_date", e) }}
                                                            minDate={moment(dcfconfig.start_date).toDate()}
                                                            showMonthYearPicker

                                                            dateFormat="MM/yyyy"
                                                        />
                                                        {/* <Calendar  dateFormat="M/yy" view='month' maxDate={moment(moment.utc()).toDate()} value={dcfconfig.start_date} placeholder="date" onChange={(e) => {
                                                        updateDCFConfig("start_date", e.value);
                                                    }} /> */}

                                                    </div>



                                                </div>

                                            </>}
                                            <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <div className="col-5">
                                                    <label
                                                        style={{
                                                            margin: 10,
                                                        }}
                                                    >
                                                        Assign Submitter
                                                    </label>
                                                </div>
                                                <div className="col-7">
                                                    <Dropdown showClear value={dcfconfig.user_id} style={{ width: '100%' }} options={userList} onChange={(e) => updateDCFConfig("user_id", e.value)} optionLabel="name" filter filterBy="name" placeholder="Select Submitter" />

                                                </div>



                                            </div>
                                            {submitted && (dcfconfig.user_id === null) &&
                                                (
                                                    <small
                                                        className="p-invalid"
                                                        style={{
                                                            color: "red", marginLeft: '22%'
                                                        }}
                                                    >

                                                        Select Submitter
                                                    </small>
                                                )}
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <div className="col-5">
                                                    <label
                                                        style={{
                                                            margin: 10
                                                        }}
                                                    >
                                                        Assign Approver
                                                    </label>
                                                </div>
                                                <div className="col-7">
                                                    <Dropdown showClear style={{ width: '100%' }} value={dcfconfig.approver_id} options={approverList} onChange={(e) => updateDCFConfig("approver_id", e.value)} optionLabel="name" filter filterBy="name" placeholder="Select Approver" />

                                                </div>



                                            </div>
                                            {submitted && (dcfconfig.approver_id === null) &&
                                                (
                                                    <small
                                                        className="p-invalid"
                                                        style={{
                                                            color: "red", marginLeft: '22%'
                                                        }}
                                                    >

                                                        Select Approver
                                                    </small>
                                                )}
                                            {submitted && (dcfconfig.approver_id !== null && dcfconfig.user_id !== null && dcfconfig.user_id.id === dcfconfig.approver_id.id) &&
                                                (
                                                    <small
                                                        className="p-invalid"
                                                        style={{
                                                            color: "red", marginLeft: '22%'
                                                        }}
                                                    >

                                                        Submitter & Approver wont be same
                                                    </small>
                                                )}
                                        </div>
                                    </div>

                                </>
                                : dcfconfig.dcfId !== null &&
                                <>

                                    <div className="col-6" style={{ padding: 10, marginTop: 15 }}>
                                        <label className="col-12">Comments : <span style={{ color: 'red' }}>*</span></label>

                                        <div className="  flex justify-content-center">
                                            <InputTextarea style={{ height: '40vh', overflow: 'auto', resize: 'none' }} value={dcfconfig.comments[0]} autoResize={false} onChange={(e) => { updateDCFConfig('comments', e.target.value) }} rows={15} cols={30} />
                                        </div>
                                        {submitted && (dcfconfig.comments.length === 0 || dcfconfig.comments[0].trim().length === 0) &&
                                            (
                                                <small
                                                    className="p-invalid"
                                                    style={{
                                                        color: "red", marginLeft: '5%'
                                                    }}
                                                >

                                                    Comments required
                                                </small>
                                            )}

                                    </div>

                                </>
                            }
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={() => { assignDCFUser() }}>Save</Button>
                        </div>


                    </div>
                </Dialog>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ClientUserDCFAssignment, comparisonFn);
