import React, { useState, useEffect } from 'react'



const BRSRReport = () => {
    const [headings, setHeadings] = useState([])




    return (
        <div >
            <div className='col-12 p-card' style={{  height: 'calc(100vh - 9rem)',justifyContent: 'space-between' }} >

                <div className='col-12 grid' style={{ margin: 5, color: 'white' }}>
                    <div  >
                        <label style={{
                            color: 'black', padding: 15,
                            justifyContent: 'flex-start',
                            display: 'flex'
                        }}>Select Year :</label>
                    </div>
                    <div className='col-1' >
                        <label style={{
                            background: 'grey',
                            padding: '10px',cursor:'pointer',
                            borderRadius: '10px', justifyContent: 'center',
                            display: 'flex'
                        }}>2019</label>
                    </div>
                    <div className='col-1' >
                        <label style={{
                            background: 'grey',
                            padding: '10px',cursor:'pointer',
                            borderRadius: '10px', justifyContent: 'center',
                            display: 'flex'
                        }}>2020</label>
                    </div>
                    <div className='col-1' >
                        <label style={{
                            background: 'grey',
                            padding: '10px',cursor:'pointer',
                            borderRadius: '10px', justifyContent: 'center',
                            display: 'flex'
                        }}>2021</label>
                    </div>
                    <div className='col-1' >
                        <label style={{
                            background: 'grey',
                            padding: '10px',cursor:'pointer',
                            borderRadius: '10px', justifyContent: 'center',
                            display: 'flex'
                        }}>2022</label>
                    </div>
                    <div className='col-1' >
                        <label style={{
                            background: 'grey',
                            padding: '10px',cursor:'pointer',
                            borderRadius: '10px', justifyContent: 'center',
                            display: 'flex'
                        }}>2023</label>
                    </div>
                </div>
     
            </div>

        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(BRSRReport, comparisonFn);
