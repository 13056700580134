
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import APIServices from "../../service/APIService";
import { baseurl } from "../../components/constants/api_url";

const initialState = {

    defra: {}

}
export const fetchEF = createAsyncThunk('api/fetachEF', async (token) => {
    return APIServices.get(baseurl+ 'scope-ones/63412f1f2617a9c8ffa93884').then((res) => res.data)
})

const setDefraStandards = (state, action) => {

    state.defra = { scope3: action.payload.data[2], scope1: action.payload.data[1] }


}

const emissionFactor = createSlice({
    name: 'emissionfactor_',
    initialState,
    extraReducers: (builder) => {

        builder.addCase(fetchEF.fulfilled, (state, action) => { setDefraStandards(state, action) })

    }



})

export default emissionFactor.reducer

